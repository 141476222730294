import useFileDrop, { FileError } from "hooks/useFileDrop"
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { FileResult, StringResult } from "tools/getFile"
import getFile from "tools/getFile"
import { t } from "i18next"

const PREFIX = "Dropzone"

const classes = {
	dropzone: `${PREFIX}-dropzone`,
	errors: `${PREFIX}-errors`,
	dragover: `${PREFIX}-dragover`,
}

const Root = styled("div")(({ theme }) => ({
	[`& .${classes.dropzone}`]: {
		position: "relative",
	},

	[`& .${classes.errors}`]: {
		position: "absolute",
		top: 0,
		left: 0,
		height: "100%",
		width: "100%",
		padding: theme.spacing(2),
		backgroundColor: "rgba(252, 212, 212, 0.85)",
		zIndex: 1,
	},

	[`& .${classes.dragover}`]: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		textAlign: "center",
		backgroundColor: "rgba(225, 225, 225, 1)",
		zIndex: 1,
		opacity: 0.85,
	},
}))

type DefaultProps = {
	children: React.ReactNode
	accept: string
}

export default function DropZone(
	props: DefaultProps & {
		readAs?: "file"
		multiple?: false
		onDrop: (file: FileResult) => void
	}
): JSX.Element

export default function DropZone(
	props: DefaultProps & {
		readAs?: "file"
		multiple?: true
		onDrop: (files: FileResult[]) => void
	}
): JSX.Element

export default function DropZone(
	props: DefaultProps & {
		readAs?: "string"
		multiple?: false
		onDrop: (files: StringResult) => void
	}
): JSX.Element

export default function DropZone(
	props: DefaultProps & {
		readAs?: "string"
		multiple?: true
		onDrop: (files: StringResult[]) => void
	}
): JSX.Element

export default function DropZone({
	children,
	accept,
	readAs = "file",
	multiple = false,
	onDrop,
}: DefaultProps & {
	readAs?: "file" | "string"
	multiple?: boolean
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDrop: (files: any) => void
}) {
	const { ref, dragover, errors } = useFileDrop(
		async (files) => {
			if (!(files instanceof FileList)) return
			if (!multiple) {
				switch (readAs) {
					case "file":
						onDrop(await getFile(files[0], readAs))
						break
					case "string":
						onDrop(await getFile(files[0], readAs))
						break
				}
			} else {
				switch (readAs) {
					case "file":
						onDrop(await Promise.all(Array.from(files).map((file) => getFile(file, readAs))))
						break
					case "string":
						onDrop(await Promise.all(Array.from(files).map((file) => getFile(file, readAs))))
				}
			}
		},
		{
			accept,
			readAs: readAs === "file" ? "file" : "string",
			multiple: multiple,
		}
	)

	return (
		<Root ref={ref} className={`${classes.dropzone}${dragover && !errors.length && ` ${classes.dragover}`}`}>
			{errors.length > 0 && (
				<div className={classes.errors}>
					{errors.map((err, i) => (
						<Typography key={`error-${err}-${i}`}>
							{err === FileError.INVALID_FILE_ERROR && "Ongeldig bestandstype"}
							{err === FileError.TOO_MANY_FILES_ERROR && "Maximaal 1 bestand"}
						</Typography>
					))}
				</div>
			)}
			{dragover && !errors.length && (
				<div className={classes.dragover}>
					<Typography variant="h4">{t("DropToUpload")}</Typography>
				</div>
			)}
			{children}
		</Root>
	)
}
