/* eslint-disable i18next/no-literal-string */ //Internal only
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import TSDialog from "components/TSDialog"
import SearchableSelect from "components/SearchableSelect"
import { useInstallationsQuery, InstallationFieldsFragment, useInstallationLazyQuery } from "generated/graphql"
const PREFIX = "SelectInstallation"

const classes = {
	dialog: `${PREFIX}-dialog`,
}

const StyledAutoFullScreenDialog = styled(TSDialog)({
	[`& .${classes.dialog}`]: {
		minWidth: 500,
	},
})

type Props = {
	onClose: (installation: InstallationFieldsFragment | null) => void
}

export default function SelectInstallation({ onClose }: Props) {
	const { data, loading, error } = useInstallationsQuery()
	const [queryInstallation] = useInstallationLazyQuery({
		onCompleted: (data) => onClose(data.installation),
	})

	const handleClose = () => onClose(null)
	const handleChange = (e: { target: { value: string | number | null } }) => {
		if (e.target.value) {
			const id = String(e.target.value)
			queryInstallation({ variables: { installationId: id } })
		}
	}

	return (
		<StyledAutoFullScreenDialog open onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title" className={classes.dialog}>
				Selecteer een installatie
			</DialogTitle>

			<DialogContent>
				{loading && <DialogContentText>Laden...</DialogContentText>}
				{error && <DialogContentText>Er ging iets fout bij het ophalen van de installaties...</DialogContentText>}
				{data && !error && !loading && (
					<>
						<DialogContentText>Selecteer een installatie</DialogContentText>
						<SearchableSelect
							options={data.installations ?? []}
							fullWidth
							getOptionLabel={({ shortAddress, resident, installer }) =>
								`${resident?.name ? `${resident.name}, ` : ""}${shortAddress}${
									installer?.organization ? ` (${installer.organization})` : ""
								}`
							}
							label="Installatie"
							onChange={handleChange}
						/>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" disabled={loading}>
					Annuleren
				</Button>
			</DialogActions>
		</StyledAutoFullScreenDialog>
	)
}
