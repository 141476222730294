/* eslint-disable i18next/no-literal-string */ //Internal only
import { Divider, Link, Stack, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { InterfaceFieldsFragment } from "generated/graphql"
import HeatPumpInfoMessage from "pages/HeatPumpStatusPage/components/HeatPumpInfoMessage"
import { resolveInterfaceStatusColor, resolveInterfaceStatusText } from "pages/HeatPumpListPage"
import { HEAT_PUMP, SETTINGS } from "settings/url"
interface Installation {
	interfaces: InterfaceFieldsFragment[]
}

type Props = {
	children: Installation
}

function formatErrors(errorCodes: number[]) {
	return (
		<Stack direction="column">
			{errorCodes.map((err) => {
				return <HeatPumpInfoMessage errorCode={err} align="left" key={`hp-error-${err}`} />
			})}
		</Stack>
	)
}
export default function InterfaceInfo({ children: installation }: Props) {
	//get current parent page
	const pathSegments = location.pathname.split("/").filter((segment) => segment !== "")
	const currentPage = pathSegments[0]
	const currentPageId = pathSegments[1]

	if (installation.interfaces.length === 0) return <Typography>Geen interface</Typography>
	return (
		<Stack direction="column" key="interfaces-info" divider={<Divider />} spacing={1}>
			{installation.interfaces.map((iface) => {
				const statusDot = resolveInterfaceStatusColor(iface)
				return (
					<Stack direction="row" spacing={1} alignItems="center" key={`${iface.id}-info`}>
						{statusDot}
						<Stack direction="column">
							<Link
								component={RouterLink}
								to={`/${HEAT_PUMP}/${iface.id}/${SETTINGS}?ref=${currentPage}/${currentPageId}`}
							>
								{iface.name ? iface.name : iface.id}
							</Link>
							{resolveInterfaceStatusText(iface, formatErrors)}
						</Stack>
					</Stack>
				)
			})}
		</Stack>
	)
}
