/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Installation {
	installedOn?: string | null
}

type Props = {
	children?: Installation | null
}

export default function HeatPump({ children: installation }: Props) {
	const { t } = useTranslation()
	return (
		<Typography>
			{installation?.installedOn ? (
				<>Geïnstalleerd op {t("date", { date: new Date(installation.installedOn) })}</>
			) : (
				<>Onbekende installatiedatum</>
			)}
		</Typography>
	)
}
