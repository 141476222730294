import { useMessage } from "providers/MessageProvider"
import { useCallback, useRef, useState } from "react"

const emailRegex =
	// eslint-disable-next-line no-control-regex
	/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g

export type Options<T, K extends keyof T> = {
	required?: boolean
	min?: number
	max?: number
	between?: [number, number]
	email?: boolean
	datetime?: boolean
	integer?: boolean
	minLength?: number
	maxLength?: number
	validate?: (value: Partial<T>[K], fields: Partial<T>) => string | null
}

type Validators<T, K extends keyof T> = {
	[key in keyof Options<T, K>]: (
		value: Partial<T>[K],
		arg: Options<T, K>[key] | undefined,
		fields: Partial<T>
	) => string | null
}

function validatorRequired(value: unknown, req?: boolean) {
	if (!req) return null // field is not required
	if (value == null || value === "") return "Dit veld is verplicht" // do not check for Boolean(value) -> that will include 0
	return null
}

function validatorMin(value: unknown, minVal?: number) {
	if (minVal == null || value == null) return null // field can be optional, so value == null is ok
	const valueAsNumber = parseFloat(String(value))
	if (isNaN(valueAsNumber)) return "Vul een getal in"
	if (valueAsNumber < minVal) return `Vul een getal groter dan ${minVal} in`
	return null
}

function validatorMax(value: unknown, maxVal?: number) {
	if (maxVal == null || value == null) return null // field can be optional, so value == null is ok
	const valueAsNumber = parseFloat(String(value))
	if (isNaN(valueAsNumber)) return "Vul een getal in"
	if (valueAsNumber > maxVal) return `Vul een getal kleiner van ${maxVal} in`
	return null
}

function validatorBetween(value: unknown, bounds?: [number, number]) {
	if (bounds == null || value == null) return null // field can be optional, so value == null is ok
	const valueAsNumber = parseFloat(String(value))
	if (isNaN(valueAsNumber)) return "Vul een getal in"
	if (valueAsNumber < bounds[0] || valueAsNumber > bounds[1])
		return `Vul een getal in tussen ${bounds[0]} en ${bounds[1]}`
	return null
}

function validatorInteger(value: unknown, int?: boolean) {
	if (!int || value == null) return null // field can be optional, so value == null is ok
	const valueAsNumber = parseFloat(String(value))
	if (isNaN(valueAsNumber)) return "Vul een getal in"
	if (Math.round(valueAsNumber) !== value) return "Vul een geheel getal in"
	return null
}

function validatorEmail(value: unknown, email?: boolean) {
	if (!email || value == null) return null // field can be optional, so value == null is ok
	if (typeof value !== "string") return "Vul tekst in" // strange error, should not happen
	if (!value.toLowerCase().trim().match(emailRegex)) return "Ongeldig e-mailadres"
	return null
}

function validatorDateTime(value: unknown, date?: boolean) {
	if (!date || value == null) return null // field can be optional, so value == null is ok
	let newVal: Date
	if (typeof value === "string") {
		newVal = new Date(value)
	} else if (value instanceof Date) {
		newVal = value
	} else {
		return "Ongeldige datum" // not stored as either string or Date object
	}
	if (isNaN(newVal.getTime())) return "Ongeldige datum" // easy way to check if the date is valid
	return null
}

function validatorMinLength(value: unknown, length?: number) {
	if (length == null || value == null) return null // field can be optional, so value == null is ok
	if (typeof value !== "string" || !length) return null
	if (value.length < length) return `Vul minimaal ${length} karakters in`
	return null
}

function validatorMaxLength(value: unknown, length?: number) {
	if (length == null || value == null) return null // field can be optional, so value == null is ok
	if (typeof value !== "string" || !length) return null
	if (value.length > length) return `Vul maximaal ${length} karakters in`
	return null
}

function validatorCustom<T, K extends keyof T>(
	value: Partial<T>[K],
	validate: ((value: Partial<T>[K], fields: Partial<T>) => string | null) | undefined,
	fields: Partial<T>
) {
	if (!validate || !fields) return null
	return validate(value, fields) // custom validator
}

function getValidators<T, K extends keyof T>(): Validators<T, K> {
	return {
		required: validatorRequired,
		min: validatorMin,
		max: validatorMax,
		between: validatorBetween,
		integer: validatorInteger,
		email: validatorEmail,
		datetime: validatorDateTime,
		minLength: validatorMinLength,
		maxLength: validatorMaxLength,
		validate: validatorCustom,
	}
}

function getProperty<T, K extends keyof T>(obj: T, key: K) {
	return obj[key]
}

// all fields that are not registered are removed by default except for the fields below
const KEEP = ["id"]

export default function useForm<T>(
	initialFields: Partial<T> | (() => Partial<T>) | null | undefined,
	onValidated: (result: T) => void,
	keep: (keyof T)[] = []
) {
	const message = useMessage()
	const [fields, setFields] = useState<Partial<T>>(initialFields ?? ({} as Partial<T>))
	const [check, setCheck] = useState<boolean>(false)
	const errorCount = useRef(0)
	const registeredFields = useRef<(keyof T)[]>([])

	errorCount.current = 0
	registeredFields.current = []

	const register = useCallback(
		<K extends keyof T>(key: K, options: Options<T, K> = {}, callback?: (value: T[K] | null) => void) => {
			const onChange = (value: T[K] | null) => {
				if (callback) callback(value)
				setFields((fields) => ({ ...fields, [key]: value }))
			}

			const value = getProperty(fields, key)
			registeredFields.current.push(key)

			const validators = getValidators<T, K>()

			const errors = Object.keys(options).flatMap((key) => {
				// ignore if undefined or null
				if (!(key in validators)) return []
				const key1 = key as keyof Options<T, K>
				if (typeof validators[key1] !== "function") return []
				// I have been fighting this any below for ages, not worth it. Maybe I'll try again later
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return validators[key1]?.(value, options[key1] as any, fields) ?? []
			})
			const hasErrors = errors.length > 0

			if (hasErrors) {
				errorCount.current++
			}

			return {
				value,
				onChange,
				required: options.required ?? false,
				error: check && hasErrors,
				helperText: check && hasErrors ? errors[0] : null,
			}
		},
		[check, fields]
	)

	const submit = useCallback(
		(e?: { preventDefault?: () => void; type?: string }) => {
			if (e?.type === "submit" && e.preventDefault) {
				e.preventDefault()
			}
			setCheck(true)
			if (errorCount.current > 0) {
				message.error(
					`Er ${errorCount.current > 1 ? "zijn" : "is"} ${errorCount.current} veld${
						errorCount.current > 1 ? "en" : ""
					} niet geldig.`
				)
				return
			}

			const keepFields: (keyof T)[] = [...KEEP, ...keep] as (keyof T)[]

			const fieldsValidated = registeredFields.current.reduce(
				(acc, key) => ({
					...acc,
					[key]: fields[key] === undefined ? null : fields[key],
				}),
				{} as Required<T>
			)
			const result = keepFields.reduce(
				(acc, field) => (field in fields ? { ...acc, [field]: fields[field] } : acc),
				fieldsValidated
			)
			onValidated(result)
		},
		[fields, keep, message, onValidated]
	)

	const set = useCallback((changeFields: Partial<T>) => {
		setFields((fields) =>
			Object.entries(changeFields).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), fields)
		)
	}, [])

	const reset = useCallback((fields: Partial<T>) => setFields(fields), [])

	return { register, submit, set, fields, reset }
}
