import { useEffect, useRef } from "react"
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"
import icon from "leaflet/dist/images/marker-icon.png"
import shadow from "leaflet/dist/images/marker-shadow.png"
import useSize from "hooks/useSize"
import { useMemo } from "react"
import { MarkerColor, StyledMap } from "./StyledMap"

type Props = {
	lat: number
	lon: number
	color?: keyof typeof MarkerColor
}

export default function Location({ lat, lon, color = MarkerColor.blue }: Props) {
	const mapRef = useRef<L.Map | null>(null)
	const marker = useRef<L.Marker | null>(null)

	const smallScreen = useSize("down", "sm")

	// must recreate here, because it will be destroyed globally by leaflet
	const smallScreenLayer = useMemo(
		() =>
			L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
				attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
				tileSize: 128,
				zoomOffset: 1,
			}),
		[]
	)

	const largeScreenLayer = useMemo(
		() =>
			L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
				attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
			}),
		[]
	)

	useEffect(() => {
		if (!lat || !lon) return

		const tsIcon = L.icon({
			iconUrl: icon,
			iconSize: [16.7, 27.3],
			iconAnchor: [8, 27.3],
			shadowUrl: shadow,
			shadowSize: [27.3, 27.3],
			shadowAnchor: [8, 27.3],
			className: color,
		})

		if (!mapRef.current) {
			mapRef.current = L.map("leaflet-map").setView([lat, lon], 14)
			largeScreenLayer.addTo(mapRef.current)
		}

		if (!marker.current) {
			marker.current = L.marker([lat, lon], {
				icon: tsIcon,
			}).addTo(mapRef.current)
		} else {
			marker.current.setLatLng([lat, lon])
		}
	}, [lat, lon, color, largeScreenLayer])

	useEffect(() => {
		if (!mapRef.current) return
		if (smallScreen) {
			mapRef.current.removeControl(mapRef.current.zoomControl)
			mapRef.current.removeLayer(largeScreenLayer)
			mapRef.current.addLayer(smallScreenLayer)
		} else {
			mapRef.current.addControl(mapRef.current.zoomControl)
			mapRef.current.removeLayer(smallScreenLayer)
			mapRef.current.addLayer(largeScreenLayer)
		}
	}, [smallScreen, largeScreenLayer, smallScreenLayer])

	if (!lat || !lon) return null
	return <StyledMap style={{ height: 150 }} id="leaflet-map" />
}
