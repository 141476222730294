/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography, CardContent, CardActions, Grid, IconButton, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Close, Description } from "@mui/icons-material"
import { Skeleton } from "@mui/material"
import FileInput from "components/FileInput"
import {
	useAddFilesToInstallationMutation,
	useDeleteFileFromInstallationMutation,
	useInstallationQuery,
} from "generated/graphql"
import { useMessage } from "providers/MessageProvider"
import { useConfirm } from "providers/ConfirmProvider"
import { useAuth } from "providers/AuthProvider"
import { saveAs } from "file-saver"
import CustomCard from "components/CustomCard"
import DropZone from "components/Dropzone"
import { FileResult } from "tools/getFile"
import appendErrorMessage from "tools/appendErrorMessage"

const FileGridItem = styled(Grid)(({ theme }) => ({
	"& :hover": {
		cursor: "pointer",
		"& $highlight": {
			color: theme.palette.primary.main,
		},
		"& .delete-file-icon": {
			color: theme.palette.text.primary,
			visibility: "visible",
		},
	},
	".delete-file-icon": {
		visibility: "hidden",
	},
}))

type Props = {
	installationId: string
}

export default function FilesCard({ installationId }: Props) {
	const {
		data,
		loading: iLoading,
		error: iError,
	} = useInstallationQuery({
		variables: { installationId },
	})

	const [addFiles, { loading: addLoading }] = useAddFilesToInstallationMutation()
	const [deleteFile, { loading: delLoading }] = useDeleteFileFromInstallationMutation()
	const message = useMessage()
	const confirm = useConfirm()
	const auth = useAuth()

	const installation = data?.installation

	const handleFiles = async (result: FileResult[]) => {
		await confirm(
			<>
				<p>
					Wil je {result.length === 1 ? "het volgende bestand" : `de volgende ${result.length} bestanden`} uploaden?
				</p>
				<ol>
					{result.map(({ fullFilename }, ix) => (
						<li key={`file-${ix}`}>{fullFilename}</li>
					))}
				</ol>
			</>
		)
		const files = result.map(({ data }) => data)
		try {
			await addFiles({ variables: { installationId, files } })
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}

	const handleOpenLink = (file: { url: string; fileName: string }) => async () => {
		const blob = await auth.fetchAsset(file.url)
		saveAs(blob, file.fileName)
	}

	const handleDeleteFile = (fileId: string) => async () => {
		await confirm("Wil je het bestand permanent verwijderen?")
		try {
			await deleteFile({ variables: { installationId, fileId } })
		} catch (e) {
			message.error(appendErrorMessage("Verwijderen mislukt", e))
		}
	}

	return (
		<CustomCard>
			<DropZone accept="*" onDrop={handleFiles} multiple>
				<CardContent>
					<Typography variant="h5" gutterBottom>
						Bestanden
					</Typography>
					{iLoading && <Skeleton />}
					{iError && <Typography>Fout bij het ophalen...</Typography>}
					{!iLoading && !iError && installation && (
						<>
							{installation.files.length > 0 ? (
								<Grid container spacing={1}>
									{installation.files.map((file) => (
										<FileGridItem xs={6} item key={file.id}>
											<Grid container alignItems="center" wrap="nowrap" spacing={1}>
												<Grid item onClick={handleOpenLink(file)}>
													<Description />
												</Grid>
												<Grid item onClick={handleOpenLink(file)}>
													<Tooltip title="Bestand downloaden">
														<Typography>{file.fileName}</Typography>
													</Tooltip>
												</Grid>
												<Grid item onClick={handleDeleteFile(file.id)} className="delete-file-icon">
													<Tooltip title="Bestand verwijderen">
														<IconButton size="small" disabled={delLoading}>
															<Close />
														</IconButton>
													</Tooltip>
												</Grid>
											</Grid>
										</FileGridItem>
									))}
								</Grid>
							) : (
								<Typography>Geen bestanden</Typography>
							)}
						</>
					)}
				</CardContent>
				<CardActions>
					<Grid container justifyContent="flex-end">
						<FileInput
							onFileContent={handleFiles}
							multiple
							accept="*"
							readAs="file"
							color="primary"
							variant="contained"
							disabled={iLoading || addLoading || delLoading || Boolean(iError)}
						>
							Toevoegen
						</FileInput>
					</Grid>
				</CardActions>
			</DropZone>
		</CustomCard>
	)
}
