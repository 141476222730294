import { red, orange, yellow, grey } from "@mui/material/colors"
import { Warning } from "@mui/icons-material"
import { Severity } from "generated/graphql"

const colors: {
	high: string
	medium: string
	low: string
} = {
	high: red[500],
	medium: orange[500],
	low: yellow[500],
}

type Props = {
	severity?: Severity
}

export default function SeverityIcon({ severity }: Props) {
	return <Warning htmlColor={severity ? colors[severity] : grey[500]} />
}
