import { Button, ButtonProps } from "@mui/material"
import { useMessage } from "providers/MessageProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import { resizeImage } from "tools/imgHelpers"

interface Props extends Omit<ButtonProps, "onChange"> {
	label: string
	multiple?: boolean
	onChange: (images: File[]) => void
}

export default function ImageInput({ label, onChange, multiple, ...props }: Props) {
	const message = useMessage()

	const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return
		}
		try {
			const images = (await Promise.all(
				Array.prototype.map.call(event.target.files, (file) => resizeImage(file))
			)) as File[]
			// const image = await resizeImage(event.target.files[0])
			onChange(images)
		} catch (e) {
			message.error(appendErrorMessage("Er ging iets fout bij het lezen van de afbeelding", e))
		} finally {
			event.target.value = ""
		}
	}

	return (
		<>
			<label>
				<Button component="span" {...props}>
					{label}
				</Button>
				<input
					type="file"
					name="image"
					accept="image/*"
					style={{ display: "none" }}
					multiple={multiple}
					onChange={handleFileInput}
				/>
			</label>
		</>
	)
}
