export const SIGN_IN = "sign-in"
export const SIGN_OUT = "sign-out"
export const INSTALLATIONS = "installations"
export const INSTALLATION = "installation"
export const ACTIVATE = "activate"
export const SETTINGS = "settings"
export const ACCOUNT = "account"
export const SERVICE_REQUESTS = "service-requests"
export const SERVICE_REQUEST = "service-request"
export const NEW = "new"
export const RESET_PASSWORD = "reset-password"
export const HEAT_PUMP = "heat-pump"
export const HEAT_PUMP_LIST = "heat-pumps"
export const FORGOT_PASSWORD = "forgot-password"
export const CREATE_ACCOUNT = "create-account"
export const STATUS = "status"
export const MANAGEMENT = "management"
export const MONITORING = "monitoring"
export const ADMIN = "admin"
export const EMAIL = "email"
export const ORGANIZATION_ID = "organization-id"
export const ORGANIZATION_NAME = "organization-name"
export const ORGANIZATION_MANAGEMENT = "organization-management"
export const INTERFACE_ID = "interface-id"
export const NOT_FOUND = "404"
export const INSTALLATION_HELP = "installation-help"
