/* eslint-disable i18next/no-literal-string */ //Internal only
import { useState } from "react"
import Table from "components/Table"
import CreateHeatPump from "components/forms/UpsertHeatPump"
import { Typography } from "@mui/material"
import { ServiceHeatPumpFieldsFragment, useServiceHeatPumpsQuery } from "generated/graphql"
import { SearchProps } from "./Users"
import Fuse from "fuse.js"
import AddButton from "components/AddButton"

const labels = [
	{ key: "name", name: "Naam", sortable: true },
	{ key: "maxPower", name: "Maximaal vermogen (kW)", sortable: true },
	{ key: "minPower", name: "Minimaal vermogen (kW)", sortable: true },
	{ key: "maxFreq", name: "Maximale frequentie (Hz)", sortable: true },
	{ key: "minFreq", name: "Minimale frequentie (Hz)", sortable: true },
]

const options = {
	includeScore: true,
	threshold: 0.4,
	keys: ["name"],
}

export default function HeatPumps({ search }: SearchProps) {
	const { data, loading, error } = useServiceHeatPumpsQuery()
	const [addDialogOpen, setAddDialogOpen] = useState(false)
	const [heatPump, setHeatPump] = useState<ServiceHeatPumpFieldsFragment | null>(null)

	const handleAdd = () => setAddDialogOpen(true)

	const fuse = new Fuse(data?.serviceHeatPumps ?? [], options)
	const heatPumps = search ? fuse.search(search).map(({ item }) => item) : data?.serviceHeatPumps ?? []

	const handleEdit = (id: string) => {
		const heatPump = heatPumps.find((hp) => hp?.id === id)
		setHeatPump(heatPump ?? null)
		setAddDialogOpen(true)
	}

	if (loading) return <Typography>Warmtepompen ophalen...</Typography>
	if (error) return <Typography>Fout bij het ophalen van de warmtepompen...</Typography>

	return (
		<>
			<AddButton onClick={handleAdd}>Warmtepomp toevoegen</AddButton>
			<Table defaultItemsPerPage={20} rows={heatPumps} labels={labels} loading={loading} onClickRow={handleEdit} />
			{addDialogOpen && (
				<CreateHeatPump
					heatPump={heatPump}
					onClose={() => {
						setHeatPump(null)
						setAddDialogOpen(false)
					}}
				/>
			)}
		</>
	)
}
