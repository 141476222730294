import { RefObject, useRef } from "react"

export default function useFocus(): [RefObject<HTMLDivElement>, () => void] {
	const ref = useRef<HTMLDivElement>(null)
	const focus = () => {
		// wait 100 ms for everything to rerender, and then focus.
		setTimeout(() => {
			if (!ref.current) return
			const inputs = ref.current.getElementsByTagName("input")
			inputs[0]?.focus()
		}, 100)
	}
	return [ref, focus]
}
