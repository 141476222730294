/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography, CardContent, Grid, Tooltip } from "@mui/material"
import Table, { Label } from "components/Table"
import { Headset, Today } from "@mui/icons-material"
import { AfasItem, useAfasOrderQuery, useInstallationQuery } from "generated/graphql"
import { Skeleton } from "@mui/material"
import { useEffect } from "react"
import CustomCard from "components/CustomCard"
import formatTime from "tools/formatTime"

const labels: Label<AfasItem>[] = [
	{ key: "name", name: "Naam", sortable: true },
	{ key: "amount", name: "Aantal", width: 50 },
	{ key: "type", name: "Type", sortable: true, width: 200 },
]

type Props = {
	installationId: string
}

export default function AfasOrderCard({ installationId }: Props) {
	const {
		data: iData,
		loading: iLoading,
		error: iError,
	} = useInstallationQuery({
		variables: { installationId },
	})

	const orderNumber = iData?.installation?.afasOrderNumber
	const { data, loading, error, refetch } = useAfasOrderQuery({
		variables: { orderNumber: orderNumber ?? "" },
		skip: !orderNumber,
		notifyOnNetworkStatusChange: true,
	})

	useEffect(() => {
		if (orderNumber) refetch()
	}, [orderNumber, refetch])

	const order = data?.afasOrder

	if (!orderNumber) return null

	return (
		<CustomCard>
			<CardContent>
				{/* for some reason the fragment is needed else react complains */}
				<>
					<Typography variant="h5" gutterBottom>
						AFAS order #{orderNumber}
					</Typography>
					{(loading || iLoading) && (
						<Grid container spacing={4} direction="column">
							<Grid item>
								<Typography>Order ophalen uit AFAS...</Typography>
							</Grid>
							<Grid item>
								<Grid container spacing={2} direction="column">
									<Grid item>
										<Skeleton />
									</Grid>
									<Grid item>
										<Skeleton />
									</Grid>
									<Grid item>
										<Skeleton />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)}
					{(error || iError) && <Typography>Er ging iets mis bij het ophalen uit AFAS...</Typography>}
					{!iLoading && !loading && !error && !order && (
						<Typography>
							De order is niet gevonden in AFAS. Dit kan gebeuren als dit ordernummer niet (meer) bestaat in AFAS.
						</Typography>
					)}
					{!loading && !error && order && (
						<>
							<Grid container spacing={4} direction="column">
								<Grid item>
									<Typography>
										Dit is de order zoals deze in AFAS staat. Let op, dit kan een order van meerdere installaties zijn.
									</Typography>
								</Grid>
								<Grid item>
									<Grid container spacing={2} direction="column">
										<Grid item>
											<Grid container spacing={2} alignItems="center">
												<Grid item>
													<Today />
												</Grid>
												<Grid item>
													<Tooltip title="Datum van invoeren">
														<Typography>{formatTime(order.createdAt)}</Typography>
													</Tooltip>
												</Grid>
												<Grid item>
													<Headset />
												</Grid>
												<Grid item>
													<Tooltip title="Verwerkte status">
														<Typography>{order.status}</Typography>
													</Tooltip>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							{order.items && (
								<Table
									defaultItemsPerPage={20}
									rows={[...order.items]}
									labels={labels}
									defaultSortCol="type"
									smallTable="sm"
									fullWidth
								/>
							)}
						</>
					)}
				</>
			</CardContent>
		</CustomCard>
	)
}
