export function createImage(base64Img: string): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const img = document.createElement("img")
		img.src = base64Img
		img.onload = () => resolve(img)
		img.onerror = () => reject(new Error("Could not load image."))
	})
}

export function canvasToFile(
	canvas: HTMLCanvasElement,
	outputFormat = "image/jpeg",
	lastModified: number,
	name: string
): Promise<File> {
	return new Promise((resolve, reject) => {
		canvas.toBlob(
			(blob) => {
				if (blob)
					resolve(
						new File([blob], name, {
							lastModified: lastModified,
							type: blob.type,
						})
					)
				else reject(new Error(`Error converting image to ${outputFormat}.`))
			},
			outputFormat,
			0.85
		)
	})
}

export function fileToBase64(file: File | Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = async () => {
			if (typeof reader.result === "string") resolve(reader.result)
			else reject(new Error("Unexpected reader result type."))
		}
		reader.readAsDataURL(file)
	})
}

export async function resizeImage(file: File, maxSize = 2000, outputFormat?: string) {
	const base64Img = await fileToBase64(file)

	const canvas = document.createElement("canvas")
	const context = canvas.getContext("2d")
	const img = await createImage(base64Img)

	const maxSide = Math.max(img.width, img.height)

	const scale = maxSize / maxSide
	const newWidth = scale < 1 ? Math.round(scale * img.width) : img.width
	const newHeight = scale < 1 ? Math.round(scale * img.height) : img.height

	canvas.width = newWidth
	canvas.height = newHeight

	context?.drawImage(img, 0, 0, newWidth, newHeight)
	return await canvasToFile(canvas, outputFormat, file.lastModified, file.name)
}
