/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"

interface Installation {
	heatPump?: {
		name: string
		maxPower?: number | null
	} | null
	heatPumpCount?: number | null
	coolingModule?: {
		name: string
	} | null
}

type Props = {
	children?: Installation | null
}

export default function HeatPump({ children: installation }: Props) {
	return (
		<Typography>
			{installation?.heatPump ? (
				<>
					{installation.heatPumpCount && <>{installation.heatPumpCount} x </>}
					{installation.heatPump.name}
					{/* {installation.heatPump.maxPower && (
            <> ({installation.heatPump.maxPower} kW)</>
          )} */}
					{installation.coolingModule && (
						<>
							<br />
							{installation.coolingModule.name}
						</>
					)}
				</>
			) : (
				<>Onbekende warmtepomp</>
			)}
		</Typography>
	)
}
