/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"

interface Installation {
	inverterType?: string | null
}

type Props = {
	children?: Installation | null
}

export default function InverterType({ children: installation }: Props) {
	return (
		<Typography>{installation?.inverterType ? <>{installation.inverterType}</> : <>Onbekende omvormer</>}</Typography>
	)
}
