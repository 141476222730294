import { TextField } from "@mui/material"

type Props = {
	value?: string
	onChange: (value: string | null) => void
	onFocus?: () => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
	autoFocus?: boolean
	rows?: number
	defaultValue?: string
}

export default function TextAreaInput({
	value,
	onChange,
	label,
	required,
	error,
	helperText,
	onFocus,
	autoFocus,
	rows = 5,
	defaultValue = undefined,
}: Props) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value === "" ? null : e.target.value)
	}

	const handleFocus = () => {
		// on focus, set value to default
		if (defaultValue != null && value == null) onChange(defaultValue)
	}

	return (
		<TextField
			fullWidth
			multiline
			rows={rows}
			variant="outlined"
			required={required}
			autoFocus={autoFocus}
			margin="normal"
			label={label}
			type="text"
			value={value ?? ""}
			onChange={handleChange}
			onFocus={onFocus ?? handleFocus}
			error={error}
			helperText={helperText}
			placeholder={defaultValue}
			InputLabelProps={{
				shrink: defaultValue != null ? true : undefined,
			}}
		/>
	)
}
