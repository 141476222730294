/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import {
	InstallationServiceRequestsFragment,
	InstallationServiceRequestsFragmentDoc,
	OpenStatus,
	ServiceRequestHistoryInput,
	ServiceRequestsQueryFieldsFragment,
	ServiceRequestsQueryFieldsFragmentDoc,
	useUpsertServiceRequestHistoryMutation,
} from "generated/graphql"
import TextAreaInput from "inputs/TextAreaInput"
import StatusTypeInput from "inputs/StatusTypeInput"
import DateInput from "inputs/DateInput"
import { differenceInSeconds } from "date-fns"
import appendErrorMessage from "tools/appendErrorMessage"
import ImageInput from "../../inputs/ImageInput"
import { useState } from "react"
import DropZone from "../Dropzone"
import { FileResult } from "../../tools/getFile"
import { resizeImage } from "../../tools/imgHelpers"
import { useTranslation } from "react-i18next"

const getInitialServiceRequestHistory = (serviceRequestHistory?: Partial<ServiceRequestHistoryInput> | null) => ({
	...serviceRequestHistory,
	timestamp: serviceRequestHistory?.timestamp ? serviceRequestHistory?.timestamp : new Date().toISOString(),
})

type Props = {
	onClose: () => void
	serviceRequestHistory?: Partial<ServiceRequestHistoryInput> | null
}

export default function UpsertServiceRequestHistory({
	onClose,
	serviceRequestHistory: editServiceRequestHistory,
}: Props) {
	const { t } = useTranslation(["general", "serviceRequests"])
	const [upsertServiceRequestHistory, { loading }] = useUpsertServiceRequestHistoryMutation({})
	const [serviceImages, setServiceImages] = useState<File[]>()

	const message = useMessage()
	const { register, submit } = useForm<ServiceRequestHistoryInput>(
		getInitialServiceRequestHistory(editServiceRequestHistory),
		handleSave,
		["serviceRequestId"]
	)

	const handleDrop = async (imgs: FileResult[]) => {
		const resized: File[] = await Promise.all(imgs.map(({ data }) => resizeImage(data)))
		setServiceImages(resized)
	}

	async function handleSave(serviceRequestHistory: ServiceRequestHistoryInput) {
		let installationId: string | undefined

		serviceRequestHistory.images = serviceImages

		try {
			await upsertServiceRequestHistory({
				variables: { serviceRequestHistory },
				update: (cache, { data }) => {
					if (!data) return
					const srHistoryItem = data.upsertServiceRequestHistory
					// add/replace history item to servicerequest and update status field
					try {
						const { serviceRequestId } = serviceRequestHistory
						const id = `ServiceRequest:${serviceRequestId}`

						const oldServiceRequest = cache.readFragment<ServiceRequestsQueryFieldsFragment>({
							id,
							fragment: ServiceRequestsQueryFieldsFragmentDoc,
							fragmentName: "ServiceRequestsQueryFields",
						})
						if (!oldServiceRequest) return
						installationId = oldServiceRequest.installation?.id
						const newHistory = [
							srHistoryItem,
							...oldServiceRequest.history.filter(({ id }) => id !== srHistoryItem?.id),
						].sort((h1, h2) => {
							if (!h1 || !h2) return 0
							return differenceInSeconds(new Date(h2.timestamp), new Date(h1.timestamp))
						})
						const newestItem = newHistory[0]
						const status = newestItem?.statusType.status ?? OpenStatus.Open
						const newServiceRequest = { ...oldServiceRequest, history: newHistory, status }
						cache.writeFragment({
							id,
							fragment: ServiceRequestsQueryFieldsFragmentDoc,
							fragmentName: "ServiceRequestsQueryFields",
							data: newServiceRequest,
						})
					} catch (e) {
						console.error(e)
					}
					// update hasOpenServiceRequests field from installation (a lot of work for what it does...)
					try {
						if (!installationId) return
						const id = `Installation:${installationId}`
						const oldInstallation = cache.readFragment<InstallationServiceRequestsFragment>({
							id,
							fragment: InstallationServiceRequestsFragmentDoc,
							fragmentName: "InstallationServiceRequests",
						})
						if (!oldInstallation?.serviceRequests) throw new Error("no servicerequests in cache for installation")
						const { serviceRequests } = oldInstallation
						const hasOpenServiceRequests = serviceRequests.some((sr) => sr.status !== OpenStatus.Closed)
						const hasOpenIBS = serviceRequests.some(
							(sr) => sr.status !== OpenStatus.Closed && sr.serviceType.name === "IBS"
						)
						const updatedInstallation = {
							...oldInstallation,
							hasOpenServiceRequests,
							hasOpenIBS,
						}
						cache.writeFragment({
							id,
							fragment: InstallationServiceRequestsFragmentDoc,
							fragmentName: "InstallationServiceRequests",
							data: updatedInstallation,
						})
					} catch (e) {
						// catch if not in cache, ignore
					}
				},
			})
			message.success(t("serviceRequests:ServiceRequestSaved"))
			onClose()
		} catch (e) {
			message.error(appendErrorMessage(t("serviceRequests:Errors.Saving"), e))
		}
	}

	return (
		<TSDialog autoFullScreen open fullWidth onClose={onClose} aria-labelledby="form-dialog-title">
			<DropZone accept="image/*" onDrop={handleDrop} multiple>
				<DialogTitle id="form-dialog-title">{t("serviceRequests:ServiceRequestLog")}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t("serviceRequests:ServiceRequestLogDescription")}</DialogContentText>
					<DateInput
						label={t("serviceRequests:CreatedAt")}
						{...register("timestamp", {
							required: true,
							datetime: true,
						})}
					/>
					<StatusTypeInput label={t("Status")} {...register("statusTypeId", { required: true })} />
					<DateInput label={t("serviceRequests:DatePlanned")} clearable {...register("plannedVisit")} />
					<TextAreaInput label={t("serviceRequests:Description")} {...register("description", { required: true })} />

					{!editServiceRequestHistory?.id && (
						<>
							<ImageInput
								label={t("serviceRequests:SelectImage")}
								color="primary"
								disabled={loading}
								onChange={setServiceImages}
								multiple
							/>
							{serviceImages?.length && <Typography>{t("image", { count: serviceImages.length })}</Typography>}
						</>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary" disabled={loading}>
						{t("general:Cancel")}
					</Button>
					<Button onClick={submit} color="primary" disabled={loading}>
						{t("general:Save")}
					</Button>
				</DialogActions>
			</DropZone>
		</TSDialog>
	)
}
