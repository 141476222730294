/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import CreateInstallation from "components/forms/UpsertInstallation"
import SearchableSelect from "components/SearchableSelect"
import { InstallationFieldsFragment, useInstallationsQuery } from "generated/graphql"
import { useState } from "react"
import formatInstallation from "tools/formatInstallation"

type Props = {
	value?: string | null
	onChange: (value: string | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function CoolantInput({ value, onChange, label = "Installatie", required, error, helperText }: Props) {
	const { data, loading, error: qError } = useInstallationsQuery()

	const [closeHandler, setCloseHandler] = useState<
		(() => (coolant: InstallationFieldsFragment | null) => void) | null
	>()

	const handleChange = (e: { target: { value: string | number | null } }) => {
		onChange(typeof e.target.value === "string" ? e.target.value : null)
	}

	const handleNew = () => {
		setCloseHandler(() => (installation: InstallationFieldsFragment | null) => {
			setCloseHandler(null)
			onChange(installation?.id ?? null)
		})
	}

	if (qError) {
		return <Typography color="error">Er ging iets fout bij het ophalen van de installaties</Typography>
	}

	return (
		<>
			<SearchableSelect
				value={value}
				onChange={handleChange}
				options={data?.installations ?? []}
				getOptionLabel={(i) => formatInstallation(i) ?? ""}
				disabled={loading || Boolean(qError)}
				label={label}
				required={required}
				error={error}
				helperText={helperText}
				onCreateNew={handleNew}
			/>
			{closeHandler && <CreateInstallation onClose={closeHandler} />}
		</>
	)
}
