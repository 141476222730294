interface DefaultResult {
	filename: string
	extension: string
	fullFilename: string
}

export type StringResult = DefaultResult & { data: string }
export type FileResult = DefaultResult & { data: File }

function getFileData(file: File) {
	const fullFilename = file.name
	const parts = fullFilename.split(".")
	const filename = parts.slice(0, -1).join(".")
	const extension = "." + parts[parts.length - 1]
	return { filename, extension, fullFilename } as DefaultResult
}

export default function getFile(file: File, readAs: "string"): Promise<StringResult>
export default function getFile(file: File, readAs: "file"): Promise<FileResult>

export default function getFile(file: File, readAs: string): Promise<DefaultResult & { data: File | string }> {
	const metadata = getFileData(file)
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.onload = () => {
			if (typeof reader.result === "string") {
				resolve({
					...metadata,
					data: reader.result,
				})
			}
		}
		switch (readAs) {
			case "file":
				resolve({
					...metadata,
					data: file,
				})
				break
			case "string":
				reader.readAsText(file)
				break
			default:
				throw new Error(`Unknown value for readAs: ${readAs}`)
		}
	})
}
