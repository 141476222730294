/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import {
	StatusTypeFieldsFragment,
	StatusTypeInput,
	StatusTypesDocument,
	useUpsertStatusTypeMutation,
} from "generated/graphql"
import status from "settings/status"
import TextInput from "inputs/TextInput"
import RadioInput from "inputs/RadioInput"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	onClose: () => void
	statusType?: StatusTypeFieldsFragment | null
}

export default function UpsertStatusType({ onClose, statusType: editStatusType }: Props) {
	const [upsertStatusType, { loading }] = useUpsertStatusTypeMutation({
		refetchQueries: [{ query: StatusTypesDocument }],
	})
	const message = useMessage()
	const { register, submit } = useForm<StatusTypeInput>(editStatusType, handleSave)

	async function handleSave(statusType: StatusTypeInput) {
		try {
			await upsertStatusType({ variables: { statusType } })
			message.success("Statustype opgeslagen")
			onClose()
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}

	return (
		<TSDialog autoFullScreen open onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Statustype</DialogTitle>
			<DialogContent>
				<DialogContentText>Vul hieronder het statustype in</DialogContentText>
				<TextInput autoFocus label="Naam" {...register("name", { required: true })} />
				<RadioInput options={status} {...register("status", { required: true })} label="Status" />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" disabled={loading}>
					Annuleren
				</Button>
				<Button onClick={submit} color="primary" disabled={loading}>
					Opslaan
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
