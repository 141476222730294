/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import CoolantTypes from "./components/CoolantTypes"
import CoolingModules from "./components/CoolingModules"
import HeatPumps from "./components/HeatPumps"
import PanelTypes from "./components/PanelTypes"
import ServiceTypes from "./components/ServiceTypes"
import StatusTypes from "./components/StatusTypes"
import Users from "./components/Users"
import Page from "components/Page"
import { ExpandMore } from "@mui/icons-material"
import DebouncedMenuInput from "components/DebouncedMenuInput"
import { useState } from "react"
import { Role, useSettingsPageFilterQuery } from "generated/graphql"
import settingsPageFilter from "localstate/settingsListPageFilter"
import useSize from "hooks/useSize"
import Organization from "pages/SettingsPage/components/Organization"
import useHasRole from "hooks/useHasRole"
import { useTranslation } from "react-i18next"
import Notifications from "pages/SettingsPage/components/Notifications"

export default function SettingsPage() {
	const { t } = useTranslation(["groupManagement", "general"])
	const smallScreen = useSize("down", "md")
	const [search, setSearch] = useState("")
	const { data: settingsSearchData } = useSettingsPageFilterQuery()
	const isAdmin = useHasRole([Role.Admin])

	const updateSearch = (searchText: string) => {
		setSearch(searchText)
		settingsPageFilter({
			searchText: searchText,
		})
	}

	return (
		<Page
			title="Instellingen"
			backButton
			hideTitle={smallScreen}
			middle={
				<DebouncedMenuInput
					initialValue={settingsSearchData?.settingsPageFilter?.searchText ?? ""}
					onChange={updateSearch}
					clearable
				/>
			}
		>
			{isAdmin && (
				<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography variant="subtitle1">{t("general:Notifications")}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Notifications search={search} />
					</AccordionDetails>
				</Accordion>
			)}

			<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="subtitle1">{t("general:Users")}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Users search={search} />
				</AccordionDetails>
			</Accordion>

			<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="subtitle1">{t("general:HeatPumps")}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<HeatPumps search={search} />
				</AccordionDetails>
			</Accordion>

			<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="subtitle1">Paneeltypes</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<PanelTypes search={search} />
				</AccordionDetails>
			</Accordion>

			<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="subtitle1">Koelmiddelen</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<CoolantTypes search={search} />
				</AccordionDetails>
			</Accordion>

			<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="subtitle1">Koelmodules</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<CoolingModules search={search} />
				</AccordionDetails>
			</Accordion>

			<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="subtitle1">Statustypes</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<StatusTypes search={search} />
				</AccordionDetails>
			</Accordion>

			<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="subtitle1">Servicetypes</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ServiceTypes search={search} />
				</AccordionDetails>
			</Accordion>

			{isAdmin && (
				<Accordion slotProps={{ transition: { unmountOnExit: true } }}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography variant="subtitle1">{t("general:Organization")}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Organization search={search} />
					</AccordionDetails>
				</Accordion>
			)}
		</Page>
	)
}
