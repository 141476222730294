/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"

interface Installation {
	street?: string
	zipCode?: string
	city?: string
	installerAddresss?: boolean
}

type Props = {
	children?: Installation | null
}

export default function HeatPump({ children: installation }: Props) {
	if (installation?.installerAddresss)
		return <Typography color="error">Onbekend adres (adres installateur ingevuld)</Typography>
	return (
		<Typography>
			{installation?.street ?? "Onbekende straat"}
			<br />
			{installation?.zipCode ?? "Onbekende postcode"}
			<br />
			{installation?.city ?? "Onbekende stad"}
		</Typography>
	)
}
