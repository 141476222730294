import { Navigate, useParams } from "react-router-dom"
import NamedListItem from "./components/NamedListItem"
import { ReadHeatPumpStatusDocument, Role, useInterfaceNameQuery, useReadHeatPumpStatusQuery } from "generated/graphql"
import { Stack, IconButton, CircularProgress, Typography, Tooltip } from "@mui/material"
import { Refresh } from "@mui/icons-material"
import useSize from "hooks/useSize"
import PageWithStatusBar from "components/PageWithStatusBar"
import "tools/showDecimalPoints"
import showDecimalPoints from "tools/showDecimalPoints"
import HeatPumpInfoDisplay from "components/HeatPumpInfoDisplay"
import HeatPumpInfoCard from "components/HeatPumpInfoCard"
import HeatPumpState from "./components/StatusDescriptions/HeatPumpState"
import HeatPumpErrors from "./components/StatusDescriptions/HeatPumpErrors"
import HeatPumpDemand from "./components/StatusDescriptions/HeatPumpDemand"
import HeatPumpRoomTemp from "./components/StatusDescriptions/HeatPumpRoomTemp"
import { NOT_FOUND } from "settings/url"
import useHasRole from "hooks/useHasRole"
import { useTranslation } from "react-i18next"
import ReceptionIcon from "./components/ReceptionIcon"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { useMessage } from "providers/MessageProvider"
import HeatPumpDmTemp from "pages/HeatPumpStatusPage/components/StatusDescriptions/HeatPumpDmTemp"

export default function HeatPumpStatusPage() {
	const message = useMessage()
	const [t] = useTranslation(["heatPumpStatus", "general"])

	const isInternal = useHasRole([Role.Admin, Role.Mechanic])
	const { interfaceId } = useParams<"interfaceId">()
	if (!interfaceId) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page

	const smallScreen = useSize("down", "md")
	const { data: interfaceNameQueryData } = useInterfaceNameQuery({ variables: { interfaceId } })
	const {
		data,
		loading: readStatusLoading,
		error,
		refetch,
	} = useReadHeatPumpStatusQuery({
		variables: { interfaceId, isInternal },
		fetchPolicy: "cache-and-network",
	})

	const copyToClipboard = () => {
		navigator.clipboard.writeText(window.location.href)
		message.info(t("general:InterfaceLinkCopied"))
	}

	return (
		<PageWithStatusBar noPadding={smallScreen} navigationName="status" interfaceId={interfaceId} backButton>
			<HeatPumpInfoDisplay
				titleCard={
					<>
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h4" alignSelf="center">
								{t("StatusOf")}{" "}
								{interfaceNameQueryData?.interface?.name ? interfaceNameQueryData?.interface.name : interfaceId}
							</Typography>

							<Stack direction="row" alignItems="center">
								<Tooltip title={t("general:CopyInterfaceLink")}>
									<IconButton onClick={copyToClipboard}>
										<ContentCopyIcon />
									</IconButton>
								</Tooltip>

								{(data || error) && (
									<>
										{!readStatusLoading ? (
											<Tooltip title={t("general:Refresh")}>
												<IconButton size="small" onClick={() => refetch()} disabled={readStatusLoading}>
													<Refresh fontSize="large" />
												</IconButton>
											</Tooltip>
										) : (
											<CircularProgress size={30} />
										)}
									</>
								)}
							</Stack>
						</Stack>
					</>
				}
				error={error}
				interfaceId={interfaceId}
				fetchOnClaim={ReadHeatPumpStatusDocument}
				isLoading={readStatusLoading && !data}
			>
				{data?.interface && (
					<>
						<HeatPumpInfoCard title={t("General")}>
							<HeatPumpDemand data={data.interface.pvtHeatPump} />
							<HeatPumpState data={data.interface.pvtHeatPump} onStarupTimerEnds={refetch} />
							<HeatPumpDmTemp data={data.interface.pvtHeatPump} />
							<HeatPumpErrors data={data.interface.pvtHeatPump} />
						</HeatPumpInfoCard>
						<HeatPumpRoomTemp data={data.interface} />
						{data.interface.pvtHeatPump && (
							<>
								<HeatPumpInfoCard title={t("Outlets")}>
									{data.interface.pvtHeatPump.compressorOn != null && (
										<NamedListItem
											name={t("CompressorRelay")}
											value={data.interface.pvtHeatPump.compressorOn ? t("general:On") : t("general:Off")}
										/>
									)}
									{data.interface.pvtHeatPump.electricElementOn != null && (
										<NamedListItem
											name={t("ElectricalBackup")}
											value={data.interface.pvtHeatPump.electricElementOn ? t("general:On") : t("general:Off")}
										/>
									)}
									{data.interface.pvtHeatPump.coolingValveEnabled != null && (
										<NamedListItem
											name={t("FourWayValveCooling")}
											value={data.interface.pvtHeatPump.coolingValveEnabled ? t("general:On") : t("general:Off")}
										/>
									)}
									{data.interface.pvtHeatPump.sourcePumpPerc != null && (
										<NamedListItem
											name={t("SourcePumpSpeed")}
											value={`${showDecimalPoints(data.interface.pvtHeatPump.sourcePumpPerc, 0)}%`}
										/>
									)}
									{data.interface.pvtHeatPump.sinkPumpPerc != null && (
										<NamedListItem
											name={t("SinkPumpSpeed")}
											value={`${showDecimalPoints(data.interface.pvtHeatPump.sinkPumpPerc, 0)}%`}
										/>
									)}
								</HeatPumpInfoCard>
								<HeatPumpInfoCard title={t("Temperatures")}>
									{data.interface.pvtHeatPump.dhwBoilerTemp != null && (
										<NamedListItem
											name={t("DHWTemp")}
											value={`${showDecimalPoints(data.interface.pvtHeatPump.dhwBoilerTemp, 1)}°C`}
										/>
									)}
									{data.interface.pvtHeatPump.sourceInTemp != null && (
										<NamedListItem
											name={t("SourceReturn")}
											value={`${showDecimalPoints(data.interface.pvtHeatPump.sourceInTemp, 1)}°C`}
										/>
									)}
									{data.interface.pvtHeatPump.sourceOutTemp != null && (
										<NamedListItem
											name={t("SourceSupply")}
											value={`${showDecimalPoints(data.interface.pvtHeatPump.sourceOutTemp, 1)}°C`}
										/>
									)}
									{data.interface.pvtHeatPump.sinkInTemp != null && (
										<NamedListItem
											name={t("SinkReturn")}
											value={`${showDecimalPoints(data.interface.pvtHeatPump.sinkInTemp, 1)}°C`}
										/>
									)}
									{data.interface.pvtHeatPump.sinkOutTemp != null && (
										<NamedListItem
											name={t("SinkSupply")}
											value={`${showDecimalPoints(data.interface.pvtHeatPump.sinkOutTemp, 1)}°C`}
										/>
									)}
									{data.interface.pvtHeatPump.compressorDischarge != null && (
										<NamedListItem
											name={t("CompressorDischarge")}
											value={`${showDecimalPoints(data.interface.pvtHeatPump.compressorDischarge, 1)}°C`}
										/>
									)}
								</HeatPumpInfoCard>
							</>
						)}
						<HeatPumpInfoCard title={t("Interface")}>
							<NamedListItem
								name={t("OTThermostatConnected")}
								value={data.interface.openThermThermostatConnected ? t("general:Yes") : t("general:No")}
							/>
							<NamedListItem
								name={t("OTBoilerConnected")}
								value={data.interface.openThermBoilerConnected ? t("general:Yes") : t("general:No")}
							/>
							<NamedListItem name={t("FirmwareVersionInterface")} value={data.interface.firmwareVersion.version} />
							{data.interface.status && (
								<NamedListItem
									name={t("MobileService")}
									value={
										<Stack direction="row" alignItems="center">
											<Typography
												component="span"
												textTransform="capitalize"
												paddingRight={1}
												sx={{ fontStyle: "italic" }}
											>
												{data.interface.status.operatorName}
											</Typography>
											<ReceptionIcon receptionStrenght={data.interface.status.signalStrength} />
										</Stack>
									}
								/>
							)}
						</HeatPumpInfoCard>
					</>
				)}
			</HeatPumpInfoDisplay>
		</PageWithStatusBar>
	)
}
