/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import {
	UpdateServiceRequestInput,
	Role,
	useUpdateServiceRequestMutation,
	ServiceRequestsQueryFieldsFragment,
	InstallationDocument,
} from "generated/graphql"
import ServiceTypeInput from "inputs/ServiceTypeInput"
import UserInput from "inputs/UserInput"
import BooleanInput from "inputs/BooleanInput"
import InstallationInput from "inputs/InstallationInput"
import DateInput from "inputs/DateInput"
import appendErrorMessage from "tools/appendErrorMessage"

const getInitialServiceRequest = (serviceRequest: Partial<ServiceRequestsQueryFieldsFragment>) => ({
	id: serviceRequest.id,
	installationId: serviceRequest.installation?.id ?? undefined,
	serviceTypeId: serviceRequest.serviceType?.id,
	assignedToId: serviceRequest.assignedTo?.id ?? undefined,
	invoice: serviceRequest.invoice ?? false,
	endDate: serviceRequest.endDate,
})

type Props = {
	onClose: () => void
	serviceRequest: Partial<ServiceRequestsQueryFieldsFragment>
}

export default function CreateServiceRequest({ onClose, serviceRequest: editServiceRequest }: Props) {
	const [updateServiceRequest, { loading }] = useUpdateServiceRequestMutation()

	const message = useMessage()
	const { register, submit } = useForm<UpdateServiceRequestInput>(
		getInitialServiceRequest(editServiceRequest),
		handleSave
	)

	async function handleSave(serviceRequest: UpdateServiceRequestInput) {
		try {
			await updateServiceRequest({
				variables: {
					serviceRequest: {
						...serviceRequest,
						installationId: serviceRequest.installationId || null,
					},
				},
				//Retch installation only if installation is removed otherwise fetched by mutatation return
				refetchQueries:
					editServiceRequest.installation?.id && !serviceRequest.installationId
						? [{ query: InstallationDocument, variables: { installationId: editServiceRequest.installation.id } }]
						: [],
			})
			message.success("Serviceverzoek opgeslagen")
			onClose()
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}

	return (
		<TSDialog autoFullScreen open fullWidth onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Serviceverzoek</DialogTitle>
			<DialogContent>
				<DialogContentText>Pas de onderstaande velden aan</DialogContentText>
				<InstallationInput {...register("installationId")} />
				<ServiceTypeInput {...register("serviceTypeId", { required: true })} />
				<UserInput label="Toegewezen aan" roles={[Role.Mechanic, Role.Admin]} {...register("assignedToId")} />
				<DateInput label="Gewenste einddatum" clearable {...register("endDate")} />
				<BooleanInput label="Factureren" {...register("invoice")} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" disabled={loading}>
					Annuleren
				</Button>
				<Button onClick={submit} color="primary" disabled={loading}>
					Opslaan
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
