/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import CreatePanelType from "components/forms/UpsertPanelType"
import SearchableSelect from "components/SearchableSelect"
import { PanelFieldsFragment, usePanelsQuery } from "generated/graphql"
import { useState } from "react"

type Props = {
	value?: string | null
	onChange: (value: string | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function PanelInput({ value, onChange, label = "Paneeltype", required, error, helperText }: Props) {
	const { data, loading, error: qError } = usePanelsQuery()

	const [closeHandler, setCloseHandler] = useState<(() => (coolant: PanelFieldsFragment | null) => void) | null>()

	const handleChange = (e: { target: { value: string | number | null } }) => {
		onChange(typeof e.target.value === "string" ? e.target.value : null)
	}

	const handleNew = () => {
		setCloseHandler(() => (panel: PanelFieldsFragment | null) => {
			setCloseHandler(null)
			onChange(panel?.id ?? null)
		})
	}

	if (qError) {
		return <Typography color="error">Er ging iets fout bij het ophalen van de paneeltypes</Typography>
	}

	return (
		<>
			<SearchableSelect
				value={value}
				onChange={handleChange}
				options={data?.panels ?? []}
				getOptionLabel={({ name }) => name}
				disabled={loading || Boolean(qError)}
				label={label}
				required={required}
				error={error}
				helperText={helperText}
				onCreateNew={handleNew}
			/>
			{closeHandler && <CreatePanelType onClose={closeHandler} />}
		</>
	)
}
