/* eslint-disable i18next/no-literal-string */ //Internal only
import { Button } from "@mui/material"

const BASE_LINK = `https://www.google.com/maps/dir/?api=1`

type Props = {
	street?: string
	zipCode?: string
	city?: string
}

export default function GoogleMapsDir({ street, zipCode, city }: Props) {
	const hasAddress = street && zipCode && city
	const href = hasAddress ? `${BASE_LINK}&destination=${street}, ${zipCode}, ${zipCode}&travelmode=driving` : ""
	return (
		<Button component="a" href={href} rel="noopener noreferrer" target="_blank" disabled={!hasAddress}>
			Navigeren
		</Button>
	)
}
