//Is this still used?
/* eslint-disable i18next/no-literal-string */
import { createContext, useState, useContext, useRef } from "react"
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import TSDialog from "components/TSDialog"

type Option = { label: string; value: string }

type Choice = {
	message: React.ReactNode
	choices: [Option, Option]
}

interface ChoiceDialogProps extends Choice {
	onSubmit: (value: string) => void
	onClose: () => void
}

const ChoiceDialog = ({ message, choices, onSubmit, onClose }: ChoiceDialogProps) => {
	return (
		<TSDialog open onClose={() => onClose()}>
			<DialogTitle>Kies een optie:</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={() => onSubmit(choices[0].value)}>
					{choices[0].label}
				</Button>
				<Button color="primary" onClick={() => onSubmit(choices[1].value)}>
					{choices[1].label}
				</Button>
				<Button onClick={onClose} autoFocus>
					Annuleren
				</Button>
			</DialogActions>
		</TSDialog>
	)
}

const ChoiceServiceContext = createContext<(choice: Choice) => Promise<unknown>>(Promise.reject)

type ChoiceProviderProps = {
	children: React.ReactNode
}

export function ChoiceProvider({ children }: ChoiceProviderProps) {
	const [choiceOptions, setChoiceOptions] = useState<Choice | null>(null)
	const awaitingPromiseRef = useRef<{
		resolve: (value: string) => void
	} | null>(null)

	const openConfirmation = (choice: Choice) => {
		setChoiceOptions(choice)
		return new Promise((resolve) => {
			awaitingPromiseRef.current = { resolve }
		})
	}

	const handleClose = () => {
		setChoiceOptions(null)
	}

	const handleSubmit = (value: string) => {
		if (awaitingPromiseRef.current) {
			awaitingPromiseRef.current.resolve(value)
		}
		setChoiceOptions(null)
	}

	return (
		<>
			<ChoiceServiceContext.Provider value={openConfirmation}>{children}</ChoiceServiceContext.Provider>
			{choiceOptions && <ChoiceDialog {...choiceOptions} onClose={handleClose} onSubmit={handleSubmit} />}
		</>
	)
}

export const useChoice = () => useContext(ChoiceServiceContext)
