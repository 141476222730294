/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import useForm from "hooks/useForm"
import severity from "settings/severity"
import TSDialog from "components/TSDialog"
import {
	ServiceTypeInput,
	ServiceTypesDocument,
	useUpsertServiceTypeMutation,
	ServiceTypeFieldsFragment,
} from "generated/graphql"
import TextInput from "inputs/TextInput"
import RadioInput from "inputs/RadioInput"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	onClose: () => void
	serviceType?: ServiceTypeFieldsFragment | null
}

export default function UpsertServiceType({ onClose, serviceType: editServiceType }: Props) {
	const [upsertServiceType, { loading }] = useUpsertServiceTypeMutation({
		refetchQueries: [{ query: ServiceTypesDocument }],
	})
	const message = useMessage()
	const { register, submit } = useForm<ServiceTypeInput>(editServiceType, handleSave)

	async function handleSave(serviceType: ServiceTypeInput) {
		try {
			await upsertServiceType({ variables: { serviceType } })
			message.success("Servicetype opgeslagen")
			onClose()
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}

	return (
		<TSDialog autoFullScreen open onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Servicetype</DialogTitle>
			<DialogContent>
				<DialogContentText>Vul hieronder het servicetype in</DialogContentText>
				<TextInput autoFocus label="Naam" {...register("name", { required: true })} />
				<RadioInput options={severity} {...register("severity", { required: true })} label="Prioriteit" />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" disabled={loading}>
					Annuleren
				</Button>
				<Button onClick={submit} color="primary" disabled={loading}>
					Opslaan
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
