/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import { ServiceHeatPumpsDocument, NewServiceHeatPump, useUpsertServiceHeatPumpMutation } from "generated/graphql"
import TextInput from "inputs/TextInput"
import NumberInput from "inputs/NumberInput"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	onClose: (heatPump: NewServiceHeatPump | null) => void
	heatPump?: NewServiceHeatPump | null
}

export default function CreateHeatPump({ onClose, heatPump: editHeatPump }: Props) {
	const [upsertHeatPump, { loading }] = useUpsertServiceHeatPumpMutation({
		refetchQueries: [{ query: ServiceHeatPumpsDocument }],
		awaitRefetchQueries: true,
	})
	const message = useMessage()
	const { register, submit } = useForm<NewServiceHeatPump>(editHeatPump, handleSave)

	async function handleSave(heatPump: NewServiceHeatPump) {
		try {
			const { data } = await upsertHeatPump({
				variables: {
					heatPump: {
						...heatPump,
						minPower: heatPump.minPower,
						maxPower: heatPump.maxPower,
						minFreq: heatPump.minFreq,
						maxFreq: heatPump.maxFreq,
					},
				},
			})
			message.success("Warmtepomp opgeslagen")
			onClose(data?.upsertServiceHeatPump ?? null)
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}

	const handleClose = () => onClose(null)

	return (
		<TSDialog autoFullScreen open onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Nieuw warmtepomptype</DialogTitle>
			<DialogContent>
				<DialogContentText>Vul hieronder de gegevens over deze warmtepomp in.</DialogContentText>
				<TextInput autoFocus label="Naam" {...register("name", { required: true })} />
				<NumberInput label="Max vermogen (kW)" {...register("maxPower", { min: 0 })} />
				<NumberInput label="Min vermogen (kW)" {...register("minPower", { min: 0 })} />
				<NumberInput label="Max frequentie (Hz)" {...register("maxFreq", { min: 0 })} />
				<NumberInput label="Min frequentie (Hz)" {...register("minFreq", { min: 0 })} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" disabled={loading}>
					Annuleren
				</Button>
				<Button onClick={submit} color="primary" disabled={loading}>
					Opslaan
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
