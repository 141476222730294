import Page from "components/Page"
import { Typography, Grid } from "@mui/material"
import { useParams } from "react-router"
import StatusCard from "./components/StatusCard"
import InstallationCard from "./components/InstallationCard"
import HistoryCard from "./components/HistoryCard"
import useSize from "hooks/useSize"
import { useServiceRequestQuery } from "generated/graphql"
import { Navigate } from "react-router-dom"
import { NOT_FOUND } from "settings/url"
import ServiceAppReadOnlyBanner from "components/ServiceAppReadOnlyBanner"

export default function ServiceRequestPage() {
	const { sequenceNumber } = useParams<"sequenceNumber">()
	if (!sequenceNumber) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page

	const smallScreen = useSize("down", "md")
	const { data, loading, error } = useServiceRequestQuery({
		variables: { sequenceNumber },
	})
	const serviceRequest = data?.serviceRequest

	return (
		<Page
			title={
				serviceRequest ? `${!smallScreen ? "Serviceverzoek " : ""}#${serviceRequest.sequenceNumber}` : "Serviceverzoek"
			}
			backButton
			noPadding={smallScreen}
		>
			<ServiceAppReadOnlyBanner />
			{loading && !error && <Typography>Serviceverzoek ophalen...</Typography>}
			{error && <Typography>Er ging iets mis bij het ophalen van het serviceverzoek...</Typography>}
			{!error && !loading && serviceRequest && (
				<Grid container spacing={smallScreen ? 0 : 2} alignItems="stretch">
					<Grid item xs={12} lg={6}>
						<StatusCard sequenceNumber={sequenceNumber} />
					</Grid>
					<Grid item xs={12} lg={6}>
						<InstallationCard sequenceNumber={sequenceNumber} />
					</Grid>
					<Grid item xs={12}>
						<HistoryCard sequenceNumber={sequenceNumber} />
					</Grid>
				</Grid>
			)}
		</Page>
	)
}
