/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import { NewPanel, PanelFieldsFragment, PanelsDocument, useUpsertPanelMutation } from "generated/graphql"
import TextInput from "inputs/TextInput"
import NumberInput from "inputs/NumberInput"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	panel?: PanelFieldsFragment | null
	onClose: (panel: PanelFieldsFragment | null) => void
}

export default function UpsertPanelType({ onClose, panel: editPanel }: Props) {
	const [createPanel, { loading }] = useUpsertPanelMutation({
		refetchQueries: [{ query: PanelsDocument }],
		awaitRefetchQueries: true,
	})
	const message = useMessage()
	const { register, submit } = useForm<NewPanel>(editPanel, handleSave)

	async function handleSave(panel: NewPanel) {
		try {
			const { data } = await createPanel({
				variables: {
					panel: panel,
				},
			})
			if (!data?.upsertPanel) return
			message.success("Paneeltype opgeslagen")
			onClose(data.upsertPanel)
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}

	const handleClose = () => onClose(null)

	return (
		<TSDialog autoFullScreen open onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Nieuw paneeltype</DialogTitle>
			<DialogContent>
				<DialogContentText>Vul hieronder de gegevens over dit type paneel in.</DialogContentText>
				<TextInput autoFocus label="Naam" {...register("name", { required: true })} />
				<NumberInput label="Paneelopparvlak (m2)" {...register("area", { required: true, min: 0 })} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" disabled={loading}>
					Annuleren
				</Button>
				<Button onClick={submit} color="primary" disabled={loading}>
					Opslaan
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
