import { useEffect, useMemo, useRef, useState } from "react"
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"
import icon from "leaflet/dist/images/marker-icon.png"
import shadow from "leaflet/dist/images/marker-shadow.png"
import Popover from "components/Popover"
import useSize from "hooks/useSize"
import { MarkerColor, StyledMap } from "./StyledMap"

export interface Location {
	lat: number
	lon: number
}
export interface Marker extends Location {
	id: string
	title: string
	color: keyof typeof MarkerColor
}

export interface View {
	center?: Location | null
	zoom?: number | null
}

type Props = {
	markers?: Marker[]
	onClickMarker?: (id: string) => void
	view?: View
	onViewChange?: (view: View) => void
}

export class LabelledMarker extends L.Marker {
	label: string
	constructor(latLng: L.LatLngExpression, title: string, options?: L.MarkerOptions) {
		super(latLng, options)
		this.label = title
	}
	getLabel() {
		return this.label
	}
	setLabel(title: string) {
		this.label = title
	}
}

export default function Map({ markers: markerLocations, onClickMarker, view, onViewChange }: Props) {
	const mapRef = useRef<L.Map | null>(null)
	const markers = useRef<L.Marker[]>([])
	const [selectedMarker, setSelectedMarker] = useState<LabelledMarker | null>(null)
	const smallScreen = useSize("down", "sm")

	// must recreate here, because it will be destroyed globally by leaflet
	const smallScreenLayer = useMemo(
		() =>
			L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
				attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
				tileSize: 128,
				zoomOffset: 1,
			}),
		[]
	)

	const largeScreenLayer = useMemo(
		() =>
			L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
				attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
			}),
		[]
	)

	useEffect(() => {
		if (!markerLocations) return
		if (!mapRef.current) {
			mapRef.current = L.map("leaflet-map")
			largeScreenLayer.addTo(mapRef.current)
			if (onViewChange) {
				mapRef.current.on("moveend", ({ target }) => {
					const { lat, lng: lon } = target.getCenter()
					onViewChange({ center: { lat, lon }, zoom: target.getZoom() })
				})
			}
		}

		if (view?.center && view.zoom) {
			mapRef.current.setView({ lat: view.center.lat, lng: view.center.lon }, view.zoom)
		} else if (markerLocations.length > 0) {
			mapRef.current.fitBounds(markerLocations.map(({ lat, lon }) => [lat, lon]))
		}

		// remove old markers
		markers.current.forEach((marker) => marker.remove())
		// add new markers
		if (mapRef.current != null) {
			markers.current = markerLocations.map(({ lat, lon, id, title, color }) => {
				const marker = new LabelledMarker([lat, lon], title, {
					icon: L.icon({
						iconUrl: icon,
						iconSize: [16.7, 27.3],
						iconAnchor: [8, 27.3],
						shadowUrl: shadow,
						shadowSize: [27.3, 27.3],
						shadowAnchor: [8, 27.3],
						className: color,
					}),
				}).setZIndexOffset(color === MarkerColor.blue ? 0 : 1)
				if (!mapRef.current) return marker
				marker
					.addTo(mapRef.current)
					.on("click", () => onClickMarker && onClickMarker(id))
					.on("mouseover", (e) => setSelectedMarker(e.target))
					.on("mouseout", () => setSelectedMarker(null))
				return marker
			})
		}
	}, [markerLocations, onClickMarker, smallScreen, largeScreenLayer, view])

	useEffect(() => {
		if (!mapRef.current) return
		if (smallScreen) {
			mapRef.current.removeControl(mapRef.current.zoomControl)
			mapRef.current.removeLayer(largeScreenLayer)
			mapRef.current.addLayer(smallScreenLayer)
		} else {
			mapRef.current.addControl(mapRef.current.zoomControl)
			mapRef.current.removeLayer(smallScreenLayer)
			mapRef.current.addLayer(largeScreenLayer)
		}
	}, [smallScreen, largeScreenLayer, smallScreenLayer])

	if (!markerLocations) return null
	return (
		<>
			<StyledMap id="leaflet-map" />
			<Popover
				open={Boolean(selectedMarker?.getLabel())}
				anchorEl={selectedMarker?.getElement()}
				// onClose={() => setSelectedMarker(null)}
			>
				{selectedMarker?.getLabel()}
			</Popover>
		</>
	)
}
