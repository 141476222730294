import { red, orange, green, grey } from "@mui/material/colors"
import { HeadsetMic } from "@mui/icons-material"
import { OpenStatus } from "generated/graphql"

const colors = {
	open: red[500],
	waiting: orange[500],
	closed: green[500],
}

type Props = {
	status?: OpenStatus
}

export default function StatusIcon({ status }: Props) {
	return <HeadsetMic htmlColor={status ? colors[status] : grey[500]} />
}
