/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Typography } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import {
	useCreateServiceRequestMutation,
	ServiceRequestInput,
	ServiceRequestsDocument,
	InstallationServiceRequestsFragmentDoc,
	InstallationServiceRequestsFragment,
	ServiceRequestsQuery,
	Role,
	OpenStatus,
} from "generated/graphql"
import UserInput from "inputs/UserInput"
import BooleanInput from "inputs/BooleanInput"
import InstallationInput from "inputs/InstallationInput"
import ServiceTypeInput from "inputs/ServiceTypeInput"
import StatusTypeInput from "inputs/StatusTypeInput"
import TextAreaInput from "inputs/TextAreaInput"
import DateInput from "inputs/DateInput"
import appendErrorMessage from "tools/appendErrorMessage"
import ImageInput from "../../inputs/ImageInput"
import { useState } from "react"
import DropZone from "../Dropzone"
import { FileResult } from "../../tools/getFile"
import { resizeImage } from "../../tools/imgHelpers"
import { useTranslation } from "react-i18next"

const getInitialServiceRequest = (serviceRequest?: Partial<ServiceRequestInput>) => ({
	installationId: serviceRequest?.installationId ?? null,
	invoice: serviceRequest?.invoice ?? false,
	timestamp: serviceRequest?.timestamp ? serviceRequest?.timestamp : new Date().toISOString(),
	endDate: serviceRequest?.endDate ?? null,
})

type Props = {
	onClose: () => void
	serviceRequest?: Partial<ServiceRequestInput>
	hideInstallationField?: boolean
}

export default function CreateServiceRequest({
	onClose,
	serviceRequest: editServiceRequest,
	hideInstallationField,
}: Props) {
	const { t } = useTranslation(["serviceRequests", "general"])
	const [createServiceRequest, { loading: upsertLoading }] = useCreateServiceRequestMutation({
		update: (cache, { data }) => {
			const serviceRequest = data?.createServiceRequest
			try {
				if (!serviceRequest?.installation?.id) throw new Error("not in cache")
				const id = `Installation:${serviceRequest.installation.id}`
				const oldInstallation = cache.readFragment<InstallationServiceRequestsFragment>({
					id,
					fragment: InstallationServiceRequestsFragmentDoc,
					fragmentName: "InstallationServiceRequests",
				})
				if (!oldInstallation?.serviceRequests) throw new Error("no servicerequests in cache for installation")
				const serviceRequests = [...oldInstallation.serviceRequests, serviceRequest]
				const hasOpenServiceRequests = serviceRequests.some((sr) => sr.status !== OpenStatus.Closed)
				cache.writeFragment({
					id,
					fragment: InstallationServiceRequestsFragmentDoc,
					fragmentName: "InstallationServiceRequests",
					data: {
						...oldInstallation,
						hasOpenServiceRequests,
						serviceRequests,
					},
				})
			} catch (e) {
				// catch if not in cache, ignore
			}
			try {
				const data = cache.readQuery<ServiceRequestsQuery>({
					query: ServiceRequestsDocument,
				})
				if (!data) throw new Error("service requests not in cache")
				cache.writeQuery({
					query: ServiceRequestsDocument,
					data: {
						...data,
						serviceRequests: [...data.serviceRequests, serviceRequest],
					},
				})
			} catch (e) {
				// catch if not in cache, ignore
			}
		},
	})

	const [serviceImages, setServiceImages] = useState<File[]>()
	const message = useMessage()

	const { register, submit, fields } = useForm(getInitialServiceRequest(editServiceRequest), handleSave, [
		"installationId",
	])

	async function handleSave(serviceRequest: ServiceRequestInput) {
		try {
			await createServiceRequest({
				variables: {
					serviceRequest: {
						...serviceRequest,
						images: serviceImages,
						installationId: serviceRequest.installationId || null,
					},
				},
			})
			message.success(t("ServiceRequestSaved"))
			onClose()
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.Saving"), e))
		}
	}

	const handleDrop = async (imgs: FileResult[]) => {
		const resized: File[] = await Promise.all(imgs.map(({ data }) => resizeImage(data)))
		setServiceImages(resized)
	}

	return (
		<TSDialog autoFullScreen open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
			<DropZone accept="image/*" onDrop={handleDrop} multiple>
				<DialogTitle id="form-dialog-title">{t("ServiceRequest")}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t("FillFields")}</DialogContentText>
					<DateInput
						label={t("CreatedAt")}
						{...register("timestamp", {
							required: true,
							datetime: true,
						})}
					/>
					{!hideInstallationField && fields.installationId && <InstallationInput {...register("installationId")} />}
					<ServiceTypeInput {...register("serviceTypeId", { required: true })} />
					<StatusTypeInput {...register("statusTypeId", { required: true })} />
					<UserInput
						label={t("general:tableHeadings.AssignedTo")}
						roles={[Role.Admin, Role.Mechanic]}
						{...register("assignedToId")}
					/>
					<DateInput label={t("EndDate")} clearable {...register("endDate")} />
					<DateInput label={t("DatePlanned")} clearable {...register("plannedVisit")} />
					<Grid item container justifyContent="space-between">
						<Grid item>
							<BooleanInput label={t("general:tableHeadings.ToInvoice")} {...register("invoice")} />
						</Grid>
						<Grid item>
							<ImageInput
								label={t("SelectImage")}
								color="primary"
								onChange={(imgs) => setServiceImages(imgs)}
								multiple
							/>
							{serviceImages?.length && <Typography>{t("general:image", { count: serviceImages.length })}</Typography>}
						</Grid>
					</Grid>
					<TextAreaInput label={t("Description")} {...register("description", { required: true })} />
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary" disabled={upsertLoading}>
						{t("general:Cancel")}
					</Button>
					<Button onClick={submit} color="primary" disabled={upsertLoading}>
						{t("general:Save")}
					</Button>
				</DialogActions>
			</DropZone>
		</TSDialog>
	)
}
