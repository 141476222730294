import { Drawer, Toolbar, List, ListItemIcon, ListItemText, ListItemButton, Divider } from "@mui/material"
import { Home, Warning, FormatListBulleted, Groups, Settings } from "@mui/icons-material"
import { INSTALLATIONS, SERVICE_REQUESTS, HEAT_PUMP_LIST, ORGANIZATION_MANAGEMENT, SETTINGS } from "settings/url"
import { Link, useLocation } from "react-router-dom"
import useSize from "hooks/useSize"
import useHasRole from "hooks/useHasRole"
import { Role, useMeQuery } from "generated/graphql"
import { useTranslation } from "react-i18next"

type Props = {
	open: boolean
	onClose: () => void
}

const DRAWER_WIDTH = "210px"

export default function SideMenu({ open, onClose }: Props) {
	const { t } = useTranslation()
	const location = useLocation()
	const smallScreen = useSize("down", "lg")
	const isAdmin = useHasRole([Role.Admin])
	const isInternal = useHasRole([Role.Admin, Role.Mechanic])
	const { data: groupData } = useMeQuery()
	return (
		<Drawer
			variant={smallScreen ? "temporary" : "permanent"}
			PaperProps={{ elevation: smallScreen ? 1 : 0, sx: { width: DRAWER_WIDTH } }}
			open={open}
			onClose={onClose}
			anchor="left"
			sx={{ width: DRAWER_WIDTH }}
		>
			<Toolbar />
			<List>
				{/* Service app */}
				{isInternal && (
					<>
						<ListItemButton
							component={Link}
							to={`/${INSTALLATIONS}`}
							selected={location.pathname === `/${INSTALLATIONS}`}
						>
							<ListItemIcon>
								<Home />
							</ListItemIcon>
							<ListItemText primary={t("Installations")} />
						</ListItemButton>
						<ListItemButton
							component={Link}
							to={`/${SERVICE_REQUESTS}`}
							selected={location.pathname === `/${SERVICE_REQUESTS}`}
						>
							<ListItemIcon>
								<Warning />
							</ListItemIcon>
							<ListItemText primary={t("ServiceRequests")} />
						</ListItemButton>
					</>
				)}

				{/* Heat pump list */}
				<ListItemButton
					component={Link}
					to={`/${HEAT_PUMP_LIST}`}
					selected={location.pathname === `/${HEAT_PUMP_LIST}`}
				>
					<ListItemIcon>
						<FormatListBulleted />
					</ListItemIcon>
					<ListItemText primary={t("HeatPumps")} />
				</ListItemButton>

				{/* Group settings */}
				{groupData?.me.group?.isOrganization && (
					<ListItemButton
						component={Link}
						to={`/${ORGANIZATION_MANAGEMENT}`}
						selected={location.pathname === `/${ORGANIZATION_MANAGEMENT}`}
					>
						<ListItemIcon>
							<Groups />
						</ListItemIcon>
						<ListItemText primary={t("Organization")} />
					</ListItemButton>
				)}
			</List>

			{isAdmin && (
				<List sx={{ mt: "auto", pb: 0 }}>
					<Divider />
					<ListItemButton component={Link} to={`/${SETTINGS}`} selected={location.pathname === `/${SETTINGS}`}>
						<ListItemIcon>
							<Settings />
						</ListItemIcon>
						<ListItemText primary={t("Settings")} primaryTypographyProps={{ noWrap: true }} />
					</ListItemButton>
				</List>
			)}
		</Drawer>
	)
}
