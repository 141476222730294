/* eslint-disable i18next/no-literal-string */ //Internal only
import { styled } from "@mui/material/styles"
import { CardContent, Typography, Divider, Button, Stack, IconButton, CardActions, Grid } from "@mui/material"
import { useState } from "react"
import StatusIcon from "components/StatusIcon"
import CreateServiceRequestHistory from "components/forms/UpsertServiceRequestHistory"
import {
	ServiceRequestDocument,
	ServiceRequestHistoryInput,
	useDeleteServiceRequestImageMutation,
	useRemoveServiceRequestHistoryMutation,
	useServiceRequestQuery,
} from "generated/graphql"
import CustomCard from "components/CustomCard"
import formatTime from "tools/formatTime"
import RawText from "components/RawText"
import { FormatUserOrGroup as User } from "components/FormatUserOrGroup"
import { Delete } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import Image from "components/Image"
import React from "react"
import { useMessage } from "providers/MessageProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import { useConfirm } from "providers/ConfirmProvider"

const StyledDivider = styled(Divider)(({ theme }) => ({
	marginTop: theme.spacing(1),
}))

const StyledImageGrid = styled(Grid)(() => ({
	width: "100px",
}))

const StyledClickableStack = styled(Stack)(({ theme }) => ({
	"&:hover": {
		cursor: "pointer",
		backgroundColor: theme.palette.background.default,
	},
}))

type Props = {
	sequenceNumber: string
}

export default function HistoryCard({ sequenceNumber }: Props) {
	const { t } = useTranslation(["general", "serviceRequests"])
	const message = useMessage()
	const { data, loading, error } = useServiceRequestQuery({
		variables: { sequenceNumber },
	})

	const serviceRequest = data?.serviceRequest
	const history = serviceRequest?.history ?? []
	const [newHistoryDialogOpen, setNewHistoryDialogOpen] = useState(false)
	const [deleteImage, { loading: deleteImageLoading }] = useDeleteServiceRequestImageMutation()
	const [editServiceRequestHistory, setEditServiceRequestHistory] =
		useState<Partial<ServiceRequestHistoryInput> | null>()

	const confirm = useConfirm()
	const handleOpen = () => setNewHistoryDialogOpen(true)
	const handleClose = () => {
		setNewHistoryDialogOpen(false)
		setEditServiceRequestHistory(null)
	}

	const handleEdit = (id: string) => {
		const item = history.find((h) => h?.id === id)

		setEditServiceRequestHistory({
			id,
			serviceRequestId: serviceRequest?.id ?? undefined,
			statusTypeId: item?.statusType.id ?? undefined,
			description: item?.description ?? "",
			timestamp: item?.timestamp ?? new Date().toISOString(),
			plannedVisit: item?.plannedVisit ?? null,
		})

		setNewHistoryDialogOpen(true)
	}

	const handleDeleteImage = async (imageId: string) => {
		await confirm(t("serviceRequests:Confirms.ImageDelete"))
		try {
			await deleteImage({ variables: { imageId } })
			message.success(t("serviceRequests:ImageDeletedSuccess"))
		} catch (e) {
			message.error(appendErrorMessage(t("serviceRequests:Errors.DeletingImage"), e))
		}
	}

	const [removeHistory, { loading: loadingRemoveHistory }] = useRemoveServiceRequestHistoryMutation({
		refetchQueries: [{ query: ServiceRequestDocument, variables: { sequenceNumber } }],
	})

	const handleRemove = async (id: string) => {
		await confirm("Weet je zeker dat je dit bericht wilt verwijderen?")

		try {
			await removeHistory({ variables: { id } })
			message.success("Bericht verwijderd")
		} catch (e) {
			if (e instanceof Error && e.message.includes("can't remove last history entry of a service request")) {
				message.error("Laatste bericht kan niet worden verwijderd")
				return
			}
			message.error(appendErrorMessage("Er is iets fout gegaan tijdens het verwijderen van het bericht", e))
		}
	}

	return (
		<>
			<CustomCard>
				<CardContent>
					{loading && <Typography>Gegevens ophalen...</Typography>}
					{error && <Typography>Fout bij het ophalen van de gegevens...</Typography>}
					{!error && !loading && history && (
						<>
							<CardActions>
								<Stack width="100%" direction="row" justifyContent="space-between" flexWrap="wrap" paddingBottom={1}>
									<Typography variant="h5" gutterBottom>
										Log
									</Typography>
									<Button onClick={handleOpen} color="primary" variant="contained">
										Toevoegen
									</Button>
								</Stack>
							</CardActions>
							<Stack spacing={2} direction="column">
								{history.map((h, ix) => (
									<StyledClickableStack
										key={h.id}
										direction="column"
										spacing={1}
										onClick={() => h.id && handleEdit(h.id)}
									>
										<Stack direction="row" justifyContent="space-between" flexWrap="wrap">
											<Stack direction="row" spacing={1} alignItems="center" paddingRight={1}>
												<StatusIcon status={h.statusType.status} />
												<Stack>
													<Typography>{h.statusType.name}</Typography>
													{h.plannedVisit != null && (
														<Typography variant="caption">
															Bezoek {t("shortDate", { date: new Date(h.plannedVisit) })}
														</Typography>
													)}
												</Stack>
											</Stack>
											<Stack direction="row" display="flex" justifyContent="center" alignItems="center">
												<Typography variant="caption">
													{formatTime(h.timestamp)} door <User>{h.user}</User>
												</Typography>
												{history.length > 1 && (
													<IconButton
														disabled={loadingRemoveHistory}
														onClick={(e) => {
															e.stopPropagation()
															handleRemove(h.id)
														}}
													>
														<Delete />
													</IconButton>
												)}
											</Stack>
										</Stack>
										<RawText>
											<em>{h.description}</em>
										</RawText>

										<Stack>
											{h.images.length > 0 && (
												<Grid key={h.id} container>
													{h.images?.map(({ id, url }, index) => (
														<React.Fragment key={index}>
															<StyledImageGrid item>
																<Image src={url} />
															</StyledImageGrid>

															<Grid item>
																<IconButton
																	onClick={(e) => {
																		e.stopPropagation()
																		handleDeleteImage(id)
																	}}
																	disabled={deleteImageLoading}
																	size="large"
																>
																	<Delete />
																</IconButton>
															</Grid>
														</React.Fragment>
													))}
													{ix !== history.length - 1 && <StyledDivider />}
												</Grid>
											)}
										</Stack>
									</StyledClickableStack>
								))}
							</Stack>
						</>
					)}
				</CardContent>
			</CustomCard>
			{newHistoryDialogOpen && (
				<CreateServiceRequestHistory
					serviceRequestHistory={
						editServiceRequestHistory ?? {
							serviceRequestId: serviceRequest?.id ?? undefined,
							statusTypeId: serviceRequest?.history[0].statusType.id,
							plannedVisit: serviceRequest?.history[0].plannedVisit,
						}
					}
					onClose={handleClose}
				/>
			)}
		</>
	)
}
