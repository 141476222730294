type GeolocateResult = {
	placeName: string
	street: string
	houseNumber: string | null
	address: string
	province: string | null
	postalCode: string
	country: string
	lat: number
	lon: number
}

async function geolocate(query: string | null): Promise<GeolocateResult | null> {
	if (query === null) return null
	const resp = await fetch(`https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1&limit=1`)
	if (resp.status !== 200) return null

	const data = await resp.json()
	if (!data.length) return null

	const loc = data[0]
	if (!loc.address) return null

	const result: GeolocateResult = {
		placeName: loc.address.village ?? loc.address.town ?? loc.address.city ?? loc.address.municipality,
		street: loc.address.road,
		houseNumber: loc.address.house_number ?? loc.address.house_name ?? null,
		address: `${loc.address.road} ${loc.address.house_number ?? ""}`,
		province: loc.address.state ?? null,
		postalCode: loc.address.postcode,
		country: loc.address.country,
		lat: parseFloat(loc.lat),
		lon: parseFloat(loc.lon),
	}

	// check certain fields exist
	if (!result.street || !result.placeName || !result.postalCode || !result.country || !result.lat || !result.lon)
		return null

	return result
}

export default geolocate
