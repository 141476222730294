/* eslint-disable i18next/no-literal-string */ //Internal only
import { useState } from "react"
import { styled } from "@mui/material/styles"
import { Typography, CardContent, Button, Grid, Divider, TextField, CardActions, IconButton } from "@mui/material"
import { useMessage } from "providers/MessageProvider"
import Image from "components/Image"
import {
	NoteInput,
	useUpsertNoteToInstallationMutation,
	useInstallationQuery,
	useDeleteNoteFromInstallationMutation,
} from "generated/graphql"
import { Delete } from "@mui/icons-material"
import { Skeleton } from "@mui/material"
import ImageInput from "inputs/ImageInput"
import CustomCard from "components/CustomCard"
import DropZone from "components/Dropzone"
import { FileResult } from "tools/getFile"
import { resizeImage } from "tools/imgHelpers"
import appendErrorMessage from "tools/appendErrorMessage"
import { FormatUserOrGroup as User } from "components/FormatUserOrGroup"
import { useTranslation } from "react-i18next"
import { useConfirm } from "../../../providers/ConfirmProvider"

const PREFIX = "NotesCard"

const classes = {
	textfield: `${PREFIX}-textfield`,
	selectable: `${PREFIX}-selectable`,
	divider: `${PREFIX}-divider`,
	images: `${PREFIX}-images`,
	image: `${PREFIX}-image`,
	message: `${PREFIX}-message`,
	skeleton: `${PREFIX}-skeleton`,
}

const StyledCustomCard = styled(CustomCard)(({ theme }) => ({
	[`& .${classes.textfield}`]: {
		whiteSpace: "pre-wrap",
	},

	[`& .${classes.selectable}`]: {
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},

	[`& .${classes.divider}`]: {
		marginTop: theme.spacing(2),
	},

	[`& .${classes.images}`]: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},

	[`& .${classes.image}`]: {
		width: `calc(300px + 2 * ${theme.spacing(1)}`,
	},

	[`& .${classes.message}`]: {
		"& button": {
			visibility: "hidden",
		},
		"& :hover": {
			"& button": {
				visibility: "visible",
			},
		},
	},

	[`& .${classes.skeleton}`]: {
		marginTop: theme.spacing(2),
	},
}))

const initialNote = (installationId: string) =>
	({
		installationId,
		message: "",
		images: null,
	} as NoteInput)

type Props = {
	installationId: string
}

export default function NotesCard({ installationId }: Props) {
	const { t } = useTranslation(["serviceRequests", "general"])
	const {
		data,
		loading: iLoading,
		error: iError,
	} = useInstallationQuery({
		variables: { installationId },
	})

	const installation = data?.installation
	const confirm = useConfirm()

	const [upsertNote, { loading }] = useUpsertNoteToInstallationMutation()
	const [deleteNote, { loading: deleteLoading }] = useDeleteNoteFromInstallationMutation()
	const message = useMessage()
	const [note, setNote] = useState<NoteInput>(initialNote(installationId))

	const handleUpsertNote = async () => {
		if (!note || !installation) return
		if (!note.message && note.images && note.images?.length > 0) {
			message.info("Voeg een notitie toe bij de foto.")
			return
		}
		try {
			await upsertNote({ variables: { note } })
			setNote(initialNote(installationId))
		} catch (e) {
			message.error(appendErrorMessage("Er ging iets fout bij het opslaan", e))
		}
	}

	const handleImage = (imgs: File[]) => {
		setNote({ ...note, images: imgs })
	}

	const handleDeleteNote = (noteId: string) => async () => {
		await confirm(t("serviceRequests:Confirms.NoteDelete"))
		try {
			await deleteNote({ variables: { installationId, noteId } })
		} catch (e) {
			message.error(appendErrorMessage("Er ging iets fout bij het verwijderen van de notitie", e))
		}
	}

	const notes = installation?.notes ?? []

	const handleDrop = async (imgs: FileResult[]) => {
		const resized: File[] = await Promise.all(imgs.map(({ data }) => resizeImage(data)))
		setNote({ ...note, images: resized })
	}

	return (
		<StyledCustomCard>
			<DropZone accept="image/*" onDrop={handleDrop} multiple>
				<CardContent>
					<Typography variant="h5">Notities</Typography>
					{iLoading && (
						<Grid container direction="column" spacing={2}>
							<Grid item container alignItems="flex-end" direction="column">
								<Grid item>
									<Skeleton width={200} />
								</Grid>
							</Grid>
							<Grid item>
								<Skeleton />
							</Grid>
							<Divider className={classes.divider} />
							<Grid item container alignItems="flex-end" direction="column" className={classes.skeleton}>
								<Grid item>
									<Skeleton width={200} />
								</Grid>
							</Grid>
							<Grid item>
								<Skeleton />
							</Grid>
						</Grid>
					)}
					{iError && <Typography>Fout bij het ophalen...</Typography>}
					{!iLoading && !iError && installation && (
						<>
							<Grid container spacing={5} direction="column">
								<Grid item container spacing={2} direction="column">
									{notes?.length ? (
										<>
											{notes.map(({ id, message, timestamp, user, images }, ix) => (
												<Grid item key={id} className={classes.message}>
													<Typography variant="caption" align="right" display="block" gutterBottom>
														{user ? <User>{user}</User> : "Onbekende gebruiker"} op{" "}
														{t("general:date", { date: new Date(timestamp) })}
													</Typography>
													<Grid container justifyContent="space-between">
														<Grid item>
															<Grid container spacing={2} direction="row" className={classes.images}>
																{images?.map(({ id, url }) => (
																	<Grid item className={classes.image} key={id}>
																		<Image src={url} />
																	</Grid>
																))}
															</Grid>
															<Typography gutterBottom className={classes.textfield}>
																{message}
															</Typography>
														</Grid>
														<Grid item>
															<IconButton onClick={handleDeleteNote(id)} disabled={deleteLoading} size="large">
																<Delete />
															</IconButton>
														</Grid>
													</Grid>
													{ix !== notes.length - 1 && <Divider className={classes.divider} />}
												</Grid>
											))}
										</>
									) : (
										<Grid item>
											<Typography>Er zijn nog geen notities</Typography>
										</Grid>
									)}
								</Grid>
							</Grid>
						</>
					)}
				</CardContent>
				<CardActions>
					<Grid container direction="column" spacing={1}>
						<Grid item container direction="row" alignItems="center">
							<Grid item>
								<ImageInput
									label="Foto selecteren"
									color="primary"
									disabled={loading || iLoading || Boolean(iError)}
									onChange={handleImage}
									multiple
								/>
							</Grid>
							{note?.images && (
								<Grid item>
									<Typography>
										{note.images.length > 0 ? (
											<>
												{note.images.length} Afbeelding
												{note.images.length > 1 ? "en" : ""}
											</>
										) : (
											""
										)}
									</Typography>
								</Grid>
							)}
						</Grid>
						<Grid item>
							<TextField
								onChange={(e) => setNote({ ...note, message: e.target.value })}
								value={note.message ?? ""}
								label="Voeg een nieuwe notitie toe."
								required
								fullWidth
								multiline
								variant="outlined"
								rows={3}
								disabled={loading || iLoading || Boolean(iError)}
							/>
						</Grid>
						<Grid item container justifyContent="flex-end">
							<Grid item>
								<Button
									onClick={handleUpsertNote}
									color="primary"
									variant="contained"
									disabled={loading || iLoading || (!note.message && !note?.images?.length) || Boolean(iError)}
								>
									Toevoegen
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</CardActions>
			</DropZone>
		</StyledCustomCard>
	)
}
