/* eslint-disable i18next/no-literal-string */ //Internal only
import { useState, useRef } from "react"
import { styled } from "@mui/material/styles"
import Page from "components/Page"
import Map, { Marker } from "components/Map"
import AddFab from "components/AddFab"
import CreateIntallation from "components/forms/UpsertInstallation"
import { Typography, MenuItem, IconButton, Divider } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { INSTALLATION } from "settings/url"
import Fuse from "fuse.js"
import TSMenu from "components/TSMenu"
import formatInstallation from "tools/formatInstallation"
import { useInstallationPageFilterQuery, useInstallationsQuery, useMapStateQuery } from "generated/graphql"
import useSize from "hooks/useSize"
import setMapState from "localstate/mapState"
import DebouncedMenuInput from "components/DebouncedMenuInput"
import { useTranslation } from "react-i18next"
import installationPageFilter from "../../localstate/installationsListPageFilter"
import HistoryIcon from "@mui/icons-material/History"
import DeleteIcon from "@mui/icons-material/Delete"
import ServiceAppReadOnlyBanner from "components/ServiceAppReadOnlyBanner"

const StyledMessage = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(2),
}))

const StyledMenuItem = styled(MenuItem)(() => ({
	color: "#8435d3",
	fontStyle: "italic",
}))

const StyledIconButton = styled(IconButton)(() => ({
	marginLeft: "auto",
	padding: 0,
}))

const StyledDiv = styled("div")(({ theme }) => ({
	marginRight: theme.spacing(2),
}))

const options = {
	includeScore: true,
	threshold: 0.4,
	keys: ["shortAddress", "resident.name", "installer.organization", "installer.name", "installer.phoneNumber"],
}

export default function InstallationPage() {
	const { t } = useTranslation()
	const { data, loading, error } = useInstallationsQuery()
	const { data: mapStateData } = useMapStateQuery()
	const [addDialogOpen, setAddDialogOpen] = useState(false)
	const navigate = useNavigate()
	const [search, setSearch] = useState("")
	const [searchOpen, setSearchOpen] = useState(false)
	const searchEl = useRef<HTMLDivElement | null>(null)
	const smallScreen = useSize("down", "md")

	const view = mapStateData?.mapState
	const fuse = new Fuse(data?.installations ?? [], options)
	const installations = search ? fuse.search(search).map(({ item }) => item) : data?.installations ?? []

	const { data: installationSearchData } = useInstallationPageFilterQuery()

	const handleAdd = () => setAddDialogOpen(true)
	const handleClose = () => setAddDialogOpen(false)
	const handleOpen = (id: string) => {
		const desiredInstallation = installations.find((installation) => installation.id === id)
		const currentSearchArray = installationSearchData?.installationPageFilter?.searchArray || []
		const searchObject = { id: id, value: formatInstallation(desiredInstallation) || "" }
		const isIdAlreadyExists = currentSearchArray.some((obj) => obj.id === id)
		const filteredSearchArray = isIdAlreadyExists ? currentSearchArray : [...currentSearchArray, searchObject]
		const newFilteredSearchArray = [...filteredSearchArray].reverse().slice(0, 3)

		if (search && desiredInstallation) {
			installationPageFilter({
				searchText: search,
				searchArray: newFilteredSearchArray,
			})
		}

		navigate(`/${INSTALLATION}/${id}`)
	}

	const markers: Marker[] = installations?.map((installation) => ({
		...(installation?.location ?? { lat: 0, lon: 0 }),
		id: installation?.id ?? "",
		title: formatInstallation(installation) ?? "",
		color: installation?.hasOpenIBS
			? "yellow"
			: installation?.hasOpenServiceRequests
				? "red"
				: installation.installerAddress
					? "orange"
					: "blue",
	}))

	const updateSearch = (searchText: string) => {
		setSearch(searchText)
	}

	const deleteHistorySuggest = (id: string) => {
		const searchArray = installationSearchData?.installationPageFilter.searchArray
		if (searchArray) {
			const updatedSearchArray = searchArray.filter((item) => item.id !== id)
			installationPageFilter({
				searchText: installationSearchData.installationPageFilter.searchText,
				searchArray: updatedSearchArray,
			})
		}
	}

	return (
		<>
			<Page
				title={t("Installations")}
				hideTitle={smallScreen}
				noPadding
				middle={
					<div ref={searchEl}>
						<DebouncedMenuInput
							initialValue={installationSearchData?.installationPageFilter.searchText || ""}
							onChange={updateSearch}
							clearable
							clearOnBlur
							onFocus={() => setTimeout(() => setSearchOpen(true), 250)}
							onBlur={() => setSearchOpen(false)}
						/>
					</div>
				}
			>
				<ServiceAppReadOnlyBanner noMargin />
				{loading && <StyledMessage>Installaties ophalen...</StyledMessage>}
				{error && <StyledMessage>Fout bij het ophalen van de installaties...</StyledMessage>}
				{!error && !loading && (
					<>
						<Map
							markers={markers}
							onClickMarker={handleOpen}
							view={search ? undefined : view} // only set initial view when not searching
							onViewChange={setMapState}
						/>
						<AddFab onClick={handleAdd}>Nieuwe installatie</AddFab>
						{addDialogOpen && <CreateIntallation onClose={handleClose} />}
					</>
				)}

				<TSMenu anchorEl={searchEl.current} open={searchOpen}>
					{installationSearchData &&
						installationSearchData.installationPageFilter.searchArray.map((el) => (
							<StyledMenuItem onMouseDown={() => handleOpen(el.id)} key={el.id}>
								<HistoryIcon sx={{ mr: 1 }} />
								<StyledDiv>{el.value}</StyledDiv>

								<StyledIconButton
									onMouseDown={(e) => {
										deleteHistorySuggest(el.id)
										e.stopPropagation()
										e.preventDefault()
									}}
								>
									<DeleteIcon color="inherit" />
								</StyledIconButton>
							</StyledMenuItem>
						))}
					{Boolean(search) && (
						<>
							{installationSearchData && installationSearchData.installationPageFilter.searchArray.length > 0 && (
								<Divider />
							)}

							{installations.map((installation) => (
								<MenuItem
									onMouseDown={(e) => {
										e.preventDefault()
										e.stopPropagation()
										installation?.id && handleOpen(installation.id)
									}}
									key={installation?.id}
								>
									{formatInstallation(installation)}
								</MenuItem>
							))}
						</>
					)}
				</TSMenu>
			</Page>
		</>
	)
}
