/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import CreateCoolingModule from "components/forms/UpsertCoolingModule"
import SearchableSelect from "components/SearchableSelect"
import { CoolingModuleFieldsFragment, useCoolingModulesQuery } from "generated/graphql"
import { useState } from "react"

type Props = {
	value?: string | null
	onChange: (value: string | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function CoolingModuleInput({
	value,
	onChange,
	label = "Koelmodule",
	required,
	error,
	helperText,
}: Props) {
	const { data, loading, error: qError } = useCoolingModulesQuery()

	const [closeHandler, setCloseHandler] = useState<
		(() => (coolingModule: CoolingModuleFieldsFragment | null) => void) | null
	>()

	const handleChange = (e: { target: { value: string | number | null } }) => {
		onChange(typeof e.target.value === "string" ? e.target.value : null)
	}

	const handleNew = () => {
		setCloseHandler(() => (coolingModule: CoolingModuleFieldsFragment | null) => {
			setCloseHandler(null)
			onChange(coolingModule?.id ?? null)
		})
	}

	if (qError) {
		return <Typography color="error">Er ging iets fout bij het ophalen van de koelmodules</Typography>
	}

	return (
		<>
			<SearchableSelect
				value={value}
				onChange={handleChange}
				options={data?.coolingModules ?? []}
				getOptionLabel={({ name }) => name}
				disabled={loading || Boolean(qError)}
				label={label}
				required={required}
				error={error}
				helperText={helperText}
				onCreateNew={handleNew}
			/>
			{closeHandler && <CreateCoolingModule onClose={closeHandler} />}
		</>
	)
}
