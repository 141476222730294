import { useMemo, useRef, useState } from "react"
import { styled } from "@mui/material/styles"
import Page from "components/Page"
import { Typography, Grid, FormControlLabel, Switch, Checkbox, Stack, Button, Menu, Box, MenuItem } from "@mui/material"
import Table, { Label } from "components/Table"
import StatusIcon from "components/StatusIcon"
import SeverityIcon from "components/SeverityIcon"
import Fuse from "fuse.js"
import severity from "settings/severity"
import status from "settings/status"
import CreateServiceRequest from "components/forms/CreateServiceRequest"
import AddFab from "components/AddFab"
import { differenceInDays, differenceInSeconds } from "date-fns"
import { useNavigate } from "react-router-dom"
import { SERVICE_REQUEST } from "settings/url"
import formatInstallation from "tools/formatInstallation"
import {
	OpenStatus,
	useServiceRequestsQuery,
	ServiceRequestsQueryFieldsFragment,
	useServiceRequestFilterQuery,
} from "generated/graphql"
import useSize from "hooks/useSize"
import serviceRequestFilter, { emptyServiceRequestFilter } from "localstate/serviceRequestFilter"
import formatInitials from "tools/formatInitials"
import DebouncedMenuInput from "components/DebouncedMenuInput"
import { FormatUserOrGroup as User } from "components/FormatUserOrGroup"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import React from "react"
import FilterListIcon from "@mui/icons-material/FilterList"
import { CSVLink } from "react-csv"
import SaveAltIcon from "@mui/icons-material/SaveAlt"
import ServiceAppReadOnlyBanner from "components/ServiceAppReadOnlyBanner"

const PREFIX = "index"

const classes = {
	switchWrapper: `${PREFIX}-switchWrapper`,
	switch: `${PREFIX}-switch`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
	[`& .${classes.switchWrapper}`]: {
		marginBottom: theme.spacing(2),
	},

	[`& .${classes.switch}`]: {
		padding: theme.spacing(3),
	},
}))

const StyledButton = styled(Button)(({ theme }) => ({
	display: "flex",
	alignItems: "center",

	[theme.breakpoints.down("sm")]: {
		"& span": {
			display: "none",
		},
	},
}))

const StyledDiv = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
}))

type Fields = ServiceRequestsQueryFieldsFragment & {
	statusTranslated?: string | null
	statusMessage?: string | null
	severityTranslated?: string | null
}

const options = {
	includeScore: true,
	threshold: 0.4,
	keys: [
		"serviceType.name",
		"severityTranslated",
		"statusTranslated",
		"statusType.name",
		"description",
		"installation.shortAddress",
		"installation.resident.name",
		"installation.installer.name",
		"installation.installer.organization",
		"lastHistory.title",
		"statusMessage",
		"assignedTo.name",
		"history.description",
	],
}

function getLabels(t: TFunction<"general">): Label<Fields>[] {
	return [
		{
			key: "sequenceNumber",
			name: "Volgnummer",
			sortable: true,
		},
		{
			key: "installation",
			name: "Installatie",
			sortable: true,
			tooltip: true,
			resolve: ({ installation }) => installation && formatInstallation(installation),
		},
		{
			key: "serviceType",
			name: "Type verzoek",
			sortable: true,
			tooltip: true,
			sort: ({ serviceType }) => serviceType.name ?? "",
			resolve: ({ serviceType }) => (
				<Grid container alignItems="center" spacing={1} wrap="nowrap">
					<Grid item>
						<SeverityIcon severity={serviceType.severity} />
					</Grid>
					<Grid item xs zeroMinWidth>
						<div
							style={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{serviceType.name}
						</div>
					</Grid>
				</Grid>
			),
		},
		{
			key: "statusType",
			name: "Laatste status",
			sortable: true,
			tooltip: true,
			sort: ({ history }) => history?.[0]?.statusType.name ?? "",
			resolve: ({ history }) => {
				const newestItem = history[0]
				return (
					<Grid container alignItems="center" spacing={1} wrap="nowrap">
						<Grid item>
							<StatusIcon status={newestItem?.statusType.status} />
						</Grid>
						<Grid item xs zeroMinWidth>
							<div
								style={{
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
								}}
							>
								{newestItem?.statusType.name}
							</div>
						</Grid>
					</Grid>
				)
			},
		},
		{
			key: "description",
			name: "Omschrijving",
			tooltip: ({ history }: Fields) =>
				history.map((item) => (
					<Typography variant="inherit" key={item.id}>
						{formatInitials(item.user.name)} : {t("shortDate", { date: new Date(item.timestamp) })} | {item.description}
					</Typography>
				)),
			resolve: ({ history }: Fields) => {
				const item = history[0]
				return (
					<>
						{formatInitials(item.user.name)} : {t("shortDate", { date: new Date(item.timestamp) })} | {item.description}
					</>
				)
			},
		},
		{
			key: "placeName",
			name: "Plaatsnaam",
			sortable: true,
			resolve: ({ installation }) => installation?.city,
		},
		{
			key: "openedOn",
			name: "Aangevraagd op",
			width: 170,
			sortable: true,
			sort: ({ history }) => {
				const oldestItem = history[history.length - 1]
				return oldestItem ? new Date(oldestItem?.timestamp).getTime() : 0
			},
			resolve: ({ history }) => {
				const oldestItem = history[history.length - 1]
				return t("date", { date: new Date(oldestItem?.timestamp) })
			},
		},
		{
			key: "endDate",
			name: "Gew. einddatum",
			sortable: true,
			sort: ({ endDate }: Fields) => (endDate ? new Date(endDate).getTime() : 0),
			resolve: ({ endDate }: Fields) => (endDate != null ? t("date", { date: new Date(endDate) }) : ""),
		},
		{
			key: "plannedVisit",
			name: "Gepland bezoek",
			sortable: true,
			sort: ({ history }: Fields) => {
				const newestItem = history[0]
				return newestItem.plannedVisit ? new Date(newestItem.plannedVisit).getTime() : 0
			},
			resolve: ({ history }: Fields) => {
				const newestItem = history[0]
				return newestItem.plannedVisit != null ? t("date", { date: new Date(newestItem.plannedVisit) }) : ""
			},
		},
		{
			key: "daysOpen",
			name: "Dagen open",
			sortable: true,
			hide: "md",
			sort: ({ history }) => {
				if (!history?.length) return 0
				const oldestItem = history[history.length - 1]
				const newestItem = history[0]
				const lastTimestamp =
					newestItem?.statusType.status !== OpenStatus.Closed ? new Date() : new Date(newestItem.timestamp)
				return differenceInSeconds(lastTimestamp, new Date(oldestItem?.timestamp))
			},
			resolve: ({ history }) => {
				const oldestItem = history[history.length - 1]
				const newestItem = history[0]
				const lastTimestamp =
					newestItem?.statusType.status !== OpenStatus.Closed ? new Date() : new Date(newestItem.timestamp)
				return differenceInDays(lastTimestamp, new Date(oldestItem?.timestamp)) + 1
			},
		},
		{
			key: "assignedTo",
			name: "Toegewezen aan",
			width: 200,
			sortable: true,
			resolve: ({ assignedTo }) => (assignedTo ? <User>{assignedTo}</User> : ""),
		},
		{
			key: "invoice",
			name: "Factureren",
			sortable: true,
			resolve: ({ invoice }) => (invoice ? "Ja" : "Nee"),
		},
	]
}

interface tableHeaderProps {
	label: string
	key: string
}

interface tableDataProps {
	sequenceNumber: string
	address: string
	resident: string
	type: string
	dateEnd: string
	status: string
	description: string
	installer: string
	assigned: string
	invoices: string
	created: string
}

export default function ServiceRequestsPage() {
	const toolBarRef = useRef<HTMLDivElement | null>(null)
	const isMobile = useSize("down", "sm")
	const { t, i18n } = useTranslation(["general", "serviceRequests"])
	const labels = useMemo(() => getLabels(t), [i18n.language])
	const [dialogIsOpen, setDialogIsOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const filterMenuOpen = Boolean(anchorEl)
	const navigate = useNavigate()
	const smallScreen = useSize("down", "md")
	const { data: serviceRequestFilterData } = useServiceRequestFilterQuery()
	const filter = serviceRequestFilterData?.serviceRequestFilter ?? emptyServiceRequestFilter

	const { data, loading, error } = useServiceRequestsQuery({
		variables: {
			statuses: filter.closedRequests ? null : [OpenStatus.Open, OpenStatus.Waiting],
		},
	})

	// add translated fields for fuse searching
	const serviceRequests =
		data?.serviceRequests?.map((sr) => {
			const newestItem = sr.history[0]
			const sev = sr.serviceType?.severity
			return {
				...sr,
				statusTranslated: newestItem ? status[newestItem.statusType?.status] : null,
				statusMessage: newestItem?.statusType?.name,
				severityTranslated: sev ? severity[sev] : null,
			} as Fields
		}) ?? []

	const fuse = new Fuse(serviceRequests, options)

	const filteredServiceRequests = filter.searchText
		? fuse.search(filter.searchText).map(({ item }) => item)
		: serviceRequests ?? []

	const updateSearch = (searchText: string) => {
		serviceRequestFilter({
			...emptyServiceRequestFilter,
			...serviceRequestFilterData?.serviceRequestFilter,
			searchText: searchText,
		})
	}
	const handleClose = () => setDialogIsOpen(false)
	const handleNew = () => setDialogIsOpen(true)
	const handleOpen = (id: string) => {
		const number = serviceRequests.find((sr) => sr.id === id)?.sequenceNumber
		navigate(`/${SERVICE_REQUEST}/${number}`)
	}
	const handleSort = (key: string, reverse: boolean) => {
		serviceRequestFilter({
			...emptyServiceRequestFilter,
			...serviceRequestFilterData?.serviceRequestFilter,
			sortColumn: key,
			sortIsAscending: reverse,
		})
	}
	const handleChangeShownFields = (key: string) => {
		const updatedFilter = [...filter.hiddenServiceRequestFields]
		const ix = updatedFilter.indexOf(key)
		if (ix < 0) {
			updatedFilter.push(key)
		} else {
			updatedFilter.splice(ix, 1)
		}
		serviceRequestFilter({
			...filter,
			hiddenServiceRequestFields: updatedFilter,
		})
	}
	const handleOpenFilterMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
	const handleCloseFilterMenu = () => setAnchorEl(null)
	const [tableData, setTableData] = useState<tableDataProps[]>([])
	const [tableHeaders, setTableHeaders] = useState<tableHeaderProps[]>([])
	const pageRef = useRef<HTMLElement>()

	const bannerHeight =
		(pageRef?.current && Array.from(pageRef.current.children).filter((el) => el.id === "banner")[0]?.clientHeight) ??
		0 ??
		0

	const handleUseRef = (data: HTMLElement) => {
		pageRef.current = data
	}

	const dateFormat = (date: string | null | undefined): string => {
		if (!date) {
			return ""
		}

		return t("date", { date: new Date(date) })
	}
	const exportCsv = () => {
		const dataArray: tableDataProps[] = []

		filteredServiceRequests?.map((el) => {
			dataArray.push({
				sequenceNumber: el.sequenceNumber ?? "",
				address: el.installation?.shortAddress ?? "",
				resident: el.installation?.resident?.name ?? "",
				type: el.serviceType?.name ?? "",
				dateEnd: dateFormat(el.endDate) ?? "",
				status: el.statusMessage ?? "",
				installer: el.installation?.installer?.organization ?? "",
				assigned: el.history?.[0].user.name ?? "",
				invoices: el.invoice ? t("Yes") : t("No"),
				created: dateFormat(el.createdAt) ?? "",
				description: el.history?.[0]
					? `${el.history[0].user.name} ${t("at")} ${dateFormat(el.history[0].timestamp)}: ${
							el.history[0]?.description?.replace(/(\r\n|\n|\r)/gm, " ") || "" // Removed linebreaks from description
						}`
					: "",
			})
		})

		setTableHeaders([
			{ label: t("tableHeadings.SequenceNumber"), key: "sequenceNumber" },
			{ label: t("tableHeadings.Address"), key: "address" },
			{ label: t("Resident"), key: "resident" },
			{ label: t("tableHeadings.Type"), key: "type" },
			{ label: t("tableHeadings.EndDate"), key: "dateEnd" },
			{ label: t("Status"), key: "status" },
			{ label: t("tableHeadings.Installer"), key: "installer" },
			{ label: t("tableHeadings.AssignedTo"), key: "assigned" },
			{ label: `${t("tableHeadings.ToInvoice")}?`, key: "invoices" },
			{ label: t("tableHeadings.Created"), key: "created" },
			{ label: t("serviceRequests:Description"), key: "description" },
		])
		setTableData(dataArray)
	}

	return (
		<Root>
			<Page
				handleUseRef={handleUseRef}
				title={t("ServiceRequests")}
				hideTitle={smallScreen}
				middle={
					<DebouncedMenuInput
						initialValue={serviceRequestFilterData?.serviceRequestFilter.searchText}
						onChange={updateSearch}
						clearable
					/>
				}
				noPadding
			>
				<ServiceAppReadOnlyBanner noMargin />
				<Stack direction="row" justifyContent="space-between" ref={toolBarRef}>
					<StyledDiv>
						<StyledButton onClick={handleOpenFilterMenu}>
							<FilterListIcon sx={{ marginRight: "5px" }} />
							{!isMobile && t("ShowFields")}
						</StyledButton>
					</StyledDiv>

					<Menu open={filterMenuOpen} onClose={handleCloseFilterMenu} anchorEl={anchorEl}>
						{labels.map(({ name, key }, ix) => (
							<MenuItem key={`filter-menu-row-${ix}`}>
								<Stack direction="row" onClick={() => handleChangeShownFields(key)} alignItems="center">
									<Checkbox checked={!filter.hiddenServiceRequestFields.some((field) => field === labels[ix].key)} />
									<Typography>{name}</Typography>
								</Stack>
							</MenuItem>
						))}
					</Menu>

					<StyledDiv>
						<CSVLink
							filename="serviceRequest_exported.csv"
							data={tableData}
							headers={tableHeaders}
							separator=";"
							onClick={exportCsv}
						>
							<StyledButton>
								<SaveAltIcon sx={{ mr: 1 }} /> <span>{t("ExportCSV")}</span>
							</StyledButton>
						</CSVLink>
					</StyledDiv>

					<Box className={classes.switch}>
						<FormControlLabel
							control={
								<Switch
									checked={filter.closedRequests}
									onChange={(e) => {
										serviceRequestFilter({
											...filter,
											closedRequests: e.target.checked,
										})
									}}
									size="small"
								/>
							}
							labelPlacement="start"
							label={t("serviceRequests:ClosedRequests")}
						/>
					</Box>
				</Stack>
				{loading && <Typography>{t("serviceRequests:FetchingServiceRequest")}...</Typography>}
				{error && <Typography>{t("serviceRequests:Errors.FetchingRequestTypes")}...</Typography>}
				{!error && !loading && (
					<Table
						defaultItemsPerPage={25}
						search={filter.searchText}
						offsetHeight={bannerHeight}
						toolBarRef={toolBarRef}
						resizable
						columnWidths={filter.colWidths}
						onChangeColumnWidths={(colWidths) =>
							serviceRequestFilter({
								...filter,
								colWidths: colWidths,
							})
						}
						smallTable="md"
						rows={filteredServiceRequests}
						labels={labels}
						loading={loading}
						fullHeight
						fullWidth
						onClickRow={handleOpen}
						size="small"
						onSort={handleSort}
						defaultSortCol={serviceRequestFilterData?.serviceRequestFilter.sortColumn ?? "openedOn"}
						startSortReverse={serviceRequestFilterData?.serviceRequestFilter.sortIsAscending}
						hiddenRows={filter.hiddenServiceRequestFields}
					/>
				)}
			</Page>

			{dialogIsOpen && <CreateServiceRequest onClose={handleClose} />}

			<AddFab offset={8} positionLabel="left-start" onClick={handleNew}>
				{t("serviceRequests:NewServiceRequest")}
			</AddFab>
		</Root>
	)
}
