/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMemo } from "react"
import { Typography, CardContent, Grid } from "@mui/material"
import LineChart from "components/plots/LineChart"
import { Log, useInstallationLogsQuery } from "generated/graphql"
import { subWeeks } from "date-fns"
import CustomCard from "components/CustomCard"
import { Line } from "components/plots/line"

type Field = {
	key: string
	name: string
	defaultOn?: boolean
	get: ((log: Log, ix: number, logs: Log[]) => number) | keyof Log
}

const FIELDS: Field[] = [
	{
		key: "outsideTemp",
		name: "Buitentemperatuur",
		defaultOn: true,
		get: "outsideTemp",
	},
	{
		key: "brineAvgTemp",
		name: "Bron gemiddeld",
		defaultOn: true,
		get: "brineAvgTemp",
	},
]

interface Installation {
	logs: Log[]
}

function parseLines(installation?: Installation | null): Line[] {
	if (!installation?.logs?.length) return []
	return FIELDS.map((field) => {
		const lineData = installation?.logs?.map((log, ix, logs) => {
			if (typeof field.get === "function") return field.get(log, ix, logs)
			const value = log[field.get]
			switch (typeof value) {
				case "number":
					return value
				case "string":
					return parseFloat(value)
				default:
					return NaN
			}
		})
		const timeData = installation?.logs?.map((log) => new Date(log.timestamp))
		return {
			field: field.key,
			label: `${field.name}`,
			id: `${field.key}`,
			y: lineData,
			x: timeData,
			active: true,
		} as Line
	})
}

const until = new Date().toISOString()
const from = subWeeks(new Date(), 1).toISOString()

type Props = {
	installationId: string
}

function InstallationPage({ installationId }: Props) {
	const { data } = useInstallationLogsQuery({
		variables: { installationId, from, until },
	})

	const lines = useMemo(() => parseLines(data?.installation), [data])

	if (!lines?.length) return null

	return (
		<Grid item xs={12}>
			<CustomCard>
				<CardContent>
					<Typography variant="h5" align="center">
						Afgelopen week
					</Typography>
					<LineChart lines={lines} xlabel="datum" ylabel="°C" />
				</CardContent>
			</CustomCard>
		</Grid>
	)
}

export default InstallationPage
