/* eslint-disable i18next/no-literal-string */ //Internal only
import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import {
	Typography,
	CardContent,
	Grid,
	Divider,
	Button,
	CardActions,
	Stack,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material"
import CreateServiceRequest from "components/forms/CreateServiceRequest"
import StatusIcon from "components/StatusIcon"
import SeverityIcon from "components/SeverityIcon"
import { DateRange, Today, Event, EventAvailable, PrecisionManufacturing, ExpandMore } from "@mui/icons-material"
import { differenceInDays } from "date-fns"
import { useNavigate } from "react-router-dom"
import { INSTALLATION, SERVICE_REQUEST } from "settings/url"
import { OpenStatus, useInstallationQuery } from "generated/graphql"
import { Skeleton } from "@mui/material"
import CustomCard from "components/CustomCard"
import formatTime from "tools/formatTime"
import RawText from "components/RawText"
import useSize from "hooks/useSize"
import { useTranslation } from "react-i18next"
import Image from "../../../components/Image"

const StyledDivider = styled(Divider)(({ theme }) => ({
	marginTop: theme.spacing(2),
	marginBottom: theme.spacing(2),
}))

const StyledClickableStack = styled(Stack)(({ theme }) => ({
	"&:hover": {
		cursor: "pointer",
		backgroundColor: theme.palette.background.default,
	},
}))

const StyledImageGrid = styled(Grid)(() => ({
	width: `50px`,
	marginRight: `10px`,
}))

type Props = {
	installationId: string
}

export default function ServiceRequestsCard({ installationId }: Props) {
	const { t } = useTranslation()
	const [serviceRequestOpen, setServiceRequestOpen] = useState(false)
	const navigate = useNavigate()
	const smallScreen = useSize("down", "md")

	const { data, loading, error } = useInstallationQuery({
		variables: { installationId },
	})

	const installation = data?.installation

	const handleOpen = () => setServiceRequestOpen(true)
	const handleClose = () => setServiceRequestOpen(false)
	const handleOpenServiceRequest = (id: string) =>
		navigate(`/${SERVICE_REQUEST}/${id}?ref=${INSTALLATION}/${installationId}`)

	return (
		<>
			<CustomCard>
				<CardActions>
					<Stack width="100%" direction="row" justifyContent="space-between" flexWrap="wrap" padding={1}>
						<Typography variant="h5" gutterBottom>
							Serviceverzoeken
						</Typography>

						<Button onClick={handleOpen} color="primary" variant="contained" disabled={loading || Boolean(error)}>
							Verzoek toevoegen
						</Button>
					</Stack>
				</CardActions>
				<CardContent sx={smallScreen ? { padding: 0 } : {}}>
					{loading && (
						<Grid container direction="column">
							<Grid item>
								<Skeleton variant="rectangular" height={100} />
							</Grid>
							<StyledDivider />
							<Grid item>
								<Skeleton variant="rectangular" height={100} />
							</Grid>
						</Grid>
					)}
					{error && <Typography>Fout bij ophalen van de gegevens...</Typography>}
					{!error && !loading && installation && (
						<Stack spacing={1}>
							{installation?.serviceRequests?.length ? (
								<>
									{installation?.serviceRequests.map((serviceRequest) => {
										if (!serviceRequest?.history || !serviceRequest?.history.length) return null
										const newestItem = serviceRequest.history[0] //History is sorted newest to oldest
										const oldestItem = serviceRequest.history[serviceRequest.history.length - 1]
										const closed = newestItem?.statusType?.status === OpenStatus.Closed

										if (!newestItem?.timestamp || !oldestItem?.timestamp) return null
										const daysOpen =
											differenceInDays(
												closed ? new Date(newestItem?.timestamp) : new Date(),
												new Date(oldestItem.timestamp)
											) + 1

										return (
											<Accordion key={`sr-${serviceRequest.id}`} defaultExpanded={!closed}>
												<AccordionSummary expandIcon={<ExpandMore />}>
													<Grid container direction="row" alignItems="center" spacing={1}>
														<Grid item xs={12} sm={6}>
															<Stack direction="row" spacing={2}>
																<SeverityIcon severity={serviceRequest?.serviceType?.severity} />
																<Typography>{serviceRequest.serviceType.name}</Typography>
															</Stack>
														</Grid>
														<Grid item xs={12} sm={6}>
															<Stack direction="row" spacing={2}>
																<StatusIcon status={newestItem.statusType.status} />
																<Typography>{newestItem.statusType.name}</Typography>
															</Stack>
														</Grid>
														<Grid item xs={12} sm={6}>
															<Stack direction="row" spacing={2}>
																<Today />
																<Typography>Aangevraagd {formatTime(oldestItem.timestamp)}</Typography>
															</Stack>
														</Grid>
														{closed ? (
															<Grid item xs={12} sm={6}>
																<Stack direction="row" spacing={2}>
																	<Event />
																	<Typography>Afgehandeld {formatTime(newestItem.timestamp)}</Typography>
																</Stack>
															</Grid>
														) : (
															<Grid item xs={12} sm={6}>
																<Stack direction="row" spacing={2}>
																	<DateRange />
																	<Typography>
																		{daysOpen} dag{daysOpen !== 1 ? "en" : ""} open
																	</Typography>
																</Stack>
															</Grid>
														)}
														{serviceRequest.endDate != null && (
															<Grid item xs={12} sm={6}>
																<Stack direction="row" spacing={2}>
																	<EventAvailable />
																	<Typography>
																		Uiterlijk {t("date", { date: new Date(serviceRequest.endDate) })}
																	</Typography>
																</Stack>
															</Grid>
														)}
														{newestItem.plannedVisit != null && (
															<Grid item xs={12} sm={6}>
																<Stack direction="row" spacing={2}>
																	<PrecisionManufacturing />
																	<Typography>
																		Bezoek {t("date", { date: new Date(newestItem.plannedVisit) })}
																	</Typography>
																</Stack>
															</Grid>
														)}
													</Grid>
												</AccordionSummary>
												<AccordionDetails>
													<StyledClickableStack
														direction="column"
														spacing={2}
														onClick={() => handleOpenServiceRequest(serviceRequest.sequenceNumber)}
													>
														{serviceRequest.history.map((h) => (
															<Stack direction="column" key={h.id}>
																<Stack direction="row" justifyContent="space-between" flexWrap="wrap">
																	<Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
																		<StatusIcon status={h.statusType.status} />
																		<Stack paddingRight={1}>
																			<Typography>{h.statusType.name}</Typography>
																			{h.plannedVisit != null && (
																				<Typography variant="caption">
																					Bezoek {t("date", { date: new Date(h.plannedVisit) })}
																				</Typography>
																			)}
																		</Stack>
																	</Stack>
																	<Typography variant="caption">
																		{formatTime(h.timestamp)} door {h.user.name}
																	</Typography>
																</Stack>
																<RawText>
																	<em>{h.description}</em>
																</RawText>
																<Stack>
																	{h.images?.length > 0 && (
																		<Grid key={h.id} container>
																			{h.images?.map(({ url }, index) => (
																				<React.Fragment key={index}>
																					<StyledImageGrid item>
																						<Image src={url} />
																					</StyledImageGrid>
																				</React.Fragment>
																			))}
																		</Grid>
																	)}
																</Stack>
															</Stack>
														))}
													</StyledClickableStack>
												</AccordionDetails>
											</Accordion>
										)
									})}
								</>
							) : (
								<Grid item>
									<Typography>Er zijn nog geen serviceverzoeken</Typography>
								</Grid>
							)}
						</Stack>
					)}
				</CardContent>
			</CustomCard>
			{serviceRequestOpen && (
				<CreateServiceRequest
					serviceRequest={{
						installationId: installation?.id,
					}}
					onClose={handleClose}
					hideInstallationField
				/>
			)}
		</>
	)
}
