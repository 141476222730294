import { Popper, Paper, Fade, PopperProps } from "@mui/material"
import { styled } from "@mui/material/styles"

const PREFIX = "Popover"

const classes = {
	popper: `${PREFIX}-popper`,
	paper: `${PREFIX}-paper`,
}

const StyledPopper = styled(Popper)(({ theme }) => ({
	[`&.${classes.popper}`]: {
		zIndex: theme.zIndex.fab,
	},

	[`& .${classes.paper}`]: {
		padding: theme.spacing(1),
	},
}))

type Props = Pick<PopperProps, "open" | "anchorEl"> & {
	children: React.ReactNode
}

export default function Popover({ anchorEl, open, children }: Props) {
	if (!anchorEl) return null
	return (
		<StyledPopper open={open} anchorEl={anchorEl} transition className={classes.popper}>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<Paper className={classes.paper}>{children}</Paper>
				</Fade>
			)}
		</StyledPopper>
	)
}
