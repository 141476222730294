/* eslint-disable i18next/no-literal-string */ //Internal only
import { InputAdornment, TextField } from "@mui/material"
import { styled } from "@mui/material/styles"
import { HourglassEmpty } from "@mui/icons-material"
import { FilterOptionsState } from "@mui/material/useAutocomplete"
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from "@mui/material/Autocomplete"
import { useMemo, useRef } from "react"

const PREFIX = "SearchableSelect"

const classes = {
	root: `${PREFIX}-root`,
}
const addLabelText = "Toevoegen..."
const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
	[`& .${classes.root}`]: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
}))

type ID<U extends Value> = {
	id: U
}

type Value = string | number
interface Props<T extends ID<U>, U extends Value> {
	value?: U | null
	fullWidth?: boolean
	required?: boolean
	error?: boolean
	disabled?: boolean
	loading?: boolean
	options: T[]
	getOptionValue?: (option: T) => U
	label?: string
	getOptionLabel: (option: T) => string
	helperText?: string | null
	onChange: (e: { target: { value: U | null } }) => void
	onCreateNew?: (input?: string) => void
	className?: string
	autoFocus?: boolean
	renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
}

export default function SearchableSelect<T extends ID<U>, U extends Value>({
	value,
	options,
	getOptionLabel,
	label,
	getOptionValue = (option) => option.id,
	loading = false,
	disabled = false,
	fullWidth = true,
	required = false,
	helperText,
	onChange,
	onCreateNew,
	autoFocus,
	error = false,
	className,
	renderInput,
}: Props<T, U>) {
	const onCreateInput = useRef("")
	const handleChange = async (_: unknown, opt: T | null | "add") => {
		if (opt === "add") {
			onCreateNew && (onCreateInput.current !== "" ? onCreateNew(onCreateInput.current) : onCreateNew())
		} else {
			onChange({ target: { value: opt ? getOptionValue(opt) : null } })
		}
	}

	const getOptionSelected = (option: T | "add", value: T | "add") => {
		if (option === "add" || value === "add") {
			return option === value
		}

		return getOptionValue(option) === getOptionValue(value)
	}

	const optionLabel: (value: T | "add") => string = (value) => {
		if (value === "add") return onCreateInput.current !== "" ? onCreateInput.current : addLabelText
		return getOptionLabel(value)
	}

	const option = options.find((option) => getOptionValue(option) === value)

	const filter = useMemo(() => createFilterOptions<T | "add">(), [])

	const filterOptions = (options: (T | "add")[], params: FilterOptionsState<T | "add">) => {
		const filtered = filter(options, params)

		// // Suggest the creation of a new value
		if (onCreateNew) {
			filtered.push("add")
		}

		return filtered
	}

	return (
		<Autocomplete
			selectOnFocus
			handleHomeEndKeys
			className={classes.root}
			value={option || null}
			options={options}
			fullWidth={fullWidth}
			getOptionLabel={optionLabel}
			isOptionEqualToValue={getOptionSelected}
			disabled={disabled}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option === "add" ? "add" : String(getOptionValue(option))}>
						{option === "add" && onCreateInput.current !== "" ? (
							<>
								Nieuwe {label?.toLowerCase()} {onCreateInput.current} {addLabelText}
							</>
						) : (
							optionLabel(option)
						)}
					</li>
				)
			}}
			renderInput={
				renderInput
					? renderInput
					: (params) => (
							<TextField
								{...params}
								label={label}
								margin="dense"
								fullWidth={fullWidth}
								required={required}
								helperText={helperText}
								error={error}
								className={className}
								autoFocus={autoFocus}
								InputProps={
									loading
										? {
												endAdornment: (
													<StyledInputAdornment position="end">
														<HourglassEmpty />
													</StyledInputAdornment>
												),
										  }
										: params.InputProps
								}
							/>
					  )
			}
			filterOptions={filterOptions}
			onChange={handleChange}
			onInputChange={(_, value) => {
				value !== addLabelText && (onCreateInput.current = value)
			}}
		/>
	)
}
