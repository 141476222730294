import { Button, ButtonProps } from "@mui/material"
import getFile, { StringResult, FileResult } from "tools/getFile"

interface DefaultProps extends ButtonProps {
	children: React.ReactNode
	accept: string
}

export default function FileInput(
	props: {
		readAs?: "string"
		multiple?: false
		onFileContent: (result: StringResult) => void
	} & DefaultProps
): JSX.Element

export default function FileInput(
	props: {
		readAs?: "string"
		multiple?: true
		onFileContent: (result: StringResult[]) => void
	} & DefaultProps
): JSX.Element

export default function FileInput(
	props: {
		readAs?: "file"
		multiple?: false
		onFileContent: (result: FileResult) => void
	} & DefaultProps
): JSX.Element

export default function FileInput(
	props: {
		readAs?: "file"
		multiple?: true
		onFileContent: (result: FileResult[]) => void
	} & DefaultProps
): JSX.Element

export default function FileInput({
	onFileContent,
	children,
	accept,
	readAs = "string",
	multiple = false,
	...props
}: DefaultProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	readAs?: any
	multiple?: boolean
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFileContent: (result: any) => void
}) {
	const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target
		if (!files) return
		if (!multiple) {
			const result = await getFile(files[0], readAs)
			onFileContent(result)
		} else {
			const result = await Promise.all([...files].map((file) => getFile(file, readAs)))
			onFileContent(result)
		}
		event.target.value = ""
	}

	return (
		<label>
			<Button component="span" {...props}>
				{children}
			</Button>
			<input accept={accept} multiple type="file" style={{ display: "none" }} onChange={handleFileInput} />
		</label>
	)
}
