/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import CreateUser from "components/forms/UpsertUser"
import SearchableSelect from "components/SearchableSelect"
import { Role, UserFieldsFragment, useUsersQuery } from "generated/graphql"
import { useState } from "react"

type Props = {
	value?: string | null
	onChange: (value: string | null) => void
	label: string
	required?: boolean
	error?: boolean
	helperText?: string | null
	roles: Role[]
}

export function checkRolesAndDeleted(roles: Role[]) {
	return (user: UserFieldsFragment) => !user.isDeleted && user.roles.some((role) => roles.includes(role))
}

export default function UserInput({ value, onChange, label, required, error, helperText, roles }: Props) {
	const { data, loading, error: qError } = useUsersQuery()

	const checkUserRoles = checkRolesAndDeleted(roles)
	const usersWithRole = data?.users.filter(checkUserRoles)

	const [closeHandler, setCloseHandler] = useState<(() => (user: UserFieldsFragment | null) => void) | null>()

	const handleChange = (e: { target: { value: string | number | null } }) => {
		onChange(typeof e.target.value === "string" ? e.target.value : null)
	}

	const handleNew = () => {
		setCloseHandler(() => (user: UserFieldsFragment | null) => {
			setCloseHandler(null)
			if (!user || !checkUserRoles(user)) {
				return onChange(null)
			}
			onChange(user.id)
		})
	}

	if (qError) {
		return <Typography color="error">Er ging iets fout bij het ophalen van de gebruikers</Typography>
	}

	return (
		<>
			<SearchableSelect
				value={value}
				onChange={handleChange}
				options={usersWithRole ?? []}
				getOptionLabel={({ name }) => name}
				disabled={loading || Boolean(qError)}
				label={label}
				required={required}
				error={error}
				helperText={helperText}
				onCreateNew={handleNew}
			/>
			{closeHandler && <CreateUser onClose={closeHandler} user={{ roles }} />}
		</>
	)
}
