/* eslint-disable i18next/no-literal-string */ //Internal only
import { styled } from "@mui/material/styles"
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, SelectChangeEvent } from "@mui/material"
import { RoofType } from "generated/graphql"
import roofTypes from "settings/roofTypes"

const PREFIX = "RoofTypeInput"

const classes = {
	select: `${PREFIX}-select`,
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	[`&.${classes.select}`]: {
		width: "100%",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}))

interface Props {
	value?: string | null
	onChange: (value: RoofType | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function RoofTypeInput({ value, error, helperText, required, onChange, label = "Type dak" }: Props) {
	const handleChange = (e: SelectChangeEvent) => {
		if (e.target.value in Object.values(RoofType)) {
			onChange(e.target.value as RoofType)
		} else {
			onChange(null)
		}
	}

	return (
		<StyledFormControl className={classes.select}>
			<InputLabel id="roof-type-id" required={required}>
				{label}
			</InputLabel>
			<Select labelId="roof-type-id" label={label} value={value ?? ""} onChange={handleChange}>
				<MenuItem value="">
					<em>Onbekend</em>
				</MenuItem>
				{Object.entries(roofTypes).map(([key, value]) => (
					<MenuItem key={key} value={key}>
						{value}
					</MenuItem>
				))}
			</Select>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</StyledFormControl>
	)
}
