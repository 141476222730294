/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import { useAddInstallationToServiceRequestMutation } from "generated/graphql"
import InstallationInput from "inputs/InstallationInput"
import appendErrorMessage from "tools/appendErrorMessage"

interface ServiceRequestInstallation {
	installationId?: string
	serviceRequestId?: string
}

type Props = {
	onClose: () => void
	sr: ServiceRequestInstallation
}

export default function AddInstallation({ onClose, sr: editSR }: Props) {
	const [addInstallationToServiceRequest, { loading: srLoading }] = useAddInstallationToServiceRequestMutation()

	const message = useMessage()

	const { register, submit } = useForm<ServiceRequestInstallation>(editSR, handleSave, ["serviceRequestId"])
	async function handleSave(sr: ServiceRequestInstallation) {
		try {
			await addInstallationToServiceRequest({
				variables: {
					installationId: sr.installationId ?? "",
					serviceRequestId: sr.serviceRequestId ?? "",
				},
			})
			message.success("Installatie toegevoegd")
			onClose()
		} catch (e) {
			message.error(appendErrorMessage("Toevoegen mislukt", e))
		}
	}

	return (
		<TSDialog autoFullScreen open fullWidth onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Installatie toevoegen</DialogTitle>
			<DialogContent>
				<DialogContentText>Selecteer een installatie.</DialogContentText>
				<InstallationInput {...register("installationId")} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" disabled={srLoading}>
					Annuleren
				</Button>
				<Button onClick={submit} variant="contained" color="primary" disabled={srLoading}>
					Opslaan
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
