/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import { NewCoolant, useUpsertCoolantMutation, CoolantsDocument } from "generated/graphql"
import TextInput from "inputs/TextInput"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	coolant?: NewCoolant | null
	onClose: (coolant: NewCoolant | null) => void
}

export default function UpsertCoolant({ onClose, coolant: editCoolant }: Props) {
	const [upsertCoolant, { loading }] = useUpsertCoolantMutation({
		refetchQueries: [{ query: CoolantsDocument }],
		awaitRefetchQueries: true,
	})

	const message = useMessage()
	const { register, submit } = useForm<NewCoolant>(editCoolant, handleSave)

	async function handleSave(coolant: NewCoolant) {
		try {
			const { data } = await upsertCoolant({ variables: { coolant } })
			message.success("Koelmiddel opgeslagen")
			if (!data) {
				message.error("Opslaan mislukt")
				return
			}
			onClose(data?.upsertCoolant ?? null)
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}
	const handleClose = () => onClose(null)

	return (
		<TSDialog autoFullScreen open onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Koelmiddel</DialogTitle>
			<DialogContent>
				<DialogContentText>Vul hieronder het type koelmiddel in.</DialogContentText>
				<TextInput autoFocus label="Naam" {...register("name", { required: true })} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" disabled={loading}>
					Annuleren
				</Button>
				<Button onClick={submit} color="primary" disabled={loading}>
					Opslaan
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
