import MessageProvider from "providers/MessageProvider"
import { BrowserRouter } from "react-router-dom"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import Routes from "pages/Routes"
import { ConfirmationProvider } from "providers/ConfirmProvider"
import { ChoiceProvider } from "providers/ChoiceProvider"
import { nl, enUS, de } from "date-fns/locale"
import { useTranslation } from "react-i18next"

function App() {
	const { i18n } = useTranslation()

	let locale
	switch (i18n.language) {
		case "nl":
			locale = nl
			break
		case "en":
			locale = enUS
			break
		case "de":
			locale = de
			break
		default:
			locale = undefined
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
			<MessageProvider>
				<ConfirmationProvider>
					<ChoiceProvider>
						<BrowserRouter future={{ v7_startTransition: true }}>
							<Routes />
						</BrowserRouter>
					</ChoiceProvider>
				</ConfirmationProvider>
			</MessageProvider>
		</LocalizationProvider>
	)
}

export default App
