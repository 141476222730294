/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import SearchableSelect from "components/SearchableSelect"
import { useStatusTypesQuery } from "generated/graphql"
import { useTranslation } from "react-i18next"

type Props = {
	value?: string | null
	onChange: (value: string | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function ServiceTypeInput({ value, onChange, label = "Status", required, error, helperText }: Props) {
	const { t } = useTranslation(["serviceRequests"])
	const { data, loading, error: qError } = useStatusTypesQuery()

	const handleChange = (e: { target: { value: string | number | null } }) => {
		onChange(typeof e.target.value === "string" ? e.target.value : null)
	}

	if (qError) {
		return <Typography color="error">{t("serviceRequests:Errors.FetchingServiceRequests")}</Typography>
	}

	return (
		<SearchableSelect
			value={value}
			onChange={handleChange}
			options={data?.statusTypes ?? []}
			getOptionLabel={({ name }) => name}
			disabled={loading || Boolean(qError)}
			label={label}
			required={required}
			error={error}
			helperText={helperText}
		/>
	)
}
