/* eslint-disable i18next/no-literal-string */ //Internal only
import { useState } from "react"
import Table from "components/Table"
import CreateServiceType from "components/forms/UpsertServiceType"
import { Typography } from "@mui/material"
import severity from "settings/severity"
import { useServiceTypesQuery, ServiceTypeFieldsFragment, ServiceType } from "generated/graphql"
import { SearchProps } from "./Users"
import Fuse from "fuse.js"
import AddButton from "components/AddButton"
import { useTranslation } from "react-i18next"

const labels = [
	{ key: "name", name: "Naam", sortable: true },
	{
		key: "severity",
		name: "Prioriteit",
		sortable: true,
		sort: ({ severity: val }: ServiceTypeFieldsFragment) => severity[val] ?? "",
		resolve: ({ severity: val }: ServiceTypeFieldsFragment) => severity[val],
	},
]

export default function ServiceTypes({ search }: SearchProps) {
	const { i18n } = useTranslation("general")

	const options = {
		includeScore: true,
		threshold: 0.4,
		keys: ["name", "severity"],
		getFn: (obj: ServiceType, path: string | string[]) => {
			if (path.includes("severity")) {
				const severityTranslations = i18n.t("severityTranslations", { returnObjects: true })
				return severityTranslations[obj.severity]
			}
			if (path.includes("name")) {
				return obj.name
			}
			return []
		},
	}

	const { data, loading, error } = useServiceTypesQuery()
	const [addDialogOpen, setAddDialogOpen] = useState(false)
	const [serviceType, setServiceType] = useState<ServiceTypeFieldsFragment | null>(null)

	const handleAdd = () => setAddDialogOpen(true)

	const fuse = new Fuse((data?.serviceTypes as ServiceType[]) ?? [], options)
	const serviceTypes = search ? fuse.search(search).map(({ item }) => item) : data?.serviceTypes ?? []

	const handleEdit = (id: string) => {
		const serviceType = serviceTypes.find((p) => p?.id === id)
		setServiceType(serviceType ?? null)
		setAddDialogOpen(true)
	}

	if (loading) return <Typography>Servicetypes ophalen...</Typography>
	if (error) return <Typography>Fout bij het ophalen van de service types...</Typography>
	return (
		<>
			<AddButton onClick={handleAdd}>Servicetype toevoegen</AddButton>
			<Table defaultItemsPerPage={20} rows={serviceTypes} labels={labels} loading={loading} onClickRow={handleEdit} />
			{addDialogOpen && (
				<CreateServiceType
					serviceType={serviceType}
					onClose={() => {
						setServiceType(null)
						setAddDialogOpen(false)
					}}
				/>
			)}
		</>
	)
}
