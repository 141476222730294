/* eslint-disable i18next/no-literal-string */ //Internal only
import { useState } from "react"
import Table from "components/Table"
import CreateStatusType from "components/forms/UpsertStatusType"
import { Typography } from "@mui/material"
import status from "settings/status"
import { StatusType, StatusTypeFieldsFragment, useStatusTypesQuery } from "generated/graphql"
import { SearchProps } from "./Users"
import Fuse from "fuse.js"
import AddButton from "components/AddButton"
import { useTranslation } from "react-i18next"

const labels = [
	{ key: "name", name: "Naam", sortable: true },
	{
		key: "status",
		name: "Status",
		sortable: true,
		resolve: ({ status: val }: StatusTypeFieldsFragment) => status[val],
	},
]

export default function StatusTypes({ search }: SearchProps) {
	const { i18n } = useTranslation("general")
	const options = {
		includeScore: true,
		threshold: 0.4,
		keys: ["name", "status"],
		getFn: (obj: StatusType, path: string | string[]) => {
			if (path.includes("status")) {
				const statusTranslations = i18n.t("statusTranslations", { returnObjects: true })
				return statusTranslations[obj.status]
			}
			if (path.includes("name")) {
				return obj.name
			}
			return []
		},
	}
	const { data, loading, error } = useStatusTypesQuery()
	const [addDialogOpen, setAddDialogOpen] = useState(false)
	const [statusType, setStatusType] = useState<StatusTypeFieldsFragment | null>(null)

	const handleAdd = () => setAddDialogOpen(true)

	const fuse = new Fuse((data?.statusTypes as StatusType[]) ?? [], options)
	const statusTypes = search ? fuse.search(search).map(({ item }) => item) : data?.statusTypes ?? []

	const handleEdit = (id: string) => {
		const statusType = statusTypes.find((p) => p?.id === id)
		setStatusType(statusType ?? null)
		setAddDialogOpen(true)
	}

	if (loading) return <Typography>Paneeltypes ophalen...</Typography>
	if (error) return <Typography>Fout bij het ophalen van de paneeltypes...</Typography>
	return (
		<>
			<AddButton onClick={handleAdd}>Paneeltype toevoegen</AddButton>
			<Table defaultItemsPerPage={20} rows={statusTypes} labels={labels} loading={loading} onClickRow={handleEdit} />
			{addDialogOpen && (
				<CreateStatusType
					statusType={statusType}
					onClose={() => {
						setStatusType(null)
						setAddDialogOpen(false)
					}}
				/>
			)}
		</>
	)
}
