export const ErrIfaceClaimed = "this interface has already been claimed"
export const ErrIfaceUnclaimed = "this interface has not been claimed"
export const ErrIfaceNotInGroup = "interface not in this group"
export const ErrNoAccessToInterface = "you don't have access to this interface"
export const ErrInviteToSelf = "you cannot invite yourself"
export const ErrGroupNotFound = "group not found"
export const ErrUserWithoutAcc = "user exists, but does not have an account"
export const ErrCantInviteToUnclaimedIface = "you can't invite users to an unclaimed interface"
export const ErrCanNotRemoveOwner = "heat pump owner can't remove heat pump while other groups have access"
export const ErrCanNotRemoveLastAdmin = "last admin can't be removed from a group with other users"
