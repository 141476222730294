/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import SearchableSelect from "components/SearchableSelect"
import { useServiceTypesQuery } from "generated/graphql"
import severityLabels from "settings/severity"
import { useTranslation } from "react-i18next"

type Props = {
	value?: string
	onChange: (value: string | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function ServiceTypeInput({ value, onChange, label, required, error, helperText }: Props) {
	const { data, loading, error: qError } = useServiceTypesQuery()
	const { t } = useTranslation(["serviceRequests"])

	const handleChange = (e: { target: { value: string | number | null } }) => {
		onChange(typeof e.target.value === "string" ? e.target.value : null)
	}

	if (qError) {
		return <Typography color="error">{t("serviceRequests:RequestType")}</Typography>
	}

	return (
		<SearchableSelect
			value={value}
			onChange={handleChange}
			options={data?.serviceTypes ?? []}
			getOptionLabel={({ name, severity }) => `${name} (${severityLabels[severity]})`}
			disabled={loading || Boolean(qError)}
			label={label || t("serviceRequests:RequestType")}
			required={required}
			error={error}
			helperText={helperText}
		/>
	)
}
