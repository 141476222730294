/* eslint-disable i18next/no-literal-string */ //Internal only
import { useState } from "react"
import Table from "components/Table"
import CreateCoolantType from "components/forms/UpsertCoolant"
import { Typography } from "@mui/material"
import { CoolantFieldsFragment, useCoolantsQuery } from "generated/graphql"
import { SearchProps } from "./Users"
import Fuse from "fuse.js"
import AddButton from "components/AddButton"

const labels = [{ key: "name", name: "Naam", sortable: true }]

const options = {
	includeScore: true,
	threshold: 0.4,
	keys: ["name"],
}

function CoolantTypePage({ search }: SearchProps) {
	const { data, loading, error } = useCoolantsQuery()
	const [addDialogOpen, setAddDialogOpen] = useState(false)
	const [coolant, setCoolant] = useState<CoolantFieldsFragment | null>(null)

	const handleAdd = () => setAddDialogOpen(true)

	const fuse = new Fuse(data?.coolants ?? [], options)
	const coolants = search ? fuse.search(search).map(({ item }) => item) : data?.coolants ?? []

	const handleEdit = (id: string) => {
		if (!coolants) return
		const coolant = coolants.find((c) => c?.id === id)
		setCoolant(coolant ?? null)
		setAddDialogOpen(true)
	}

	if (loading) return <Typography>Koelmiddelen ophalen...</Typography>
	if (error) return <Typography>Fout bij het ophalen van de koelmiddelen...</Typography>
	return (
		<>
			<AddButton onClick={handleAdd}>Koelmiddelen toevoegen</AddButton>
			<Table defaultItemsPerPage={20} rows={coolants} labels={labels} loading={loading} onClickRow={handleEdit} />
			{addDialogOpen && (
				<CreateCoolantType
					coolant={coolant}
					onClose={() => {
						setCoolant(null)
						setAddDialogOpen(false)
					}}
				/>
			)}
		</>
	)
}

export default CoolantTypePage
