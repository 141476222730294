import { makeVar } from "@apollo/client"

import { ServiceRequestFilter } from "generated/graphql"
import getValueFromStorage from "tools/getValueFromStorage"
import omit from "tools/omit"
import pick from "tools/pick"

export const emptyServiceRequestFilter: ServiceRequestFilter = {
	closedRequests: false,
	searchText: "",
	sortColumn: "",
	sortIsAscending: false,
	hiddenServiceRequestFields: ["invoice", "statusType"],
	colWidths: [],
}

const localValues = getValueFromStorage<Pick<ServiceRequestFilter, "hiddenServiceRequestFields" | "colWidths">>(
	localStorage,
	"serviceRequestFilterLocalFields",
	pick(emptyServiceRequestFilter, ["hiddenServiceRequestFields", "colWidths"])
)

const sessionValues = getValueFromStorage<Omit<ServiceRequestFilter, "hiddenServiceRequestFields" | "colWidths">>(
	sessionStorage,
	"serviceRequestFilter",
	omit(emptyServiceRequestFilter, ["hiddenServiceRequestFields", "colWidths"])
)

const filter = makeVar({ ...sessionValues, ...localValues })

const handleChange = (updatedFilter: ServiceRequestFilter) => {
	filter.onNextChange(handleChange)

	if (updatedFilter.colWidths.length > 0) {
		sessionStorage.setItem(
			"serviceRequestFilter",
			JSON.stringify(omit(updatedFilter, ["hiddenServiceRequestFields", "colWidths"]))
		)
		localStorage.setItem(
			"serviceRequestFilterLocalFields",
			JSON.stringify(pick(updatedFilter, ["hiddenServiceRequestFields", "colWidths"]))
		)
	}
}
filter.onNextChange(handleChange)
export default filter
