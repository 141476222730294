import Page from "components/Page"
import { styled } from "@mui/material/styles"
import { Grid, Stack } from "@mui/material"
import { useParams } from "react-router"
import InfoCard from "./components/InfoCard"
import LogCard from "./components/LogCard"
import ServiceRequestsCard from "./components/ServiceRequestsCard"
import AfasOrderCard from "./components/AfasOrderCard"
import NotesCard from "./components/NotesCard"
import FilesCard from "./components/FilesCard"
import { useInstallationQuery } from "generated/graphql"
import useSize from "hooks/useSize"
import { Navigate } from "react-router-dom"
import { NOT_FOUND } from "settings/url"
import ServiceAppReadOnlyBanner from "components/ServiceAppReadOnlyBanner"

const StyledPage = styled(Page)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}))

export default function InstallationPage() {
	const { installationId } = useParams<"installationId">()
	if (!installationId) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page

	const smallScreen = useSize("down", "lg")
	const { data } = useInstallationQuery({
		variables: { installationId },
	})

	return (
		<>
			<StyledPage title={data?.installation?.shortAddress ?? "Laden"} backButton noPadding={smallScreen}>
				<ServiceAppReadOnlyBanner />
				<Grid container spacing={smallScreen ? 1 : 2}>
					{/* Grid item is defined in LogCard because else it would take up space if there are nog logs */}
					<LogCard installationId={installationId} />
					<Grid item xs={12} lg={6}>
						<Stack direction="column" spacing={smallScreen ? 1 : 2}>
							<InfoCard installationId={installationId} />
							<NotesCard installationId={installationId} />
							<FilesCard installationId={installationId} />
						</Stack>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Stack direction="column" spacing={smallScreen ? 1 : 2}>
							<ServiceRequestsCard installationId={installationId} />
							<AfasOrderCard installationId={installationId} />
						</Stack>
					</Grid>
				</Grid>
			</StyledPage>
		</>
	)
}
