/* eslint-disable i18next/no-literal-string */ //Internal only
import { useMessage } from "providers/MessageProvider"
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import { CoolingModuleInput, CoolingModulesDocument, useUpsertCoolingModuleMutation } from "generated/graphql"
import TextInput from "inputs/TextInput"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	onClose: (coolingModule: CoolingModuleInput | null) => void
	coolingModule?: Partial<CoolingModuleInput> | null
}

export default function UpsertCoolingModule({ onClose, coolingModule: editCoolingModule }: Props) {
	const [upsertCoolingModule, { loading }] = useUpsertCoolingModuleMutation({
		refetchQueries: [{ query: CoolingModulesDocument }],
		awaitRefetchQueries: true,
	})
	const message = useMessage()
	const { register, submit } = useForm<CoolingModuleInput>(editCoolingModule, handleSave)

	async function handleSave(coolingModule: CoolingModuleInput) {
		try {
			const { data } = await upsertCoolingModule({
				variables: { coolingModule },
			})
			message.success("Koelmodule opgeslagen")
			onClose(data?.upsertCoolingModule ?? null)
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}
	const handleClose = () => onClose(null)

	return (
		<TSDialog autoFullScreen open onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Koelmodule</DialogTitle>
			<DialogContent>
				<DialogContentText>Vul hieronder het type koelmodule in.</DialogContentText>
				<TextInput autoFocus label="Naam" {...register("name", { required: true })} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" disabled={loading}>
					Annuleren
				</Button>
				<Button onClick={submit} color="primary" disabled={loading}>
					Opslaan
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
