import { makeVar } from "@apollo/client"

import { InstallationPageFilter } from "generated/graphql"
import pick from "../tools/pick"
import getValueFromStorage from "../tools/getValueFromStorage"

const emptyInstallationPageFilter: InstallationPageFilter = {
	searchText: "",
	searchArray: [],
}

const localValues = getValueFromStorage<Pick<InstallationPageFilter, "searchArray">>(
	localStorage,
	"searchArray",
	pick(emptyInstallationPageFilter, ["searchArray"])
)

const sessionValues = getValueFromStorage<Pick<InstallationPageFilter, "searchText">>(
	sessionStorage,
	"searchText",
	pick(emptyInstallationPageFilter, ["searchText"])
)

const filter = makeVar({ ...sessionValues, ...localValues })

const handleChange = (updatedFilter: InstallationPageFilter) => {
	filter.onNextChange(handleChange)

	sessionStorage.setItem("searchArray", JSON.stringify(pick(updatedFilter, ["searchText"])))
	localStorage.setItem("searchArray", JSON.stringify(pick(updatedFilter, ["searchArray"])))
}
filter.onNextChange(handleChange)
export default filter
