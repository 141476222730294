/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography, Link } from "@mui/material"

interface InstallerFields {
	organization?: string | null
	name?: string | null
	email?: string | null
	phoneNumber?: string | null
}

interface Installation {
	installer?: InstallerFields | null
	insideInstaller?: InstallerFields | null
}

type Props = {
	children?: Installation | null
}

function SingleInstaller({ installer, gutterBottom }: { installer?: InstallerFields | null; gutterBottom?: boolean }) {
	return (
		<Typography gutterBottom={gutterBottom}>
			{installer ? (
				<>
					{installer.organization && (
						<>
							{installer.organization}
							<br />
						</>
					)}
					{installer.name && (
						<>
							{installer.name}
							<br />
						</>
					)}
					{installer.email && (
						<>
							<Link href={`mailto:${installer.email}`}>{installer.email}</Link>
							<br />
						</>
					)}
					{installer.phoneNumber && (
						<>
							{installer.phoneNumber}
							<br />
						</>
					)}
				</>
			) : (
				"Onbekende installateur"
			)}
		</Typography>
	)
}

export default function Installer({ children: installation }: Props) {
	return (
		<>
			{installation?.installer && (
				<>
					<Typography variant="subtitle2">Dak:</Typography>
					<SingleInstaller installer={installation?.installer} gutterBottom />
				</>
			)}
			{installation?.insideInstaller && (
				<>
					<Typography variant="subtitle2">Binnen:</Typography>
					<SingleInstaller installer={installation?.insideInstaller} />
				</>
			)}
		</>
	)
}
