import { Stack, styled } from "@mui/material"

const MarginCompensate = styled("div")(({ theme }) => ({
	marginLeft: theme.spacing(-2),
	marginRight: theme.spacing(-2),
	marginBottom: theme.spacing(2),
	marginTop: theme.spacing(-2),
}))

const Banner = styled(Stack)(({ theme }) => ({
	height: "30px",
	textAlign: "center",
	backgroundColor: theme.palette.secondary.main,
	color: theme.palette.secondary.contrastText,
	fontWeight: "bold",
}))

type Props = {
	noMargin?: boolean
}

export default function ServiceAppReadOnlyBanner({ noMargin }: Props) {
	const inner = (
		<Banner direction="row" justifyContent="center" alignItems="center">
			{/* This component is temporary and only relevant for the Dutch service app*/}
			{/* eslint-disable i18next/no-literal-string */}
			Er kunnen geen aanpassingen meer worden gedaan aan de serviceapp
		</Banner>
	)

	if (noMargin) {
		return inner
	}

	return <MarginCompensate>{inner}</MarginCompensate>
}
