interface Installation {
	resident?: {
		name?: string | null
	} | null
	shortAddress?: string | null
	installer?: {
		organization?: string | null
	} | null
	installerAddress?: boolean
}

export default function formatInstallation(installation?: Installation | null) {
	if (!installation) return null
	return `${installation.resident ? `${installation.resident?.name}, ` : ""}${
		installation.installerAddress ? "Onbekend adres" : installation.shortAddress
	}${installation.installer ? ` (${installation.installer?.organization})` : ""}`
}
