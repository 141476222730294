/* eslint-disable i18next/no-literal-string */ //Internal only
import { useRef, useState } from "react"
import { useMessage } from "providers/MessageProvider"
import {
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Button,
	Grid,
	Tooltip,
} from "@mui/material"
import geolocate from "tools/geolocate"
import { useConfirm } from "providers/ConfirmProvider"
import useForm from "hooks/useForm"
import SelectInstallation from "./SelectInstallation"
import useSize from "hooks/useSize"
import TSDialog from "components/TSDialog"
import {
	Installation,
	InstallationFieldsFragment,
	InstallationsDocument,
	NewInstallation,
	Role,
	useInstallersQuery,
	useUpsertInstallationMutation,
} from "generated/graphql"
import CoolantInput from "inputs/CoolantInput"
import HeatPumpInput from "inputs/HeatPumpInput"
import PanelInput from "inputs/PanelInput"
import UserInput from "inputs/UserInput"
import InstallerInput from "inputs/InstallerInput"
import useFocus from "hooks/useFocus"
import CoolingModuleInput from "inputs/CoolingModule"
import TextInput from "inputs/TextInput"
import NumberInput from "inputs/NumberInput"
import RoofTypeInput from "inputs/RoofTypeInput"
import BooleanInput from "inputs/BooleanInput"
import DateInput from "inputs/DateInput"
import InterfaceIDfromDBInput from "inputs/InterfaceIdFromDBInput"
import appendErrorMessage from "tools/appendErrorMessage"

function installationToInput(installation?: Partial<InstallationFieldsFragment> | null): NewInstallation {
	return {
		id: installation?.id ?? null,
		street: installation?.street ?? "",
		zipCode: installation?.zipCode ?? "",
		city: installation?.city ?? "",
		installerAddress: installation?.installerAddress ?? false,
		installerReferenceNumber: installation?.installerReferenceNumber ?? null,
		panelId: installation?.panel?.id ?? null,
		panelCount: installation?.panelCount ?? null,
		roofType: installation?.roofType ?? null,
		heatPumpId: installation?.heatPump?.id ?? null,
		heatPumpCount: installation?.heatPumpCount === null ? null : 1,
		coolantId: installation?.coolant?.id ?? null,
		coolingModuleId: installation?.coolingModule?.id ?? null,
		installerId: installation?.installer?.id ?? null,
		insideInstallerId: installation?.insideInstaller?.id ?? null,
		residentId: installation?.resident?.id ?? null,
		afasOrderNumber: installation?.afasOrderNumber ?? null,
		softwareVersion: installation?.softwareVersion ?? null,
		collectiveRoof: installation?.collectiveRoof ?? false,
		inverterType: installation?.inverterType ?? null,
		installedOn: installation?.installedOn ?? null,
		ibsByTripleSolar: installation?.ibsByTripleSolar ?? false,
		interfaceIDs: installation?.interfaceIDs ?? null,
		location:
			installation?.location?.lat && installation?.location?.lon
				? {
						lat: installation.location.lat,
						lon: installation.location.lon,
					}
				: {
						lat: NaN,
						lon: NaN,
					},
	}
}

type Props = {
	onClose: (installation: InstallationFieldsFragment | null) => void
	installation?: InstallationFieldsFragment | null
}

export default function UpsertInstallation({ onClose, installation: editInstallation }: Props) {
	const hasShowConfirm = useRef(false)
	const [search, setSearch] = useState<string | null>(null)
	const [selectInstallationCloseHandler, setSelectInstallationCloseHandler] = useState<
		(() => (installation: InstallationFieldsFragment) => void) | null
	>()

	const fullScreen = useSize("down", "lg")
	const [addrRef, focusAddr] = useFocus()

	const { data } = useInstallersQuery()
	const [upsertInstallation, { loading }] = useUpsertInstallationMutation()

	const confirm = useConfirm()
	const message = useMessage()

	const { submit, register, set, fields, reset } = useForm<NewInstallation>(
		installationToInput(editInstallation),
		handleSave,
		["location", "street", "zipCode", "city", "installerAddress"],
	)

	async function handleSave(installation: NewInstallation) {
		if (!fields.installerAddress && !installation.street) {
			message.info("Zoek eerst een adres")
			return
		}
		try {
			const { data } = await upsertInstallation({
				variables: { installation },
				update: (client, { data }) => {
					const upsertInstallation = data?.upsertInstallation
					if (installation.id) {
						return
					}
					let installations
					try {
						const data = client.readQuery<{
							installations?: Installation[]
						}>({
							query: InstallationsDocument,
						})
						if (!data) {
							return
						}
						installations = data.installations
					} catch (e) {
						return // not in cache, don't bother
					}
					const newInstallations = [
						...(installations ?? []).filter(({ id }) => id !== upsertInstallation?.id),
						upsertInstallation,
					]
					client.writeQuery({
						query: InstallationsDocument,
						data: { installations: newInstallations },
					})
				},
			})
			message.success("Installatie opgeslagen")
			onClose(data?.upsertInstallation ?? null)
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}

	const searchAddress = async () => {
		const location = await geolocate(search)
		if (!location) {
			message.error("Deze locatie is niet gevonden.")
			return
		}
		message.success("Locatie gevonden!")
		set({
			street: location.address,
			zipCode: location.postalCode,
			city: location.placeName,
			location: { lat: location.lat, lon: location.lon },
		})
	}

	const handleChangeAddress = () => {
		set({
			installerAddress: false,
			street: "",
			zipCode: "",
			city: "",
			location: { lat: NaN, lon: NaN },
		})
		setSearch(`${fields.street}, ${fields.city}`)
	}

	const handleConfirm = async () => {
		if (hasShowConfirm.current) return
		hasShowConfirm.current = true
		await confirm(
			'Let op! Als je op deze manier handmatig het adres verandert worden de coordinaten van de installatie niet geupdate. Maak alleen wijzigingen op deze manier als je een adres niet kan vinden met de zoekfunctie, en gebruik anders de knop "Adres wijzigen".',
		)
	}

	const handleSelectInstallation = () =>
		setSelectInstallationCloseHandler(() => (installation: InstallationFieldsFragment) => {
			if (!installation) {
				setSelectInstallationCloseHandler(null)
				return
			}
			// remove some fields from installation before resetting form
			const { id: _1, resident: _2, street, city, zipCode: _3, location: _4, ...fields } = installation
			reset(installationToInput(fields))
			setSearch(`${street}, ${city}`)
			focusAddr()
			setSelectInstallationCloseHandler(null)
		})

	const handleFindAddress = async (installerId?: string | null) => {
		if (!installerId || !fields.installerAddress) return
		const installer = data?.installers.find(({ id }) => id === installerId)
		if (!installer) {
			message.error("Fout bij het ophalen van data over de installateur")
			return
		}
		const searchStr = `${installer?.street}, ${installer?.city}`
		const location = await geolocate(searchStr)
		if (!location) {
			message.error(`Kon het adres ${searchStr} niet ophalen`)
			return
		}
		set({
			street: location.address,
			zipCode: location.postalCode,
			city: location.placeName,
			location: { lat: location.lat, lon: location.lon },
		})
		message.success(`Het adres is (voor nu) op het adres van ${installer.organization} gezet`)
		// console.log(addr)
	}

	return (
		<>
			<TSDialog
				autoFullScreen
				open
				onClose={() => onClose(null)}
				aria-labelledby="form-dialog-title"
				fullScreen={fullScreen}
			>
				<DialogTitle id="form-dialog-title">
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>Installatie</Grid>
						{!fields.id && (
							<Grid item>
								<Tooltip title="Kopiëren vanuit andere installatie">
									<Button color="primary" onClick={handleSelectInstallation}>
										Kopiëren
									</Button>
								</Tooltip>
							</Grid>
						)}
					</Grid>
				</DialogTitle>
				<DialogContent>
					{!fields.installerAddress && (fields.street === "" || fields.zipCode === "" || fields.city === "") ? (
						<>
							<DialogContentText>
								Als je het adres niet kunt vinden met de zoekfunctie kun je een adres zo dichtbij mogelijk invoeren en
								daarna het adres handmatig aanpassen. Als het huis een toevoegsel heeft (bv: 23A/12-bis/45-II), probeer
								het adres dan eens in te voeren zonder toevoegsel en voeg het toevoegsel daarna handmatig toe.
							</DialogContentText>
							<TextField
								autoFocus
								required
								margin="dense"
								label="Zoek adres"
								type="text"
								fullWidth
								value={search ?? ""}
								onChange={(e) => setSearch(e.target.value)}
								placeholder="Programmeurstraat 6b, Amsterdam"
								error={search === ""}
								helperText={search === "" && "Dit veld is verplicht"}
								ref={addrRef}
							/>
							<Grid container justifyContent="space-between">
								<Button type="submit" color="primary" onClick={searchAddress}>
									Zoeken
								</Button>
								<Button color="secondary" onClick={() => set({ installerAddress: true })}>
									(Nog) geen locatie bekend
								</Button>
							</Grid>
						</>
					) : (
						<>
							<TextInput
								label="Straat en huisnummer"
								onFocus={handleConfirm}
								disabled={fields.installerAddress}
								{...register("street", { required: true })}
							/>
							<TextInput
								label="Postcode"
								onFocus={handleConfirm}
								disabled={fields.installerAddress}
								{...register("zipCode", { required: true })}
							/>
							<TextInput
								label="Stad"
								onFocus={handleConfirm}
								disabled={fields.installerAddress}
								{...register("city", { required: true })}
							/>
							<Button color="primary" onClick={handleChangeAddress}>
								Adres wijzigen
							</Button>
						</>
					)}
					<>
						<InterfaceIDfromDBInput multiple label="InterfaceIDs" {...register("interfaceIDs")} />
						<InstallerInput
							{...register("installerId", { required: fields.installerAddress }, handleFindAddress)}
							label="Installateur (dak)"
						/>
						<TextInput
							label="Referentienummer (dak) installateur"
							{...register("installerReferenceNumber", { required: fields.installerAddress })}
						/>
						<InstallerInput {...register("insideInstallerId")} label="Installateur (binnen)" />
						<DateInput label="Geinstalleerd op" {...register("installedOn", { datetime: true })} />
						<HeatPumpInput label="Type warmtepomp" {...register("heatPumpId")} />
						<BooleanInput label="IBS door Triple Solar" {...register("ibsByTripleSolar")} />
						<NumberInput label="Aantal warmtepompen" {...register("heatPumpCount", { min: 0, integer: true })} />
						<PanelInput label="Paneeltype" {...register("panelId")} />
						<NumberInput label="Aantal panelen" {...register("panelCount", { min: 0, integer: true })} />
						<RoofTypeInput {...register("roofType")} />
						<BooleanInput label="Collectief dak" {...register("collectiveRoof")} />
						<CoolantInput {...register("coolantId")} />
						<CoolingModuleInput {...register("coolingModuleId")} />
						<TextInput label="AFAS ordernummer" {...register("afasOrderNumber")} />
						<TextInput label="Softwareversie" {...register("softwareVersion")} />
						<TextInput label="Omvormer" {...register("inverterType")} />
						<UserInput label="Bewoner" roles={[Role.Resident]} {...register("residentId")} />
					</>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => onClose(null)} color="primary" disabled={loading}>
						Annuleren
					</Button>
					<Button onClick={submit} color="primary" disabled={loading}>
						Opslaan
					</Button>
				</DialogActions>
			</TSDialog>
			{selectInstallationCloseHandler && <SelectInstallation onClose={selectInstallationCloseHandler} />}
		</>
	)
}
