import { styled } from "@mui/material"

export enum MarkerColor {
	orange = "orange",
	green = "green",
	red = "red",
	yellow = "yellow",
	blue = "blue",
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const StyledMap = styled("div")`
	height: 100%;
	.${MarkerColor.orange} {
		filter: hue-rotate(165deg) brightness(120%);
	}
	.${MarkerColor.green} {
		filter: hue-rotate(-95deg);
	}
	.${MarkerColor.red} {
		filter: hue-rotate(140deg) brightness(110%);
	}
	.${MarkerColor.yellow} {
		filter: hue-rotate(195deg) brightness(160%);
	}
	.${MarkerColor.blue} {
	}
`
