/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import { RoofType } from "generated/graphql"
import roofTypes from "settings/roofTypes"

interface Installation {
	panel?: {
		name: string
		area?: number | null
	} | null
	panelCount?: number | null
	roofType?: RoofType | null
	collectiveRoof?: boolean | null
}

type Props = {
	children?: Installation | null
}

export default function Panel({ children: installation }: Props) {
	return (
		<Typography>
			{installation?.panel ? (
				<>
					{installation.panel.name} ({installation.panel.area} m<sup>2</sup>)
				</>
			) : (
				<>Onbekend paneeltype</>
			)}
			<br />
			{installation?.panelCount ? (
				<>
					{installation.panelCount} panelen
					{installation.panel?.area && (
						<>
							{" "}
							({(installation.panel.area * installation.panelCount).toFixed(1)} m<sup>2</sup> totaal)
						</>
					)}
				</>
			) : (
				<>Onbekend aantal panelen</>
			)}
			{installation?.roofType && (
				<>
					<br />
					{roofTypes[installation.roofType]} dak
				</>
			)}
			{installation?.collectiveRoof && (
				<>
					<br />
					Collectief dak
				</>
			)}
		</Typography>
	)
}
