import { Link, Typography } from "@mui/material"

interface Installation {
	resident?: {
		name?: string | null
		email?: string | null
		phoneNumbers?: string[] | null
	} | null
}

type Props = {
	children?: Installation | null
}

export default function Resident({ children: installation }: Props) {
	return (
		<Typography>
			{installation?.resident ? (
				<>
					{installation.resident.name && (
						<>
							{installation.resident.name}
							<br />
						</>
					)}
					{installation.resident.email && (
						<>
							<Link href={`mailto:${installation.resident.email}`}>{installation.resident.email}</Link>
							<br />
						</>
					)}
					{installation.resident.phoneNumbers?.map((phoneNumber) => (
						<span key={phoneNumber}>
							<Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
							<br />
						</span>
					))}
				</>
			) : (
				"Onbekende bewoner"
			)}
		</Typography>
	)
}
