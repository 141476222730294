import { Popper, Fade, Paper, List, Toolbar, Dialog } from "@mui/material"
import { styled } from "@mui/material/styles"
import useSize from "hooks/useSize"

const PREFIX = "TSMenu"

const classes = {
	paper: `${PREFIX}-paper`,
	paperSmall: `${PREFIX}-paperSmall`,
	list: `${PREFIX}-list`,
}

const StyledSmallPaper = styled(Dialog)(({ theme }) => ({
	overflowY: "auto",
	zIndex: theme.zIndex.drawer,
}))

const StyledPaper = styled(Paper)`
	max-height: 300px;
	overflow-y: auto;
	max-width: 100vw;
`

const StyledList = styled(List)(() => ({
	maxHeight: 300,
	overflowY: "auto",
	maxWidth: "100vw",
	"& li": {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
	zIndex: theme.zIndex.modal,
}))

type Props = {
	open: boolean
	anchorEl: HTMLElement | null
	children: React.ReactNode
}

export default function TSMenu({ open, anchorEl, children }: Props) {
	const smallScreen = useSize("down", "md")

	if (smallScreen) {
		return (
			<StyledSmallPaper
				disableAutoFocus
				disableEnforceFocus // allow focus outside dialog
				open={open}
				fullScreen
				className={classes.paperSmall}
			>
				<Toolbar />
				<List className={`${classes.list}`}>{children}</List>
			</StyledSmallPaper>
		)
	}

	return (
		<StyledPopper open={open} anchorEl={anchorEl} placement="bottom-start" transition>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<StyledPaper>
						<StyledList>{children}</StyledList>
					</StyledPaper>
				</Fade>
			)}
		</StyledPopper>
	)
}
