import { Typography } from "@mui/material"

interface Installation {
	softwareVersion?: string | null
}

type Props = {
	children?: Installation | null
}

export default function Installer({ children: installation }: Props) {
	return <Typography>{installation?.softwareVersion ?? "Onbekende softwareversie"}</Typography>
}
