/* eslint-disable i18next/no-literal-string */ //Internal only
import { useState } from "react"
import { styled } from "@mui/material/styles"
import { Typography, CardContent, Grid, CardActions, Button } from "@mui/material"
import {
	Home,
	ViewModule,
	FlashOn,
	Opacity,
	Build,
	Person,
	Computer,
	Today,
	WbSunny,
	DeveloperBoard,
} from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { INSTALLATION } from "settings/url"
import HeatPump from "components/render/HeatPump"
import Panel from "components/render/Panel"
import Installer from "components/render/Installer"
import Resident from "components/render/Resident"
import Coolant from "components/render/Coolant"
import Address from "components/render/Address"
import SoftwareVersion from "components/render/SoftwareVersion"
import AddInstallation from "components/forms/AddInstallation"
import InstalledOn from "components/render/InstalledOn"
import InverterType from "components/render/InverterType"
import { useServiceRequestQuery } from "generated/graphql"
import GoogleMapsDir from "components/GoogleMapsDir"
import CustomCard from "components/CustomCard"
import InterfaceInfo from "components/render/Interface"
import UpsertInstallation from "components/forms/UpsertInstallation"

const StyledClickableGrid = styled(Grid)(({ theme }) => ({
	"&:hover": {
		cursor: "pointer",
		backgroundColor: theme.palette.background.default,
	},
}))

type Props = {
	sequenceNumber: string
}

function InstallationPage({ sequenceNumber }: Props) {
	const { data, loading, error } = useServiceRequestQuery({
		variables: { sequenceNumber },
	})
	const serviceRequest = data?.serviceRequest
	const installation = serviceRequest?.installation
	const navigate = useNavigate()
	const handleClick = () => installation && navigate(`/${INSTALLATION}/${installation.id}`)
	const [addInstallationOpen, setAddInstallationOpen] = useState(false)
	const [editInstallationOpen, setEditInstallationOpen] = useState(false)

	return (
		<>
			<CustomCard>
				<CardContent>
					{loading && <Typography>Gegevens ophalen...</Typography>}
					{error && <Typography>Er ging iets mis bij het ophalen van de gegevens...</Typography>}
					{!error && !loading && data && (
						<>
							<Typography variant="h5" gutterBottom>
								Installatie
							</Typography>
							{installation ? (
								<StyledClickableGrid container onClick={handleClick}>
									<Grid item xs={12} sm={6}>
										<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
											<Grid item>
												<Home />
											</Grid>
											<Grid item>
												<Address>{installation}</Address>
											</Grid>
										</Grid>
									</Grid>
									{installation?.installedOn && (
										<Grid item xs={12} sm={6}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item>
													<Today />
												</Grid>
												<Grid item>
													<InstalledOn>{installation}</InstalledOn>
												</Grid>
											</Grid>
										</Grid>
									)}
									{(installation.heatPump || installation.coolingModule) && (
										<Grid item xs={12} sm={6}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item>
													<FlashOn />
												</Grid>
												<Grid item>
													<HeatPump>{installation}</HeatPump>
												</Grid>
											</Grid>
										</Grid>
									)}
									{(installation.panel ||
										installation.panelCount ||
										installation.roofType ||
										installation.collectiveRoof) && (
										<Grid item xs={12} sm={6}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item>
													<ViewModule />
												</Grid>
												<Grid item>
													<Panel>{installation}</Panel>
												</Grid>
											</Grid>
										</Grid>
									)}
									{installation.coolant && (
										<Grid item xs={12} sm={6}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item>
													<Opacity />
												</Grid>
												<Grid item>
													<Coolant>{installation}</Coolant>
												</Grid>
											</Grid>
										</Grid>
									)}
									{(installation.installer || installation.insideInstaller) && (
										<Grid item xs={12} sm={6}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item>
													<Build />
												</Grid>
												<Grid item>
													<Installer>{installation}</Installer>
												</Grid>
											</Grid>
										</Grid>
									)}
									{installation.resident && (
										<Grid item xs={12} sm={6}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item>
													<Person />
												</Grid>
												<Grid item>
													<Resident>{installation}</Resident>
												</Grid>
											</Grid>
										</Grid>
									)}
									{installation.softwareVersion && (
										<Grid item xs={12} sm={6}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item>
													<Computer />
												</Grid>
												<Grid item>
													<SoftwareVersion>{installation}</SoftwareVersion>
												</Grid>
											</Grid>
										</Grid>
									)}
									{installation.inverterType && (
										<Grid item xs={12} sm={6}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item>
													<WbSunny />
												</Grid>
												<Grid item>
													<InverterType>{installation}</InverterType>
												</Grid>
											</Grid>
										</Grid>
									)}
									{installation.interfaceIDs.length > 0 && (
										<Grid item sm={12}>
											<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
												<Grid item display="flex" alignItems="center">
													<DeveloperBoard />
												</Grid>
												<Grid item>
													<InterfaceInfo>{installation}</InterfaceInfo>
												</Grid>
											</Grid>
										</Grid>
									)}
								</StyledClickableGrid>
							) : (
								<Typography>Er is geen installatie opgegeven bij dit serviceverzoek.</Typography>
							)}
						</>
					)}
				</CardContent>
				<CardActions>
					<Grid container justifyContent="flex-end" spacing={2}>
						{installation && (
							<Grid item>
								<GoogleMapsDir
									street={installation?.street}
									zipCode={installation?.zipCode}
									city={installation?.city}
								/>
							</Grid>
						)}
						<Grid item>
							{installation ? (
								<Button variant="contained" color="primary" onClick={() => setEditInstallationOpen(true)}>
									Aanpassen
								</Button>
							) : (
								<Button variant="contained" color="primary" onClick={() => setAddInstallationOpen(true)}>
									Toevoegen
								</Button>
							)}
						</Grid>
					</Grid>
				</CardActions>
			</CustomCard>
			{editInstallationOpen && (
				<UpsertInstallation
					installation={serviceRequest?.installation}
					onClose={() => setEditInstallationOpen(false)}
				/>
			)}
			{addInstallationOpen && (
				<AddInstallation
					sr={{
						serviceRequestId: serviceRequest?.id ?? "",
						installationId: serviceRequest?.installation?.id ?? "",
					}}
					onClose={() => setAddInstallationOpen(false)}
				/>
			)}
		</>
	)
}

export default InstallationPage
