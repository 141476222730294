import { useEffect, useRef, useState } from "react"
import verifyAccept from "tools/verifyAccept"

type Opts = {
	multiple?: boolean
	accept?: string
	readAs: "string" | "file"
}

const defaultOpts = {
	multiple: false,
	accept: "*",
}

export enum FileError {
	INVALID_FILE_ERROR = "invalid file type",
	TOO_MANY_FILES_ERROR = "too many files",
}

export default function useFileDrop(cb: (files: unknown) => void, opts: Opts) {
	const { multiple, accept } = { ...defaultOpts, ...opts }
	const ref = useRef<HTMLDivElement | null>(null)
	const [dragover, setDragover] = useState(false)
	const [errors, setErrors] = useState<FileError[]>([])

	useEffect(() => {
		if (!ref.current) return

		const getErrors = (items: DataTransferItemList) => {
			const mimetypes = Array.from(items).map((item) => item.type)
			const invalidFiles = mimetypes.map((type) => verifyAccept(type, accept)).includes(false)
			const tooManyFiles = !multiple && mimetypes.length > 1
			const errors = [
				invalidFiles ? FileError.INVALID_FILE_ERROR : [],
				tooManyFiles ? FileError.TOO_MANY_FILES_ERROR : [],
			].flat()
			return errors
		}

		const dragenter = (e: DragEvent) => {
			e.preventDefault()
			e.stopPropagation()
			if (!e.dataTransfer?.items.length) return
			setErrors(getErrors(e.dataTransfer.items))
			setDragover(true)
		}

		const dragleave = (e: DragEvent) => {
			e.stopPropagation()
			setDragover(false)
			setErrors([])
			hideOverlay()
		}

		const drop = (e: DragEvent) => {
			e.preventDefault()
			e.stopPropagation()
			setDragover(false)
			setErrors([])
			hideOverlay()
			if (e.dataTransfer && e.dataTransfer.files.length && getErrors(e.dataTransfer.items).length === 0) {
				cb(e.dataTransfer.files)
			}
		}

		const preventDefault = (e: DragEvent) => e.preventDefault()

		ref.current.style.position = "relative"

		const overlayEl = document.createElement("div")
		overlayEl.style.position = "absolute"
		overlayEl.style.height = "100%"
		overlayEl.style.width = "100%"
		overlayEl.style.left = "0"
		overlayEl.style.top = "0"
		overlayEl.style.zIndex = "10"
		overlayEl.style.display = "none"

		ref.current.appendChild(overlayEl)

		const showOverlay = () => {
			overlayEl.style.display = "block"
		}
		const hideOverlay = () => {
			overlayEl.style.display = "none"
		}

		overlayEl.addEventListener("dragenter", dragenter)
		overlayEl.addEventListener("dragleave", dragleave)
		overlayEl.addEventListener("dragover", preventDefault)
		overlayEl.addEventListener("drop", drop)
		ref.current.addEventListener("dragenter", showOverlay)
		document.addEventListener("drop", preventDefault)
		document.addEventListener("dragover", preventDefault)
		document.addEventListener("dragenter", preventDefault)

		return () => {
			// not sure if removing overlayEl events is needed as the element is removed anyway, but just in case to avoid a memory leak, they are all removed
			overlayEl.removeEventListener("dragenter", dragenter)
			overlayEl.removeEventListener("dragleave", dragleave)
			overlayEl.removeEventListener("dragover", preventDefault)
			overlayEl.removeEventListener("drop", drop)
			ref.current?.removeEventListener("dragenter", showOverlay)
			document.removeEventListener("drop", preventDefault)
			document.removeEventListener("dragover", preventDefault)
			document.removeEventListener("dragenter", preventDefault)

			ref.current?.removeChild(overlayEl)
			if (ref.current) ref.current.style.position = ""
		}
	}, [ref.current])

	return { ref, dragover, errors }
}
