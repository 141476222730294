export default function getValueFromStorage<T>(storage: Storage, key: string, defaultValue: T): T {
	try {
		const storedValue = storage.getItem(key)

		if (!storedValue) throw new Error("Couldn't get value from storage")
		return JSON.parse(storedValue) as T
	} catch {
		return defaultValue
	}
}
