import { Typography } from "@mui/material"

interface Installation {
	coolant?: {
		name: string
	} | null
}

type Props = {
	children?: Installation | null
}

export default function Coolant({ children: installation }: Props) {
	return <Typography>{installation?.coolant ? installation.coolant.name : "Onbekend koelmiddel"}</Typography>
}
