/* eslint-disable i18next/no-literal-string */ //Internal only
import { CardContent, Typography, Grid, CardActions, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import SeverityIcon from "components/SeverityIcon"
import StatusIcon from "components/StatusIcon"
import { DateRange, Today, Event, Person, Receipt, EventAvailable, PrecisionManufacturing } from "@mui/icons-material"
import { differenceInDays } from "date-fns"
import { useMessage } from "providers/MessageProvider"
import { useNavigate } from "react-router-dom"
import { SERVICE_REQUESTS } from "settings/url"
import { OpenStatus, useDeleteServiceRequestMutation, useServiceRequestQuery } from "generated/graphql"
import UpdateServiceRequest from "components/forms/UpdateServiceRequest"
import { useState } from "react"
import { useConfirm } from "providers/ConfirmProvider"
import CustomCard from "components/CustomCard"
import formatTime from "tools/formatTime"
import appendErrorMessage from "tools/appendErrorMessage"
import { FormatUserOrGroup as User } from "components/FormatUserOrGroup"
import { useTranslation } from "react-i18next"

const StyledCustomCard = styled(CustomCard)`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

type Props = {
	sequenceNumber: string
}

export default function StatusCard({ sequenceNumber }: Props) {
	const { t } = useTranslation()
	const { data, loading, error } = useServiceRequestQuery({
		variables: { sequenceNumber },
	})
	const [updateServiceRequestDialogOpen, setUpdateServiceRequestDialogOpen] = useState(false)
	const serviceRequest = data?.serviceRequest
	const history = serviceRequest?.history
	const newestItem = history?.[0] //History is sorted newest to oldest
	const oldestItem = history?.[history.length - 1]

	const [deleteServiceRequest] = useDeleteServiceRequestMutation({
		update: (cache) => {
			cache.evict({ id: `ServiceRequest:${serviceRequest?.id}` })
			cache.gc()
		},
	})
	const message = useMessage()
	const confirm = useConfirm()
	const navigate = useNavigate()

	const closed = newestItem?.statusType.status === OpenStatus.Closed
	const daysOpen =
		differenceInDays(
			closed ? new Date(newestItem?.timestamp ?? "") : new Date(),
			new Date(oldestItem?.timestamp ?? "")
		) + 1

	const handleDelete = async () => {
		await confirm("Weet je zeker dat de dit serviceverzoek permanent wil verwijderen?")
		try {
			if (!serviceRequest?.id) throw new Error("serviceRequest.id niet gevonden")
			await deleteServiceRequest({ variables: { id: serviceRequest.id } })
			message.success("Serviceverzoek verwijderd")
			navigate(`/${SERVICE_REQUESTS}`)
		} catch (e) {
			message.error(appendErrorMessage("Er ging iets fout bij het verwijderen", e))
		}
	}

	if (!newestItem?.timestamp || !oldestItem?.timestamp) return null

	return (
		<>
			<StyledCustomCard>
				<CardContent>
					{loading && <Typography>Gegevens ophalen...</Typography>}
					{error && <Typography>Fout bij het ophalen van de gegevens...</Typography>}
					{!loading && !error && serviceRequest && (
						<>
							<Typography variant="h5" gutterBottom>
								Status
							</Typography>
							<Grid container direction="column" spacing={1}>
								<Grid item container alignItems="center">
									<Grid item xs={12} sm={6}>
										<Grid container spacing={2} wrap="nowrap">
											<Grid item>
												<SeverityIcon severity={serviceRequest?.serviceType?.severity} />
											</Grid>
											<Grid item zeroMinWidth>
												<Typography noWrap>{serviceRequest.serviceType.name}</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Grid container spacing={2} wrap="nowrap">
											<Grid item>
												<StatusIcon status={newestItem.statusType.status} />
											</Grid>
											<Grid item zeroMinWidth>
												<Typography noWrap>{newestItem.statusType.name}</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container alignItems="center">
										<Grid item xs={12} sm={6}>
											<Grid container spacing={2} wrap="nowrap">
												<Grid item>
													<Today />
												</Grid>
												<Grid item zeroMinWidth>
													<Typography noWrap>Aangevraagd op {formatTime(oldestItem.timestamp)}</Typography>
												</Grid>
											</Grid>
										</Grid>
										{serviceRequest.endDate != null && (
											<Grid item xs={12} sm={6}>
												<Grid container spacing={2} wrap="nowrap">
													<Grid item>
														<EventAvailable />
													</Grid>
													<Grid item zeroMinWidth>
														<Typography noWrap>
															Uiterlijk {t("date", { date: new Date(serviceRequest.endDate) })}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										)}
										{newestItem.plannedVisit != null && (
											<Grid item xs={12} sm={6}>
												<Grid container spacing={2} wrap="nowrap">
													<Grid item>
														<PrecisionManufacturing />
													</Grid>
													<Grid item zeroMinWidth>
														<Typography noWrap>
															Bezoek {t("date", { date: new Date(newestItem.plannedVisit) })}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										)}
										{newestItem.statusType.status === OpenStatus.Closed && (
											<Grid item xs={12} sm={6} container spacing={2} wrap="nowrap">
												<Grid item>
													<Event />
												</Grid>
												<Grid item zeroMinWidth>
													<Typography noWrap>Afgehandeld op {formatTime(newestItem.timestamp)}</Typography>
												</Grid>
											</Grid>
										)}
									</Grid>
								</Grid>
								<Grid item>
									<Grid container spacing={2} wrap="nowrap">
										<Grid item>
											<DateRange />
										</Grid>
										<Grid item zeroMinWidth>
											<Typography>
												{daysOpen} dag{daysOpen !== 1 ? "en" : ""} open {closed ? "geweest" : ""}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								{serviceRequest.assignedTo && (
									<Grid item>
										<Grid container spacing={2} wrap="nowrap">
											<Grid item>
												<Person />
											</Grid>
											<Grid item zeroMinWidth>
												<Typography>
													Toegewezen aan <User>{serviceRequest.assignedTo}</User>
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								)}
								<Grid item>
									<Grid container spacing={2} wrap="nowrap">
										<Grid item>
											<Receipt />
										</Grid>
										<Grid item zeroMinWidth>
											<Typography>{serviceRequest?.invoice ? "Wel factureren" : "Niet factureren"}</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
				</CardContent>
				<CardActions>
					<Grid container justifyContent="flex-end" spacing={2}>
						<Grid item>
							<Button onClick={() => setUpdateServiceRequestDialogOpen(true)} color="primary" variant="contained">
								Aanpassen
							</Button>
						</Grid>
						<Grid item>
							<Button onClick={handleDelete} color="primary" variant="contained">
								Verwijderen
							</Button>
						</Grid>
					</Grid>
				</CardActions>
			</StyledCustomCard>
			{serviceRequest && updateServiceRequestDialogOpen && (
				<UpdateServiceRequest
					serviceRequest={serviceRequest}
					onClose={() => setUpdateServiceRequestDialogOpen(false)}
				/>
			)}
		</>
	)
}
