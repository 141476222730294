/* eslint-disable i18next/no-literal-string */ //Internal only
import { Typography } from "@mui/material"
import SearchableSelect from "components/SearchableSelect"
import { useInstallersQuery } from "generated/graphql"

type Props = {
	value?: string | null
	onChange: (value: string | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function InstallerInput({
	value,
	onChange,
	label = "Installateur",
	required,
	error,
	helperText,
}: Props) {
	const {
		data,
		loading,
		error: qError,
	} = useInstallersQuery({
		fetchPolicy: "network-only",
	})

	const handleChange = (e: { target: { value: string | number | null } }) => {
		onChange(typeof e.target.value === "string" ? e.target.value : null)
	}

	if (qError) {
		return <Typography color="error">Er ging iets fout bij het ophalen van de installateurs</Typography>
	}

	return (
		<SearchableSelect
			value={value}
			onChange={handleChange}
			options={data?.installers ?? []}
			getOptionLabel={({ organization }) => organization}
			disabled={loading || Boolean(qError)}
			loading={loading}
			label={label}
			required={required}
			error={error}
			helperText={helperText}
		/>
	)
}
