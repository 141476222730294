/* eslint-disable i18next/no-literal-string */ //Internal only
import { useState } from "react"
import { CardContent, Grid, CardActions, Button, Typography, Box } from "@mui/material"
import CreateInstallation from "components/forms/UpsertInstallation"
import {
	Home,
	ViewModule,
	FlashOn,
	Opacity,
	Build,
	Person,
	Computer,
	Today,
	WbSunny,
	DeveloperBoard,
} from "@mui/icons-material"
import Location from "components/Location"
import HeatPump from "components/render/HeatPump"
import Panel from "components/render/Panel"
import Installer from "components/render/Installer"
import Resident from "components/render/Resident"
import Coolant from "components/render/Coolant"
import Address from "components/render/Address"
import SoftwareVersion from "components/render/SoftwareVersion"
import InstalledOn from "components/render/InstalledOn"
import InverterType from "components/render/InverterType"
import { InstallationFieldsFragment, useInstallationQuery } from "generated/graphql"
import { Skeleton } from "@mui/material"
import GoogleMapsDir from "components/GoogleMapsDir"
import CustomCard from "components/CustomCard"
import useSize from "hooks/useSize"
import InterfaceInfo from "components/render/Interface"

type Props = {
	installationId: string
	hideMap?: boolean
}

export default function InfoCard({ installationId }: Props) {
	const { data, loading, error } = useInstallationQuery({
		variables: { installationId: installationId ?? "" },
		skip: !installationId,
	})
	const [editInstallation, setInstallation] = useState<InstallationFieldsFragment | null>(null)
	const [addDialogOpen, setAddDialogOpen] = useState(false)
	const smallScreen = useSize("down", "md")

	const installation = data?.installation

	const handleEdit = () => {
		if (!installation) return
		setInstallation(installation)
		setAddDialogOpen(true)
	}

	const handleClose = () => {
		setInstallation(null)
		setAddDialogOpen(false)
	}

	return (
		<>
			<CustomCard>
				<CardContent>
					{loading && (
						<Grid container spacing={2} direction="column">
							<Grid item>
								<Box sx={smallScreen ? { marginLeft: -2, marginRight: -2, marginTop: -3 } : {}}>
									<Skeleton variant="rectangular" height={150} />
								</Box>
							</Grid>
							<Grid item>
								<Skeleton />
							</Grid>
						</Grid>
					)}
					{error && <Typography>Fout bij het ophalen...</Typography>}
					{!loading && !error && installation && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box sx={smallScreen ? { marginLeft: -2, marginRight: -2, marginTop: -3 } : {}}>
									<Location
										lat={installation?.location.lat}
										lon={installation?.location.lon}
										color={installation?.hasOpenIBS ? "yellow" : installation?.hasOpenServiceRequests ? "red" : "blue"}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
									<Grid item display="flex" alignItems="center">
										<Home />
									</Grid>
									<Grid item>
										<Address>{installation}</Address>
									</Grid>
								</Grid>
							</Grid>
							{installation?.installedOn && (
								<Grid item xs={12} sm={6}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<Today />
										</Grid>
										<Grid item>
											<InstalledOn>{installation}</InstalledOn>
										</Grid>
									</Grid>
								</Grid>
							)}
							{(installation.heatPump || installation.coolingModule) && (
								<Grid item xs={12} sm={6}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<FlashOn />
										</Grid>
										<Grid item>
											<HeatPump>{installation}</HeatPump>
										</Grid>
									</Grid>
								</Grid>
							)}
							{(installation.panel ||
								installation.panelCount ||
								installation.roofType ||
								installation.collectiveRoof) && (
								<Grid item xs={12} sm={6}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<ViewModule />
										</Grid>
										<Grid item>
											<Panel>{installation}</Panel>
										</Grid>
									</Grid>
								</Grid>
							)}
							{installation.coolant && (
								<Grid item xs={12} sm={6}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<Opacity />
										</Grid>
										<Grid item>
											<Coolant>{installation}</Coolant>
										</Grid>
									</Grid>
								</Grid>
							)}
							{(installation.installer || installation.insideInstaller) && (
								<Grid item xs={12} sm={6}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<Build />
										</Grid>
										<Grid item>
											<Installer>{installation}</Installer>
										</Grid>
									</Grid>
								</Grid>
							)}
							{installation.resident && (
								<Grid item xs={12} sm={6}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<Person />
										</Grid>
										<Grid item>
											<Resident>{installation}</Resident>
										</Grid>
									</Grid>
								</Grid>
							)}
							{installation.softwareVersion && (
								<Grid item xs={12} sm={6}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<Computer />
										</Grid>
										<Grid item>
											<SoftwareVersion>{installation}</SoftwareVersion>
										</Grid>
									</Grid>
								</Grid>
							)}
							{installation.inverterType && (
								<Grid item xs={12} sm={6}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<WbSunny />
										</Grid>
										<Grid item>
											<InverterType>{installation}</InverterType>
										</Grid>
									</Grid>
								</Grid>
							)}
							{installation.interfaceIDs.length > 0 && (
								<Grid item sm={12}>
									<Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
										<Grid item display="flex" alignItems="center">
											<DeveloperBoard />
										</Grid>
										<Grid item>
											<InterfaceInfo>{installation}</InterfaceInfo>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
				</CardContent>
				<CardActions>
					<Grid item container spacing={2} direction="column">
						<Grid item container justifyContent="flex-end" spacing={2}>
							<Grid item display="flex" alignItems="center">
								<GoogleMapsDir
									street={installation?.street}
									zipCode={installation?.zipCode}
									city={installation?.city}
								/>
							</Grid>
							<Grid item>
								<Button onClick={handleEdit} color="primary" variant="contained" disabled={loading || Boolean(error)}>
									Aanpassen
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</CardActions>
			</CustomCard>
			{addDialogOpen && <CreateInstallation installation={editInstallation} onClose={handleClose} />}
		</>
	)
}
