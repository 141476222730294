import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import { NotificationType, OpenStatus } from "generated/graphql"
import { differenceInSeconds, isBefore } from "date-fns"
import mapState from "localstate/mapState"
import logPeriod from "localstate/logperiod"
import serviceRequestFilter from "localstate/serviceRequestFilter"
import installationPageFilter from "localstate/installationsListPageFilter"
import heatPumpPageFilter from "localstate/heatPumpListPageFilter"
import settingsPageFilter from "./localstate/settingsListPageFilter"
import Auth from "tools/auth"

const GRAPHQL_ENDPOINT: string | undefined = import.meta.env.PROD
	? window.env.GRAPHQL_ENDPOINT
	: import.meta.env.VITE_GRAPHQL_ENDPOINT?.toString()

interface Ref {
	__ref: string
}

type QueryCache = {
	queryCache?: Set<string>
}

type NotificationArgs = {
	includeExpired?: boolean
	types?: NotificationType[] | NotificationType
	limit?: number
}

type ServiceRequestArgs = {
	statuses?: OpenStatus[]
}

export function setupApollo() {
	const auth = new Auth()

	const httpLink = createUploadLink({ uri: GRAPHQL_ENDPOINT })

	const authLink = setContext(async (_, { headers }) => {
		const token = await auth.getToken()
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : "",
			},
		}
	})

	const cache = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					mapState: () => mapState(),
					logPeriod: () => logPeriod(),
					serviceRequestFilter: () => serviceRequestFilter(),
					heatPumpPageFilter: () => heatPumpPageFilter(),
					installationPageFilter: () => installationPageFilter(),
					settingsPageFilter: () => settingsPageFilter(),
					serviceRequests: { read: readServiceRequests, merge: mergeServiceRequests },
					installations: { read: sortOnField("city") },
					serviceTypes: { read: sortOnField("name") },
					statusTypes: { read: sortOnField("name") },
					notifications: { read: readNotifications, merge: mergeServiceRequests },
				},
			},
			User: {
				fields: {
					roles: {
						merge: incoming,
					},
				},
			},
			Installation: {
				fields: {
					serviceRequests: {
						merge: incoming,
						read: sortServiceRequests,
					},
					notes: {
						merge: incoming,
						read: sortOnField("timestamp", "time", true),
					},
					files: {
						merge: incoming,
						read: sortOnField("fileName"),
					},
				},
			},
			Group: {
				fields: {
					interfaces: {
						merge: incoming,
					},
					users: {
						merge: incoming,
					},
					members: {
						merge: incoming,
					},
				},
			},
		},
	})

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache,
	})

	return { client, auth }
}

const incoming = (_: Ref[], incoming: Ref[]) => incoming

const sortOnField =
	(field: string, type: "time" | "string" | "number" = "string", reverse = false) =>
	//TODO: handle sorting in the backend
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(existing: Ref[], { readField }: { readField: any }) => {
		if (!existing) return existing

		const sorted = [...existing].sort((e1, e2) => {
			const c1 = readField(field, e1)
			const c2 = readField(field, e2)
			if (!c1) return -1
			if (!c2) return 1
			let value: number
			switch (type) {
				case "string":
					value = c1.localeCompare(c2)
					break
				case "number":
					value = c2 - c1
					break
				case "time":
					value = differenceInSeconds(new Date(c1), new Date(c2))
					break
			}
			return value * (reverse ? -1 : 1)
		})
		return sorted
	}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortServiceRequests = (existing: Ref[], { readField }: any) => {
	if (!existing) return existing
	const sorted = [...existing].sort((e1, e2) => {
		const h1 = readField("history", e1)
		const h2 = readField("history", e2)
		let t1, t2: string
		if (!h1?.[0]) {
			t1 = readField("createdAt", e1)
		} else {
			t1 = readField("timestamp", h1[0])
		}
		if (!h2?.[0]) {
			t2 = readField("createdAt", e2)
		} else {
			t2 = readField("timestamp", h2[0])
		}
		// giving up if both sr.history.timestamp and sr.createdAt do not exist..
		if (!t1) return -1
		if (!t2) return 1
		return -differenceInSeconds(new Date(t1), new Date(t2))
	})
	return sorted
}

const readServiceRequests = (
	existing: Ref[] | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	props: { args: unknown; readField: any; storage: QueryCache },
) => {
	const { args, readField, storage } = props
	const srArgs = args as ServiceRequestArgs

	if (storage.queryCache == null) storage.queryCache = new Set()

	const stats: OpenStatus[] = srArgs.statuses ?? Object.values(OpenStatus)

	const statusMissing = stats.some((status) => !storage.queryCache?.has(status))
	stats.forEach((status) => storage.queryCache?.add(status))
	if (!existing || statusMissing) return undefined
	return existing.filter((i) => stats.includes(readField("status", i) as OpenStatus))
}

const mergeServiceRequests = (existing: Ref[] = [], incoming: Ref[]) => {
	return [...existing, ...incoming.filter(({ __ref }) => !existing.some((i) => i.__ref === __ref))]
}

const readNotifications = (
	existing: Ref[] | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	props: { args: unknown; readField: any; storage: QueryCache },
) => {
	const { args, readField, storage } = props
	const notificationArgs = args as NotificationArgs

	if (storage.queryCache == null) storage.queryCache = new Set()

	// some made up key to track which queries have been done before
	const key = `${notificationArgs.types}-${notificationArgs.includeExpired}-${notificationArgs.limit}`

	if (!storage.queryCache.has(key) || !existing) {
		storage.queryCache.add(key) // next time we know this query
		return undefined // for now get from server
	}

	let types: NotificationType[] = Object.values(NotificationType) // if not given, all are queried
	if (typeof notificationArgs.types === "string") {
		types = [notificationArgs.types]
	}
	if (Array.isArray(notificationArgs.types)) {
		types = notificationArgs.types
	}

	let filtered = existing.filter((ref) => types.includes(readField("type", ref)))

	if (!notificationArgs.includeExpired) {
		filtered = filtered.filter((ref) => {
			const expirationDate = readField("expirationDate", ref)
			return !expirationDate || isBefore(new Date(), new Date(expirationDate))
		})
	}

	// reduce length based on limit
	if (notificationArgs.limit != null) {
		filtered.length = Math.min(filtered.length, notificationArgs.limit)
	}

	return filtered
}
